import React from 'react';

import {
    Button,
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Theme,
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { CloseIconWrapper, DialogTitleContainer } from '../../pages/ProductsListV3/styled';

import { LogsScrapperResult } from '../../models/LogsScrapperResult';
import { LogMessageContent, Message } from '../../models/LogMessage';
import { useDispatch } from 'react-redux';
import { AddCommen, DeleteCommen, UpdateComment } from '../../store/Logs2/actions';
import moment from 'moment';
import { escapeHtmlSpecialChars } from '../../utils/utils';

interface ILogDialogProps {
    log?: LogsScrapperResult;
    handleDialogClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        lastUpdate: {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '30px',
            fontSize: '0.8rem',
        },
    }),
);

export const LogDetailDialog: React.FC<ILogDialogProps> = ({ log, handleDialogClose }) => {
    const dispatch = useDispatch();
    //const [values, setValues] = React.useState<string[]>([]);
    let values: string[] = [];
    let value: string = '';
    const [firstRun, setfirstRun] = React.useState<boolean>(false);
    var msgs = log?.messages?.messages
        ?.map((I) => {
            return (I.message = I.message?.map((j) => {
                var auxEndDate = j.message.indexOf('</Date>');
                var startDateTagLength = '<Date>'.length;
                var date = j.message.substr(startDateTagLength, auxEndDate - startDateTagLength);
                var msg = j as any;
                msg.date = moment(date, 'DD/MM/YYYY-HH:mm:ss').toDate().getTime();
                msg.logMessageContent = I;
                msg.msg = j;
                //var x2 = new Date(j.date);
                return msg;
            }));
        })
        .flat();

    msgs = msgs?.sort((I, J) => (J?.date ?? new Date().getTime()) - (I?.date ?? new Date().getTime()));

    const handleEditComment = React.useCallback(
        (M: Message, J: LogMessageContent, idx: number) => {
            const comment = values[idx]
                .replace(/ /g, '&nbsp;')
                .replace(/\r\n/g, '<br>')
                .replace(/\n/g, '<br>')
                .replace(/\"/g, "'");
            dispatch(
                UpdateComment({ comment: comment, executionName: J.name, executionId: J.executionId, idMsg: M.id }),
            );
            handleDialogClose();
        },
        [values, handleDialogClose],
    );

    const change = (V: any, idx: number) => {
        const v1 = V.target.value;
        values[idx] = v1;
    };

    const handleAddComment = React.useCallback(
        (msg: string, ExecName: string, ExecID: string) => {
            msg = escapeHtmlSpecialChars(msg);
            msg = msg.replace(/ /g, '&nbsp;').replace(/\r\n/g, '<br>').replace(/\n/g, '<br>').replace(/\"/g, "'");
            dispatch(AddCommen({ comment: msg, executionId: ExecID, executionName: ExecName }));
            handleDialogClose();
        },
        [handleDialogClose],
    );

    var handleDeleteComment = React.useCallback(
        (M: Message, J: LogMessageContent, idx: number) => {
            var comment = escapeHtmlSpecialChars(values[idx])
                .replace(/ /g, '&nbsp;')
                .replace(/\r\n/g, '<br>')
                .replace(/\n/g, '<br>')
                .replace(/\"/g, "'");
            dispatch(
                DeleteCommen({ comment: comment, executionName: J.name, executionId: J.executionId, idMsg: M.id }),
            );
            handleDialogClose();
        },
        [values, handleDialogClose],
    );

    let i = -1;
    const x = msgs?.map((j) => {
        const idx = ++i;
        const auxEndDate = j.message.indexOf('</Date>');
        const startDateTagLength = '<Date>'.length;
        const date = j.message.substr(startDateTagLength, auxEndDate - startDateTagLength);
        const val = j.message
            .substr('</Date>'.length + auxEndDate)
            .replace(/<br>/g, '\r\n')
            .replace(/&nbsp;/g, ' ');
        values.push(val);

        return (
            <>
                <b>{date}</b>
                <br />
                <textarea
                    style={{ width: '1200px', height: '200px' }}
                    onChange={(v) => change(v, idx)}
                    defaultValue={values[idx]}
                />
                <br />
                <Button
                    onClick={() => handleEditComment(j.msg, j.logMessageContent, idx)}
                    color={'default'}
                    variant={'contained'}
                >
                    Update
                </Button>
                <Button
                    onClick={() => handleDeleteComment(j.msg, j.logMessageContent, idx)}
                    style={{ background: '#f86051' }}
                    variant={'contained'}
                >
                    Delete
                </Button>
                <br />
            </>
        );
    });

    return (
        <Dialog open={!!log} maxWidth="lg" onClose={handleDialogClose}>
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ fontWeight: 700 }}>{log?.messages?.name}</div>
                </DialogTitleContainer>
                <CloseIconWrapper>
                    <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                        <HighlightOffIcon color="primary" fontSize="large" />
                    </IconButton>
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <div>
                    <b>Add Comment:</b>
                    <br />
                    <textarea onChange={(v) => (value = v.target.value)} style={{ width: '1200px', height: '200px' }} />
                    <br />
                    <Button
                        onClick={() =>
                            handleAddComment(value, log?.last.executionName ?? 'Nada', log?.last.executionId ?? 'ada')
                        }
                        variant="contained"
                    >
                        Save
                    </Button>
                    <br />
                </div>
                <hr />
                {x}
            </DialogContent>
        </Dialog>
    );
};
