export enum AdministrationForm {
	None = 'None',
	Unknown = 'Unknown',
	Auricular = 'Auricular',
	Buccal = 'Buccal',
	Cutaneous = 'Cutaneous',
	Dental = 'Dental',
	Endocervical = 'Endocervical',
	Endosinusial = 'Endosinusial',
	Endotracheopulmonary = 'Endotracheopulmonary',
	Epidural = 'Epidural',
	Epilesional = 'Epilesional',
	Extraamniotic = 'Extraamniotic',
	Extracorporeal = 'Extracorporeal',
	Gastric = 'Gastric',
	Gastroenteral = 'Gastroenteral',
	Gingival = 'Gingival',
	Haemodialysis = 'Haemodialysis',
	Implantation = 'Implantation',
	Infiltration = 'Infiltration',
	Inhalation = 'Inhalation',
	Intestinal = 'Intestinal',
	Intraamniotic = 'Intraamniotic',
	Intraarterial = 'Intraarterial',
	Intraarticular = 'Intraarticular',
	Intrabursal = 'Intrabursal',
	Intracameral = 'Intracameral',
	Intracardiac = 'Intracardiac',
	Intracartilaginous = 'Intracartilaginous',
	Intracavernous = 'Intracavernous',
	Intracerebral = 'Intracerebral',
	Intracerebroventricular = 'Intracerebroventricular',
	Intracervical = 'Intracervical',
	Intracholangiopancreatic = 'Intracholangiopancreatic',
	Intracisternal = 'Intracisternal',
	Intracorneal = 'Intracorneal',
	Intracoronary = 'Intracoronary',
	Intradermal = 'Intradermal',
	Intradiscal = 'Intradiscal',
	Intraepidermal = 'Intraepidermal',
	Intraglandular = 'Intraglandular',
	Intralesional = 'Intralesional',
	Intralymphatic = 'Intralymphatic',
	Intramuscular = 'Intramuscular',
	Intraocular = 'Intraocular',
	Intraosseous = 'Intraosseous',
	Intrapericardial = 'Intrapericardial',
	Intraperitoneal = 'Intraperitoneal',
	Intrapleural = 'Intrapleural',
	Intraportal = 'Intraportal',
	Intraprostatic = 'Intraprostatic',
	Intraputaminal = 'Intraputaminal',
	Intrasternal = 'Intrasternal',
	Intrathecal = 'Intrathecal',
	Intratumoral = 'Intratumoral',
	Intrauterine = 'Intrauterine',
	Intravenous = 'Intravenous',
	Intravesical = 'Intravesical',
	Intravitreal = 'Intravitreal',
	Iontophoresis = 'Iontophoresis',
	Laryngopharyngeal = 'Laryngopharyngeal',
	Nasal = 'Nasal',
	Ocular = 'Ocular',
	Oral = 'Oral',
	Oromucosal = 'Oromucosal',
	Oropharyngeal = 'Oropharyngeal',
	Periarticular = 'Periarticular',
	Perineural = 'Perineural',
	Periodontal = 'Periodontal',
	Periosseous = 'Periosseous',
	Peritumoral = 'Peritumoral',
	PosteriorJuxtascleral = 'PosteriorJuxtascleral',
	Rectal = 'Rectal',
	Retrobulbar = 'Retrobulbar',
	SkinScarification = 'SkinScarification',
	Subconjunctival = 'Subconjunctival',
	Subcutaneous = 'Subcutaneous',
	Sublingual = 'Sublingual',
	Submucosal = 'Submucosal',
	Subretinal = 'Subretinal',
	Topical = 'Topical',
	Transdermal = 'Transdermal',
	Transmammary = 'Transmammary',
	Transplacental = 'Transplacental',
	Urethral = 'Urethral',
	Vaginal = 'Vaginal',
	Administration = 'Administration',
	Application = 'Application',
	Bathing = 'Bathing',
	Burning = 'Burning',
	Chewing = 'Chewing',
	Dialysis = 'Dialysis',
	Gargling = 'Gargling',
	Infusion = 'Infusion',
	Injection = 'Injection',
	Insertion = 'Insertion',
	Instillation = 'Instillation',
	Orodispersion = 'Orodispersion',
	RinsingOrWashing = 'RinsingOrWashing',
	Spraying = 'Spraying',
	Sucking = 'Sucking',
	Swallowing = 'Swallowing',
	Hemofiltration = 'Hemofiltration',
	Nebulisation = 'Nebulisation',
}