import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { MapOf } from '../../../../utils/Types';

import { useTranslations } from '../../../../store/Translations/hooks';

import { ActiveSubFullListContainer } from './styled';
import PlatformFilters from '../../../../components/Filter';
import { findStringComparison } from '../../../../utils/utils';
import { ProcessingDciFullSettings } from '../../../../models/ProcessingDciFullSettings';

export interface IActiveSubFullListMappingTable {
    values: MapOf<ProcessingDciFullSettings>;
}

const ActiveSubFullListMappingTable: React.FC<IActiveSubFullListMappingTable> = ({ values }) => {
    const t = useTranslations();
    const [categoryList, setCategoryList] = React.useState<ProcessingDciFullSettings[]>([]);
    const [categoryListData, setCategoryListData] = React.useState<ProcessingDciFullSettings[]>([]);
    const [selectFilterType, setSelectFilterType] = React.useState('');
    const [textFilterVal, setTextFilterVal] = React.useState('');

    React.useEffect(() => {
        //const offset = currentPage * recordsPerPage;
        //const startingNumber = offset - recordsPerPage;
        const categoryList = Object.keys(values).map((key) => values[key]);
        setCategoryList(categoryList);
        setCategoryListData(categoryList);
    }, [values, setCategoryList, setCategoryListData]);

    const onSelectFilterChange = React.useCallback(
        (value) => {
            setSelectFilterType(value);
        },
        [selectFilterType, setSelectFilterType],
    );

    const onTextFilterChange = React.useCallback(
        (fieldName: string) => (filterVal: string) => {
            let categoryList: ProcessingDciFullSettings[] = [];

            if (fieldName === 'activeSub') {
                categoryList = categoryListData.filter((item) =>
                    findStringComparison(item.activeSubs, filterVal, selectFilterType),
                );
            }

            if (fieldName === 'user') {
                categoryList = categoryListData.filter((item) =>
                    findStringComparison(item.user, filterVal, selectFilterType),
                );
            }

            setCategoryList(categoryList);
            setTextFilterVal(filterVal);
        },
        [selectFilterType, textFilterVal, setTextFilterVal, setCategoryList, categoryListData],
    );

    return (
        <ActiveSubFullListContainer>
            {/*//<Typography variant="caption">* {t(TK.specifyTheKeywordsForTheCorrespondingValues)}.</Typography>*/}
            <div>Total: {categoryList.length}</div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Active Sub
                            <PlatformFilters
                                elementId="activeSub-popover"
                                onTextFilterChange={onTextFilterChange('activeSub')}
                                onSelectFilterChange={onSelectFilterChange}
                            />
                        </TableCell>
                        <TableCell>
                            Updated by
                            <PlatformFilters
                                elementId="user-popover"
                                onTextFilterChange={onTextFilterChange('user')}
                                onSelectFilterChange={onSelectFilterChange}
                            />
                        </TableCell>
                        <TableCell>Updated</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categoryList.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.activeSubs}</TableCell>
                            <TableCell>{item.user}</TableCell>
                            <TableCell>
                                {' '}
                                {item.updated != undefined
                                    ? new Date(Date.parse(item.updated?.toString() || '')).toLocaleString()
                                    : ''}{' '}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ActiveSubFullListContainer>
    );
};

export default ActiveSubFullListMappingTable;
