import { AppThunkAction } from '..';
import { Action } from 'redux';
import { alertGenericError, requestServer, alertSuccess, alertSaving, alertDismiss } from '../Session/actions';
import {
    PlatformProcessingSettingsLoadedAction,
    PlatformProcessingPformSettingsLoadedAction,
    PlatformProcessingPformNewTermAction,
} from './types';
import { isLoadingPlatformData } from './selectors';
import { IPformTermData, ProcessingSettings } from '../../models/ProcessingSettings';
import {
    getPlatformProcessingSettingsAsync,
    getPlatformPformProcessingSettingsAsync,
    postPlatformProcessingSettingsAsync,
    postPlatformSenReProcessRequestAsync as postPlatformSendReprocessRequestAsync,
    postPlatformRestoreBackupRequestAsync,
    putPFormPlatformProcessSettingsAsync,
} from '../../fetch/requests';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';
import { SearchResult } from '../../models/SearchResult';
import { ProcessingPformsSettings } from '../../models/ProcessingPformsSettings';
import { filterByChangedValues } from '../../utils/utils';

export const Actions = {
    platformDataLoading: '@@whichpharma.platform.dataLoading',
    platformDataSaving: '@@whichpharma.platform.saving',
    platformDataLoadError: '@@whichpharma.platform.dataLoadError',
    platformProcessingSettingsLoaded: '@@whichpharma.platform.mappingsLoaded',
    platformProcessingPformsSettingsLoaded: '@@whichpharma.platform.mappingsPformsLoaded',
    platformPharmaNewTermLoaded: '@@whichpharma.platform.newTermLoaded',
};

const platformDataLoading = (): Action => ({
    type: Actions.platformDataLoading,
});

const platformDataSaving = (): Action => ({
    type: Actions.platformDataSaving,
});

const platformDataLoadError = (): Action => ({
    type: Actions.platformDataLoadError,
});

const platformProcessingSettingsLoaded = (
    country: string,
    mappings: ProcessingSettings,
): PlatformProcessingSettingsLoadedAction => ({
    type: Actions.platformProcessingSettingsLoaded,
    country,
    mappings,
});
const platformProcessingPformSettingsLoaded = (
    country: string,
    mappings: SearchResult<ProcessingPformsSettings>,
): PlatformProcessingPformSettingsLoadedAction => ({
    type: Actions.platformProcessingPformsSettingsLoaded,
    country,
    mappings,
});

const updatePlatformSettingsPFormCatMap = (
    country: string,
    pFormTerm: IPformTermData,
): PlatformProcessingPformNewTermAction => ({
    type: Actions.platformPharmaNewTermLoaded,
    country,
    pFormTerm,
});

export const fetchPlatformProcessingSettings = (countryCode: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingPlatformData(getState())) {
                return;
            }
            dispatch(platformDataLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getPlatformProcessingSettingsAsync(countryCode, token, csrfToken)),
            );
            dispatch(platformProcessingSettingsLoaded(countryCode, result));
        } catch (e) {
            console.log(e);
            dispatch(platformDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const fetchPformPlatformProcessingSettings = (countryCode: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingPlatformData(getState())) {
                return;
            }
            dispatch(platformDataLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getPlatformPformProcessingSettingsAsync(countryCode, token, csrfToken),
                ),
            );
            dispatch(platformProcessingPformSettingsLoaded(countryCode, result));
        } catch (e) {
            console.log(e);
            dispatch(platformDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const savePlatformProcessingSettings = (
    countryCode: string,
    mappings: ProcessingSettings,
): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingPlatformData(getState())) {
                return;
            }
            //dispatch(platformDataSaving());
            dispatch(alertSaving(getTranslation(getState(), TK.saving)));
            await dispatch(
                requestServer((token, csrfToken) => {
                    // Filter only changed values
                    const newMappings = {
                        culture: mappings.culture,
                        drugFormsMap2: filterByChangedValues(mappings.drugFormsMap2),
                        dciMap: filterByChangedValues(mappings.dciMap),
                    } as ProcessingSettings;

                    return postPlatformProcessingSettingsAsync(countryCode, newMappings, token, csrfToken);
                }),
            );
            dispatch(alertDismiss(getState().session.alerts[0].id));
            dispatch(alertSuccess(getTranslation(getState(), TK.savePlatformSettings)));
            /*const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getPlatformPformProcessingSettingsAsync(countryCode, token, csrfToken),
                ),
            );
            dispatch(platformProcessingPformSettingsLoaded(countryCode, result));*/
        } catch (e) {
            console.log(e);
            dispatch(platformDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

/*postPFormPlatformProcessSettingsAsync*/

export const savePFormNewItem = (countryCode: string, pformItemData: IPformTermData): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    putPFormPlatformProcessSettingsAsync(countryCode, pformItemData, token, csrfToken),
                ),
            );
            dispatch(alertSuccess(getTranslation(getState(), TK.pFormCreated)));
            dispatch(updatePlatformSettingsPFormCatMap(countryCode, result));
        } catch (e) {
            dispatch(platformDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const sendReprocessRequest = (origin: string, valuesAffected: string[]): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(
                requestServer((token, csrfToken) =>
                    postPlatformSendReprocessRequestAsync(origin, valuesAffected, token, csrfToken),
                ),
            );
            dispatch(alertSuccess(getTranslation(getState(), TK.reprocessRequestSubmitted)));
        } catch (e) {
            console.log(e);
            dispatch(platformDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const sendRestoreFromBackup = (origin: string, valuesAffected: string[]): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(
                requestServer((token, csrfToken) =>
                    postPlatformRestoreBackupRequestAsync(origin, valuesAffected, token, csrfToken),
                ),
            );
            dispatch(alertSuccess(getTranslation(getState(), TK.reprocessRequestSubmitted)));
        } catch (e) {
            console.log(e);
            dispatch(platformDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
