import styled from 'styled-components';

export const PFormMappingTableContainer = styled.div`
    > * {
        padding-top: ${({ theme }) => theme.metrics.space.s};
    }
`;

export const PlatFormFiltersContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    width: 300px;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
    border-radius: 5px;
`;
