import { Box, Button, IconButton, Popover } from '@material-ui/core';
import React, { useEffect } from 'react';
import { PlatFormFiltersContainer } from '../../pages/Platform/ProcessingSettingsPanel/PFormMappingTable/styled';
import SingleSelectInput from '../inputs/SingleSelectInput';
import TextInput from '../inputs/TextInput';
import FilterListIcon from '@material-ui/icons/FilterList';
import useDebounce from '../../utils/debounceHook';

interface IPlatformFiltersProps {
    elementId: string;
    onTextFilterChange: (value: string) => void;
    onSelectFilterChange: (value: string) => void;
}

const filterTypes = ['Contains', 'Not contains', 'Equal', 'Not equal', 'Starts with', 'Ends with'];

const PlatformFilters: React.FC<IPlatformFiltersProps> = ({ elementId, onTextFilterChange, onSelectFilterChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [textValue, setTextValue] = React.useState('');
    const [selectValue, setSelectValue] = React.useState('');

    const openPopover = Boolean(anchorEl);

    const idPopover = openPopover ? elementId : undefined;

    const onInputChange = React.useCallback(
        (value) => {
            setTextValue(value);
            if (value.length < 3) {
                return;
            }
            setTimeout(() => {
                onTextFilterChange(value);
            }, 2000);
        },
        [onTextFilterChange, setTextValue],
    );

    const onSingleInputChange = React.useCallback(
        (value) => {
            setSelectValue(value);
            onSelectFilterChange(value);
        },
        [onSelectFilterChange],
    );

    const onClickReset = React.useCallback(() => {
        setTextValue('');
        setSelectValue('');
        onSelectFilterChange('');
        onTextFilterChange('');
    }, [setTextValue, setSelectValue, onSelectFilterChange, onTextFilterChange]);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="filter"
                aria-describedby={idPopover}
                size="small"
                color="primary"
                onClick={handlePopoverClick}
            >
                <FilterListIcon fontSize="small" />
            </IconButton>

            <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PlatFormFiltersContainer>
                    <Box padding="5px">Filter</Box>
                    <Box padding="5px">
                        <SingleSelectInput value={selectValue} options={filterTypes} onChange={onSingleInputChange} />
                    </Box>
                    <Box padding="5px">
                        <TextInput style={{ width: '220px' }} value={textValue} onChange={onInputChange} />
                    </Box>
                    <Box display="flex" justifyContent="right">
                        <Box marginTop="10px" marginBottom="10px">
                            <Button onClick={() => onClickReset()} color="primary" variant="contained">
                                Reset
                            </Button>
                        </Box>
                    </Box>
                </PlatFormFiltersContainer>
            </Popover>
        </>
    );
};

export default PlatformFilters;
