const appSettings = {
    googleClientId: '676769789875-4pa6dp8kd2prmbeqo17l46c0l76e71mm.apps.googleusercontent.com',
    googleLoginScope: 'https://www.googleapis.com/auth/gmail.compose',
    productionUrl: 'https://whichpharma.pt',
    developmentUrl: 'https://whichpharma.pt:50010/',
    localDevelopmentUrl: 'http://localhost:3000',
    backend_url: process.env.REACT_APP_BACK_END_URL || '',
};

export default appSettings;
