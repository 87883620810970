import { SearchResult } from '../../models/SearchResult';
import { ProductV1 } from '../../models/ProductV1';
import { ProductStatus } from '../../models/ProductStatus';
import { ShortageType } from '../../models/ShortageType';

export const productsMock: SearchResult<ProductV1> = {
    total: 1250,
    timeInSeconds: 0.58,
    items: [
        {
            id: '5eb17f5f4ecb317d0efe74de',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 3058,
            lastUpdate: '05/05/2020 14:59:43',
            productCode: '585166',
            name: 'ARDINEX®',
            activeSubstances: ['CODEINE', 'IBUPROFEN'],
            atc: 'N02AJ08',
            manufacturer: 'MUNDIPHARMA AB',
            drugForm: 'SOLID TABLET',
            strength: '',
            package: '20',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: 'https://vapris.vvkt.lt/vvkt-web/public/medications/view/916',
            pilFileUrl: undefined,
            retailPrice: 64,
            retailPriceWithVat: 100,
            reimbursementPrice: undefined,
            wholesalePrice: 27.9200000762939,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: 'USD',
            drugFormCategories: ['Solid', 'Liquid'],
            administrationFormCategories: ['Rectal', 'Oral'],
            shortageInfo: {
                start: '2020-06-25T00:25:00.000',
                end: '2020-10-25T00:25:00.000',
                isActive: true,
                type: ShortageType.Permanent,
                additionalNotes: 'Cenas que acontecem',
                lastUpdate: '2020-06-16T09:25:00.000',
            },
        },
        {
            id: '5eb17f5f4ecb317d0efe7528',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 3056,
            scrapingOriginUrl: 'https://vapris.vvkt.lt/vvkt-web/public/medications/view/916',
            lastUpdate: '05/05/2020 14:59:43',
            productCode: '585182',
            name: 'ARDINEX®',
            activeSubstances: ['CODEINE', 'IBUPROFEN'],
            atc: 'N02AJ08',
            manufacturer: 'MUNDIPHARMA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg',
            package: '100 coiso e tal',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: 'https://vapris.vvkt.lt/vvkt-web/public/medications/view/916',
            retailPrice: 175.610000610352,
            reimbursementPrice: undefined,
            wholesalePrice: 128.5,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: 'EUR',
        },
        {
            id: '5eb17f7d4ecb317d0efe8f0e',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 4639,
            lastUpdate: '05/05/2020 15:00:13',
            productCode: '501765',
            name: 'BURANA COMP',
            activeSubstances: ['CODEINE', 'IBUPROFEN'],
            atc: 'N02AJ08',
            manufacturer: 'ORION PHARMA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '100 packages',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPriceWithVat: 161.699996948242,
            reimbursementPrice: undefined,
            wholesalePrice: 115,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
            documentsUrls: [
                'https://vapris.vvkt.lt/vvkt-web/public/medications/view/916',
                'https://vapris.vvkt.lt/vvkt-web/public/medications/view/917',
            ],
        },
        {
            id: '5eb17f7d4ecb317d0efe8f10',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 4637,
            lastUpdate: '05/05/2020 15:00:13',
            productCode: '143401',
            name: 'BURANA COMP',
            activeSubstances: ['CODEINE', 'IBUPROFEN'],
            atc: 'N02AJ08',
            manufacturer: 'ORION PHARMA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '10',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 51,
            reimbursementPrice: undefined,
            wholesalePrice: 17.0799999237061,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f7e4ecb317d0efe8ff1',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 4635,
            lastUpdate: '05/05/2020 15:00:14',
            productCode: '194754',
            name: 'BURANA COMP',
            activeSubstances: ['CODEINE', 'IBUPROFEN'],
            atc: 'N02AJ08',
            manufacturer: 'ORION PHARMA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '50',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 161.699996948242,
            reimbursementPrice: undefined,
            wholesalePrice: 115,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f7e4ecb317d0efe9081',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 4641,
            lastUpdate: '05/05/2020 15:00:15',
            productCode: '89939',
            name: 'BURANA COMP',
            activeSubstances: ['CODEINE', 'IBUPROFEN'],
            atc: 'N02AJ08',
            manufacturer: 'ORION PHARMA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '20',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 51,
            reimbursementPrice: undefined,
            wholesalePrice: 17.0799999237061,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea4fd',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5776,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '529966',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '100',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 104.230003356934,
            reimbursementPrice: undefined,
            wholesalePrice: 51.8600006103516,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea4ff',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5784,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '84210',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '25',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 67.9000015258789,
            reimbursementPrice: undefined,
            wholesalePrice: 21.5799999237061,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea516',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5772,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '529974',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '600',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 300.940002441406,
            reimbursementPrice: undefined,
            wholesalePrice: 239.020004272461,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea542',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5786,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '84228',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '50',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 91.9400024414062,
            reimbursementPrice: undefined,
            wholesalePrice: 41.6199989318848,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea55b',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5793,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '28621',
            name: 'CITODON® MINOR',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID SUPPOSITORY',
            strength: '10mg/1ml',
            package: '20',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 159.979995727539,
            reimbursementPrice: undefined,
            wholesalePrice: 113.330001831055,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea57f',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5780,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '529941',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '20',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 56.3300018310547,
            reimbursementPrice: undefined,
            wholesalePrice: 11.9399995803833,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea583',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5788,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '414664',
            name: 'CITODON® FORTE',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID SUPPOSITORY',
            strength: '10mg/1ml',
            package: '50',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 326.5,
            reimbursementPrice: undefined,
            wholesalePrice: 275,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea585',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5770,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '174904',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '100Packs',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 104.230003356934,
            reimbursementPrice: undefined,
            wholesalePrice: 51.8600006103516,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea58e',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5778,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '529958',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '50',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 74.3000030517578,
            reimbursementPrice: undefined,
            wholesalePrice: 26.9200000762939,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f964ecb317d0efea598',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5782,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '84236',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '100',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 139.990005493164,
            reimbursementPrice: undefined,
            wholesalePrice: 82.7600021362305,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f974ecb317d0efea5b2',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5791,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '414631',
            name: 'CITODON® FORTE',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID SUPPOSITORY',
            strength: '10mg/1ml',
            package: '10',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 97.2399978637695,
            reimbursementPrice: undefined,
            wholesalePrice: 55.6199989318848,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f974ecb317d0efea5b4',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5768,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '531112',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '10',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 55.0999984741211,
            reimbursementPrice: undefined,
            wholesalePrice: 10.9200000762939,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f974ecb317d0efea5c6',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5774,
            lastUpdate: '05/05/2020 15:00:38',
            productCode: '160754',
            name: 'CITODON®',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'BIOPHAUSIA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '250',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 144.850006103516,
            reimbursementPrice: undefined,
            wholesalePrice: 87.4800033569336,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f9a4ecb317d0efea96f',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5996,
            lastUpdate: '05/05/2020 15:00:42',
            productCode: '186741',
            name: 'CODALVONIL',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'MEDA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '25',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 67.9000015258789,
            reimbursementPrice: undefined,
            wholesalePrice: 21.5799999237061,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f9a4ecb317d0efea99a',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5994,
            lastUpdate: '05/05/2020 15:00:42',
            productCode: '486680',
            name: 'CODALVONIL',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'MEDA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '100',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 139.990005493164,
            reimbursementPrice: undefined,
            wholesalePrice: 82.7600021362305,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17f9a4ecb317d0efea99e',
            country: 'Sweden',
            countryCode: 'SE',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 5998,
            lastUpdate: '05/05/2020 15:00:42',
            productCode: '118734',
            name: 'CODALVONIL',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: 'MEDA AB',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '50',
            maNumber: undefined,
            maHolder: '-',
            status: ProductStatus.Discontinued,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: 89.9899978637695,
            reimbursementPrice: undefined,
            wholesalePrice: 39.9900016784668,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17fb24ecb317d0efec00b',
            country: 'Portugal',
            countryCode: 'PT',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 7167,
            lastUpdate: '05/05/2020 15:01:06',
            productCode: '27989033',
            name: 'COEFFERALGAN',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: '-',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '16',
            maNumber: undefined,
            maHolder: 'UPSA S.A.S.',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: undefined,
            reimbursementPrice: undefined,
            wholesalePrice: undefined,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17fb24ecb317d0efec00d',
            country: 'Portugal',
            countryCode: 'PT',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 7176,
            lastUpdate: '05/05/2020 15:01:06',
            productCode: '31825060',
            name: 'TACHIDOL',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: '-',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '16',
            maNumber: undefined,
            maHolder: 'ANGELINI S.P.A.',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: undefined,
            reimbursementPrice: undefined,
            wholesalePrice: undefined,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '5eb17fb34ecb317d0efec04f',
            country: 'Portugal',
            countryCode: 'PT',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 7164,
            lastUpdate: '05/05/2020 15:01:06',
            productCode: '37021021',
            name: 'CODAMOL',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: '-',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '16',
            maNumber: undefined,
            maHolder: 'ABIOGEN PHARMA S.P.A.',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: undefined,
            reimbursementPrice: undefined,
            wholesalePrice: undefined,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
        {
            id: '534ecb317d0efec04f25',
            country: 'Spain',
            countryCode: 'ES',
            scrapingOrigin: 'exprice',
            scrapingOriginId: 7164,
            lastUpdate: '05/05/2020 15:01:06',
            productCode: '37021021',
            name: 'CODAMOL',
            activeSubstances: ['CODEINE', 'PARACETAMOL'],
            atc: 'N02AJ06',
            manufacturer: '-',
            drugForm: 'SOLID TABLET',
            strength: '10mg/1ml',
            package: '16',
            maNumber: undefined,
            maHolder: 'SPAIN PHARMA S.P.A.',
            status: ProductStatus.Marketed,
            spcFileUrl: undefined,
            pilFileUrl: undefined,
            retailPrice: undefined,
            reimbursementPrice: undefined,
            wholesalePrice: undefined,
            factoryPrice: undefined,
            exFactoryPrice: undefined,
            currencyCode: undefined,
        },
    ],
};
