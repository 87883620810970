import { Country } from '../../models/Country';

export const countries: Country[] = [
    { name: 'Austria', alpha2Code: 'AT' },
    { name: 'Belgium', alpha2Code: 'BE' },
    { name: 'Bulgaria', alpha2Code: 'BG' },
    { name: 'Switzerland', alpha2Code: 'CH' },
    { name: 'Cyprus', alpha2Code: 'CY' },
    { name: 'Czechia', alpha2Code: 'CZ' },
    { name: 'Germany', alpha2Code: 'DE' },
    { name: 'Denmark', alpha2Code: 'DK' },
    { name: 'Estonia', alpha2Code: 'EE' },
    { name: 'Spain', alpha2Code: 'ES' },
    { name: 'Finland', alpha2Code: 'FI' },
    { name: 'France', alpha2Code: 'FR' },
    { name: 'United Kingdom', alpha2Code: 'GB' },
    { name: 'Greece', alpha2Code: 'GR' },
    { name: 'Croatia', alpha2Code: 'HR' },
    { name: 'Hungary', alpha2Code: 'HU' },
    { name: 'Ireland', alpha2Code: 'IE' },
    { name: 'Italy', alpha2Code: 'IT' },
    { name: 'Israel', alpha2Code: 'IL' },
    { name: 'Lithuania', alpha2Code: 'LT' },
    { name: 'Luxembourg', alpha2Code: 'LU' },
    { name: 'Latvia', alpha2Code: 'LV' },
    { name: 'Netherlands', alpha2Code: 'NL' },
    { name: 'Norway', alpha2Code: 'NO' },
    { name: 'Panama', alpha2Code: 'PA' },
    { name: 'Poland', alpha2Code: 'PL' },
    { name: 'Portugal', alpha2Code: 'PT' },
    { name: 'Romania', alpha2Code: 'RO' },
    { name: 'Sweden', alpha2Code: 'SE' },
    { name: 'Slovenia', alpha2Code: 'SI' },
    { name: 'Slovakia', alpha2Code: 'SK' },
];
