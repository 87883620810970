//Product list v3.0 for production
import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
    isLoadingProducts,
    getSearchTotalIncludingNotLoaded,
    getSearchResult,
    getSelectedProducts,
    getAllProducts,
    getRequestTimeInSeconds,
} from '../../store/ProductsV2/selectors';
import { fetchProducts, productSelected, productDeselected } from '../../store/ProductsV2/actions';
import { columnsArray } from './columns';
import ProductsFilters from './ProductsFilters';
import { Filters } from './ProductsFilters/types';
import { TK } from '../../store/Translations/translationKeys';
import { useTranslations } from '../../store/Translations/hooks';
import { Box, Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import queryString from 'query-string';
import { ProductV2 } from '../../models/ProductV2';
import { goToProducts, goToSuppliers } from '../../store/Router/actions';
import Table from '../../components/AGTable';
import { getProductsV2TableSettings } from '../../store/Session/selectors';
import Page from '../../components/Page';
import { goBack } from 'react-router-redux';
import { ColDef, ColGroupDef, RowSelectedEvent } from 'ag-grid-community';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { PanelButtonsContainer } from '../../components/Panel';
import { useUrl } from '../../utils/hooks/url';
import { ProductDetailDialog } from '../../components/ProductDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollableButton: {
            top: '250px',
            right: '30px',
            zIndex: 10,
        },
    }),
);

const ProductsList: React.FC = () => {
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const { offset } = useParams<{ offset?: string }>();
    const location = useLocation();

    const path = useUrl();
    const dispatch = useDispatch();
    const t = useTranslations();
    const classes = useStyles();

    const isFirstRun = React.useRef(true);

    const bottomRef = useRef<HTMLDivElement>(null);

    const allProducts = useSelector(getAllProducts) || {};
    const searchResult = useSelector(getSearchResult) || {};

    let selectedProductsIds = useSelector(getSelectedProducts) || [];
    const total = useSelector(getSearchTotalIncludingNotLoaded) || 0;
    const isLoading = useSelector(isLoadingProducts);
    const tableSettings = useSelector(getProductsV2TableSettings);

    const parsedOffset = (offset && parseInt(offset)) || 0;

    const [isSwitchDisabled, setIsSwitchDisabled] = React.useState<boolean>(false);
    let [isShowCommercialised, setIsShowCommercialised] = React.useState<boolean>(true);
    const [isEuropean, setIsEuropean] = React.useState<boolean>(true);
    const [isNonEuropean, setIsNonEuropean] = React.useState<boolean>(true);
    const [products, setProducts] = React.useState<ProductV2[]>([]);
    const [selectedProducts, setSelectedProducts] = React.useState<ProductV2[]>([]);
    const [isScrollTopBtn, setIsScrollTopBtn] = React.useState<boolean>(false);
    const [lastProductOpen, setLastProductOpen] = React.useState<ProductV2 | null>(null); // Used only for dialog fade effect
    const [totalRecords, setTotalRecords] = React.useState<number>(total);

    const searchColumns: string[] = [
        'name',
        'activeSubstances',
        'pharmaceuticalFormCategories',
        'strength',
        'package',
        'atc',
        'maHolder',
    ];
    const customComparator = (valueA ?: string, valueB ?: string) => {
        return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase() ?? '');
    };
    const handleDeselectAll = React.useCallback(
        () => selectedProductsIds.forEach((productId) => dispatch(productDeselected(productId))),
        [dispatch, selectedProductsIds],
    );

    const defaultColDef = React.useMemo(
        () => ({
            flex: 1,
            filter: true,
            sortable: true,
            wrapHeaderText: true,
            minWidth: 125,
            resizable: true,
            autoHeaderHeight: true,
            comparator: customComparator,
            suppressMovable: true,
        }),
        [],
    );

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    React.useEffect(() => {
        setTimeout(() => {
            if (window.localStorage.getItem('queryString')) {
                setProducts(searchResult.map((id) => allProducts[id]));
                window?.localStorage.removeItem('queryString');
            }
        }, 1000);

        const handleScroll = () => {
            if (window.scrollY > 1400) {
                setIsScrollTopBtn(true);
                const buttonElement: HTMLElement | null = window.document.querySelector<HTMLElement>('#scrollToTop');
                if (buttonElement != null) {
                    buttonElement.style.top = 400 + window.scrollY + 'px';
                }
            } else {
                setIsScrollTopBtn(false);
            }
        };
        const bodyObject: HTMLElement | null = window.document.querySelector<HTMLElement>('#body');

        if (bodyObject != null) {
            bodyObject.style.height = '100%';
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (bodyObject != null) {
                bodyObject.style.height = '100vh';
            }
        };
    }, []);

    React.useEffect(() => {
        setIsSwitchDisabled(false);
    }, [products, setIsSwitchDisabled]);

    const query = React.useMemo(() => {
        let query =
            isFirstRun && !location.search.length
                ? {} //{ isAuthorised: 'yes', isMarketed: ['yes', 'unknown'], sortBy: 'country' }
                : queryString.parse(location.search);

        if (window?.location.search.includes('?')) {
            query = queryString.parse(window?.location.search);
        }

        return query as Filters & { sortBy: string; sortType?: 'asc' | 'desc' };
        // eslint-disable-next-line
    }, [location.search]);

    const filteryByIsCommercial = React.useCallback(
        (isCommercial: boolean) => {
            let itemsList: ProductV2[] = [];

            if (isEuropean && isNonEuropean) {
                itemsList = searchResult.map((id) => allProducts[id]);
                if (isCommercial) {
                    itemsList = itemsList.filter((item) => {
                        if (item.isAuthorised !== false && item.isMarketed !== false) {
                            return true;
                        }
                    });
                } else {
                    itemsList = searchResult.map((id) => allProducts[id]);
                }
            } else if (isEuropean || isNonEuropean) {
                filterItemsByTypes(isEuropean, isNonEuropean, isCommercial);
                return;
            }

            setProducts(itemsList);
            setTotalRecords(itemsList.length);
            setIsShowCommercialised(isCommercial);
        },
        [
            products,
            searchResult,
            isEuropean,
            isNonEuropean,
            isShowCommercialised,
            setProducts,
            setTotalRecords,
            setIsShowCommercialised,
        ],
    );

    const filterItemsByTypes = React.useCallback(
        (isEuropean: boolean, isNonEuropean: boolean, isCommercial = null) => {
            //Collect commercial items
            isShowCommercialised = isCommercial != null ? isCommercial : isShowCommercialised;

            let itemsList: ProductV2[] = [];
            itemsList = searchResult.map((id) => allProducts[id]);
            if (isEuropean) {
                itemsList = itemsList.filter((item) => {
                    if (item.isEuropeanCountry) {
                        return true;
                    }
                });
            } else if (isNonEuropean) {
                itemsList = itemsList.filter((item) => {
                    if (!item.isEuropeanCountry) {
                        return true;
                    }
                });
            }

            if (isNonEuropean && isEuropean) {
                itemsList = searchResult.map((id) => allProducts[id]);
            } else if (!isNonEuropean && !isEuropean) {
                itemsList = [];
            }

            if (isShowCommercialised) {
                itemsList = itemsList.filter((item) => {
                    if (item.isAuthorised !== false && item.isMarketed !== false) {
                        return true;
                    }
                });
            }
            setProducts(itemsList);
            setTotalRecords(itemsList.length);
            setIsEuropean(isEuropean);
            setIsNonEuropean(isNonEuropean);
            setIsShowCommercialised(isShowCommercialised);
        },
        [
            products,
            searchResult,
            isShowCommercialised,
            setIsEuropean,
            setIsNonEuropean,
            setProducts,
            setTotalRecords,
            setIsShowCommercialised,
        ],
    );

    React.useMemo(
        () => {
            if (!window.location.search.includes('?')) {
                setProducts([]);
                handleDeselectAll();
            } else {
                filteryByIsCommercial(isShowCommercialised);
            }
        },
        // eslint-disable-next-line
        [searchResult],
    );

    React.useMemo(
        () => setSelectedProducts(selectedProductsIds.map((id) => allProducts[id])),
        // eslint-disable-next-line
        [selectedProductsIds],
    );

    React.useMemo(
        () => {
            if (!isFirstRun.current || location.search?.length) {
                dispatch(fetchProducts(parsedOffset, tableSettings.pageSize, query));
            }
        },
        // eslint-disable-next-line
        [parsedOffset, tableSettings.pageSize, query],
    );

    React.useEffect(() => {
        isFirstRun.current = false;
        setHeaderName(t(TK.products));
    }, [setHeaderName, path, t]);

    const productOpen = React.useMemo(
        () => (location.hash?.length > 1 ? allProducts[location.hash.substring(1)] : null),
        [location, allProducts],
    );

    const onRowDataUpdated = (params: any) => {
        if (selectedProducts && selectedProducts.length) {
            params.api.forEachNode((node: any) => {
                const isProductPresent = selectedProducts.find((product) => product.id === node.data.id);
                if (isProductPresent) {
                    node.setSelected(true);
                }
            });
        }
    };

    const handleFiltersChange = React.useCallback(
        (newFilters: Filters) => {
            if (Object.keys(newFilters).length === 0) {
                setProducts([]);
                handleDeselectAll();
            }

            dispatch(goToProducts(undefined, newFilters));
        },
        [dispatch, setProducts],
    );

    const handleProceedClick = React.useCallback(() => {
        window?.localStorage.setItem('queryString', window?.location.search);
        return dispatch(goToSuppliers());
    }, [dispatch]);

    const handleItemSelectionChange = React.useCallback(
        (id: string, selected: boolean) => dispatch(selected ? productSelected(id) : productDeselected(id)),
        [dispatch],
    );

    const handleDialogClose = React.useCallback(() => {
        setLastProductOpen(productOpen);
        dispatch(goBack());
    }, [dispatch, setLastProductOpen, productOpen]);

    const handleSelectProductOpen = React.useCallback(() => {
        productOpen && handleItemSelectionChange(productOpen.id, true);
        handleDialogClose();
        setTimeout(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }), 500);
    }, [handleDialogClose, handleItemSelectionChange, productOpen]);

    return (
        <Page>
            <ProductDetailDialog
                productOpen={productOpen}
                handleSelectProductOpen={handleSelectProductOpen}
                handleDialogClose={handleDialogClose}
            />
            {isScrollTopBtn && (
                <Box position="absolute" id="scrollToTop" className={classes.scrollableButton}>
                    <Button color="primary" variant="contained" onClick={goToTop}>
                        <ExpandLessIcon />
                    </Button>
                </Box>
            )}
            <ProductsFilters
                filterItemsByTypes={filterItemsByTypes}
                filteryByIsCommercial={filteryByIsCommercial}
                setIsSwitch={setIsSwitchDisabled}
                isSwitch={isSwitchDisabled}
                isLoading={isLoading}
                defaultFilters={query}
                onChange={handleFiltersChange}
            />
            <Box justifyContent="center" display="flex" height={'34px'} marginBottom={'15px'}>
                {!!selectedProductsIds.length && (
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<NavigateNextIcon />}
                        onClick={handleProceedClick}
                    >
                        {t(TK.proceedToRFQCreation)}
                    </Button>
                )}
            </Box>
            {!isFirstRun.current && (
                <Table
                    columnsDefinition={columnsArray as ColDef[] | ColGroupDef[]}
                    isLoading={isLoading}
                    total={totalRecords}
                    isExportable={false}
                    enablePagination={true}
                    captionAnalytics={TK.TotalResult}
                    gridType="products"
                    // rowGroupPanelShow={'always'}
                    pageSize={10}
                    defaultColDef={defaultColDef}
                    
                    searchColumns={searchColumns}
                    data={products}
                    onRowDataUpdated={onRowDataUpdated}
                    rowSelection="multiple"
                    onRowSelected={(event: RowSelectedEvent) => {
                        const {
                            node: {
                                data: { id: productId },
                            },
                        } = event;

                        handleItemSelectionChange(productId as string, !!event.node.isSelected());
                    }}
                />
            )}
            {!!selectedProductsIds.length && (
                <>
                    {/* <Panel title={`${t(TK.selectedProducts)} (${selectedProducts.length})`}>
                        {!selectedProducts.length && <p>{t(TK.noProductSelected)}</p>}
                        <List
                            items={selectedProducts.map((p) => ({ ...p, isSelected: true }))}
                            renderDetails={(p: ProductV2): React.ReactNode => (
                                <ProductDetails product={p} renders={renders} />
                            )}
                            renderName={(p: ProductV2): string => p.name}
                            renderSummary={(p: ProductV2): string =>
                                `(${[p.atc, p.pharmaceuticalForm, p.strength, p.package].filter((x) => x).join(' - ')})`
                            }
                            onItemDeselected={(p: ProductV2) => dispatch(productDeselected(p.id))}
                        />
                        {!!selectedProducts.length && <Button onClick={handleDeselectAll}>{t(TK.deselectAll)}</Button>}
                    </Panel> */}

                    <PanelButtonsContainer>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<NavigateNextIcon />}
                            onClick={handleProceedClick}
                        >
                            {t(TK.proceedToRFQCreation)}
                        </Button>
                    </PanelButtonsContainer>
                </>
            )}

            <div ref={bottomRef} />
        </Page>
    );
};

export default ProductsList;
