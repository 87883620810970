
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { PlatformPformState, LogState } from './state';
import { LogsDataHistoryLoadedAction, LogsDataLoadedAction, LogsDataReportLoadedAction, LogsSourcesLoadedAction } from './types';
import { stat } from 'fs';

const unloadedState: LogState = {
    logs: {},
    sources: {},
    logsHistory: [],
    isLoading: false,
    isLoadingHistory: false,
};

export const persistor = (state: LogState): LogState => ({
    ...unloadedState,
});

export const reconciler = (stored: LogState): LogState => ({
    ...stored,
    //isLoading: false,
});

const handlePlatformDataLoading = (state: LogState): LogState => ({
    logs: state.logs,
    logsHistory: state.logsHistory,
    sources: {},
    isLoading: true,
    isLoadingHistory: true,
});

const handlePlatformDataLoadError = (state: LogState): LogState => ({
    ...state,
    isLoading: false,
});

const handlePlatformProcessingSettingsLoaded = (state: LogState, action: LogsDataLoadedAction): LogState  => ({
    //...state,
    isLoading: false,
    isLoadingHistory: false,
    logsHistory: state.logsHistory,
    sources: state.sources,
    logs: action.result
});
const handlePlatformProcessingSettingsReportLoaded = (state: LogState, action: LogsDataReportLoadedAction): LogState => ({
    //...state,
    isLoading: false,
    isLoadingHistory: false,
    logsHistory: state.logsHistory,
    logs: state.logs,
    sources: state.sources,
    reportLog: action.result
});
const handleLogsSourcesLoaded = (state: LogState, action: LogsSourcesLoadedAction): LogState => ({
    //...state,
    isLoading: false,
    isLoadingHistory: false,
    logsHistory: state.logsHistory,
    logs: state.logs,
    sources: action.result,

});
const handlePlatformProcessingHistSettingsLoaded = (state: LogState, action: LogsDataHistoryLoadedAction): LogState  => ({
    //...state,
    isLoading: false,
    isLoadingHistory: false,
    sources: state.sources,
    logsHistory: action.result,
    logs: state.logs
});

export const reducer: Reducer<LogState> = (state: LogState = unloadedState, action: Action): LogState  => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.logsDataLoading:
            return handlePlatformDataLoading(state);
        case Actions.logsDataLoadError:
            return handlePlatformDataLoadError(state);
        case Actions.logsDataLoaded:
            return handlePlatformProcessingSettingsLoaded(state, action as LogsDataLoadedAction );
        case Actions.logsDataHistoryLoaded:
            return handlePlatformProcessingHistSettingsLoaded(state, action as LogsDataHistoryLoadedAction);
        case Actions.logsDataReportLoaded:
            return handlePlatformProcessingSettingsReportLoaded(state, action as LogsDataReportLoadedAction);
        case Actions.logsSourcesLoaded:
            return handleLogsSourcesLoaded(state, action as LogsSourcesLoadedAction);
        default:
            return state;
    }
};
