import * as React from 'react';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import Panel, { PanelButtonsContainer } from '../../../components/Panel';
import { AppBar, Tabs, Tab, Button, Typography, Grid, Box } from '@material-ui/core';
import SingleSelectInput from '../../../components/inputs/SingleSelectInput';
import {
    getPlatformPformProcessingSettings,
    getPlatformProcessingSettings,
} from '../../../store/Platform/selectors';
import { useSelector, useDispatch } from 'react-redux';
import CountryFlag from '../../../components/CountryFlag';
import { Country } from '../../../models/Country';

import ProductsCompareTable from './ProductsCompareTable';
import Loading from '../../../components/Loading';
import { getMyUser, getUser } from '../../../store/Users/selectors';
import { ProductsCompareList } from '../../../models/ProductCompare';
import { getListCountries, getProductsCompareTable, getSetAsync, getSetV2Async, putProccesCountry } from '../../../fetch/requests';
import { requestServer } from '../../../store/Session/actions';



const ProcessingSettingsPanel: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const [products, setProducts] = React.useState<ProductsCompareList>({});
    const [origin, setOrigin] = React.useState<string | null | undefined>(undefined);
    const [countries, setCountries] = React.useState<Country[]>([]);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [tab, setTab] = React.useState(0);
    const [isShowFullList, setIsShowFullList] = React.useState<boolean>(false);
    React.useEffect(() => {
        Promise.resolve(dispatch(requestServer((token, csrfToken) => getListCountries(token, csrfToken))))
            .then((value: any) => {
                var r = (value ?? []) as Country[];
                setCountries(r);
            });
    }, [setCountries, changed]);
    
    const showFullList = React.useCallback(() => {
        let cty = origin as string;        
        Promise.resolve(dispatch(requestServer((token, csrfToken) => putProccesCountry(cty, {}, token, csrfToken))))
            .then(() => {
                setOrigin(undefined);
                setChanged(!changed);
            });
    }, [setOrigin, origin, setChanged, changed]);


    const handleOriginChange = React.useCallback(
        (countryObject: Country) => {
            let origin = countryObject ? countryObject.alpha2Code : null;
            setIsLoading(true);
            setOrigin(origin);
            Promise.resolve(dispatch(requestServer((token, csrgToken) => getProductsCompareTable(origin!, token, csrgToken))))
                .then((value) => {
                    var r = value as ProductsCompareList;
                    setIsLoading(false);
                    setProducts(r);
                    
                });
            //getProductsCompareTable(origin!).then(i => {
            //    setIsLoading(false);
            //})
        },
        [setProducts, setOrigin, dispatch, setIsLoading],
    );
    return (
        <Panel title={t(TK.productsCompare)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={6}>
                    <SingleSelectInput
                        label={t(TK.countries)}
                        value={origin}
                        options={countries}
                        isLoading={!countries?.length}                        
                        isCustomFilter={true}                        
                        onChange={handleOriginChange}
                        renderOption={(option: Country): React.ReactNode => (
                            <React.Fragment>
                                {/*                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                />*/}
                                <CountryFlag
                                    countryCode={option.alpha2Code}
                                    showCode={false}
                                    country={`${option.name} (${option.alpha2Code})`}
                                />
                            </React.Fragment>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button disabled={tab > 1} onClick={showFullList} fullWidth variant="contained">
                        Process Country
                    </Button>
                </Grid>
            </Grid>

            {origin && (
                <>
                    <AppBar position="static">
{/*                        <Tabs value={tab} onChange={(_, tab) => showContentByTab(tab)} variant="fullWidth">
                            */}{/* <Tab label={t(TK.general)} /> */}{/*
                            <Tab label={t(TK.drugForm)} />
                            <Tab label={t(TK.activeSubstances)} />
                            <Tab label={t(TK.atc)} />
                        </Tabs>*/}
                    </AppBar>
                    <Box>
                        {isLoading ? (
                            <Loading isLoading={isLoading} style={{top:"0"}} />
                        ) : (
                            products &&
                            !isShowFullList &&
                            tab == 0 && (
                                    <ProductsCompareTable
                                        products={products}
                                />
                            )
                        )}
                    </Box>
                </>
            )}
        </Panel>
    );
};

export default ProcessingSettingsPanel;
