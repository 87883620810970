export enum PharmaUnits {
	None = 'None',
	Dose = 'Dose',
	Percent = 'Percent',
	g = 'g',
	mg = 'mg',
	l = 'l',
	ml = 'ml',
	h = 'h',
	ug = 'ug',
	MBq = 'MBq',
	GBq = 'GBq',
	U = 'U',
	IU = 'IU',
	M_U = 'M_U',
	M_IU = 'M_IU',
	mol = 'mol',
	mmol = 'mmol',
	umol = 'umol',
	M = 'M',
}