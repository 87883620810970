import { SearchResult } from '../../models/SearchResult';
import { Shortage } from '../../models/Shortage';
import { ShortageType } from '../../models/ShortageType';
import { ProductStatus } from '../../models/ProductStatus';

export const shortagesMock: SearchResult<Shortage> = {
    total: 1250,
    timeInSeconds: 0.58,
    items: [
        {
            id: '5ed0f54697dbb9544fb8b517',
            productId: '5eb182394ecb317d0e012bc8',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-31T00:00:00Z',
            end: '2020-07-17T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Novartis Pharma Services Inc, Podružnica v Sloveniji. (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:39.612Z',
            product: {
                id: '5eb182394ecb317d0e012bc8',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 41080,
                lastUpdate: '13/07/2020 15:41:48',
                name: 'BETOPTIC S 2,5 MG/ML KAPLJICE ZA OKO, SUSPENZIJA, ŠKATLA S KAPALNIM VSEBNIKOM S 5 ML SUSPENZIJE',
                activeSubstances: ['BETAXOLOL'],
                atc: 'S01ED02',
                productCode: '089184',
                maHolder: 'Novartis Pharma GmbH',
                status: ProductStatus.Marketed,
                wholesalePrice: 4.21,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-31T00:00:00Z',
                    end: '2020-07-17T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Novartis Pharma Services Inc, Podružnica v Sloveniji. (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:50:39.575Z',
                },
            },
        },
        {
            id: '5ed0f54697dbb9544fb8b52e',
            productId: '5eb182e94ecb317d0e01b9e0',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-04-16T00:00:00Z',
            end: '2020-06-26T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by sanofi-aventis d.o.o. (Marketing authorization holder) Notification of the actual re-entry of the medicinal product on the market following a previously notified supply disruption',
            lastUpdate: '2020-06-29T15:08:17.723Z',
            product: {
                id: '5eb182e94ecb317d0e01b9e0',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 48297,
                lastUpdate: '13/07/2020 15:51:49',
                name:
                    'SABRIL 500 MG FILMSKO OBLOŽENE TABLETE, ŠKATLA S 100 TABLETAMI (10 X 10 TABLET V PRETISNEM OMOTU)',
                activeSubstances: ['VIGABATRIN'],
                atc: 'N03AG04',
                drugForm: 'SOLID TABLET',
                productCode: '072699',
                strength: '500 MG',
                package: '100',
                maHolder: 'sanofi-aventis d.o.o.',
                status: ProductStatus.Marketed,
                wholesalePrice: 60.86,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-04-16T00:00:00Z',
                    end: '2020-06-26T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by sanofi-aventis d.o.o. (Marketing authorization holder) Notification of the actual re-entry of the medicinal product on the market following a previously notified supply disruption',
                    lastUpdate: '2020-06-29T15:08:17.682Z',
                },
            },
        },
        {
            id: '5ed0f54697dbb9544fb8b543',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-06-03T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Krka, d.d., Novo mesto (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:27.318Z',
            product: {
                scrapingOriginId: 0,
                name:
                    'Nalgesin S 275 mg filmsko obložene tablete, škatla z 20 tabletami (2 x 10 tablet v pretisnem omotu)',
                productCode: '071200',
                maHolder: 'Krka, d.d., Novo mesto',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed0f54697dbb9544fb8b556',
            productId: '5eb182404ecb317d0e0131a4',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-05-17T00:00:00Z',
            end: '2020-05-27T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Reported by Mylan d.o.o (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:30.526Z',
            product: {
                id: '5eb182404ecb317d0e0131a4',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 41536,
                lastUpdate: '13/07/2020 15:42:15',
                name: 'CHOLIB 145 MG/40 MG FILMSKO OBLOŽENE TABLETE, 30 TABLET',
                activeSubstances: ['SIMVASTATIN', 'FENOFIBRATE'],
                atc: 'C10BA04',
                drugForm: 'SOLID TABLET',
                productCode: '145926',
                package: '30',
                maHolder: 'Mylan IRE Healthcare Ltd',
                status: ProductStatus.Marketed,
                wholesalePrice: 9.88,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-04-05T00:00:00Z',
                    end: '2020-06-03T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Mylan d.o.o (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:50:48.879Z',
                },
            },
        },
        {
            id: '5ed0f54697dbb9544fb8b569',
            productId: '5eb182334ecb317d0e0125f9',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-23T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Boehringer Ingelheim RCV GmbH&Co KG, Podružnica Ljubljana (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:27.482Z',
            product: {
                id: '5eb182334ecb317d0e0125f9',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 40874,
                lastUpdate: '13/07/2020 15:41:34',
                name:
                    'ASASANTIN 200 MG/25 MG TRDE KAPSULE S PRIREJENIM SPROŠČANJEM, ŠKATLA S 60 KAPSULAMI V POLIPROPILENSKEM VSEBNIKU Z NAVOJNIM VRATOM',
                activeSubstances: ['COMBINATIONS'],
                atc: 'B01AC30',
                drugForm: 'SOLID CAPSULE',
                productCode: '070025',
                package: '60',
                maHolder: 'Boehringer Ingelheim International GmbH',
                status: ProductStatus.Discontinued,
                wholesalePrice: 13.1,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-23T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Boehringer Ingelheim RCV GmbH&Co KG, Podružnica Ljubljana (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:27.441Z',
                },
            },
        },
        {
            id: '5ed0f54697dbb9544fb8b58b',
            productId: '5eb1824f4ecb317d0e013e37',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-01-12T00:00:00Z',
            end: '2020-07-10T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-29T15:08:19.136Z',
            product: {
                id: '5eb1824f4ecb317d0e013e37',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 42210,
                lastUpdate: '13/07/2020 15:42:54',
                name: 'DIFLUCAN 50 MG TRDE KAPSULE, ŠKATLA S 7 KAPSULAMI (1 X 7 KAPSUL V PRETISNEM OMOTU)',
                activeSubstances: ['FLUCONAZOLE'],
                atc: 'J02AC01',
                drugForm: 'SOLID CAPSULE',
                productCode: '056391',
                maHolder: 'Pfizer Luxembourg SARL',
                status: ProductStatus.Marketed,
                wholesalePrice: 8.01,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-01-12T00:00:00Z',
                    end: '2020-07-10T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-29T15:08:19.099Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b5a8',
            productId: '5eb1823b4ecb317d0e012de6',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-04-16T00:00:00Z',
            end: '2020-05-17T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Lek farmacevtska družba d.d. (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:41.167Z',
            product: {
                id: '5eb1823b4ecb317d0e012de6',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 41302,
                lastUpdate: '13/07/2020 15:42:01',
                name: 'CANDEA 16 MG TABLETE, ŠKATLA Z 28 TABLETAMI (4 X 7 TABLET V PRETISNEM OMOTU)',
                activeSubstances: ['CANDESARTAN'],
                atc: 'C09CA06',
                drugForm: 'SOLID TABLET',
                productCode: '079715',
                strength: '16 MG',
                package: '28',
                maHolder: 'Lek d.d.',
                status: ProductStatus.Marketed,
                wholesalePrice: 3.19,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-04-16T00:00:00Z',
                    end: '2020-05-17T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Lek farmacevtska družba d.d. (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:50:41.124Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b5bd',
            productId: '5eb182334ecb317d0e0125f8',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-03-22T00:00:00Z',
            end: '2020-04-05T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Merck Sharp & Dohme, inovativna zdravila d.o.o. (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:59.194Z',
            product: {
                id: '5eb182334ecb317d0e0125f8',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 40880,
                lastUpdate: '13/07/2020 15:41:34',
                name:
                    'ASMANEX TWISTHALER 200 MIKROGRAMOV/VDIH PRAŠEK ZA INHALIRANJE, ŠKATLA S PLASTIČNIM VSEBNIKOM S PRAŠKOM ZA INHALIRANJE ZA 60 VDIHOV (VSEBNIK Z ODMERNIM VENTILOM IN USTNIKOM)',
                activeSubstances: ['MOMETASONE'],
                atc: 'R03BA07',
                productCode: '030066',
                maHolder: 'Merck Sharp & Dohme, d.o.o.',
                status: ProductStatus.Marketed,
                wholesalePrice: 14.15,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-02-19T00:00:00Z',
                    end: '2020-04-05T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Merck Sharp & Dohme, inovativna zdravila d.o.o. (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:51:11.132Z',
                },
            } as any,
        },
        {
            id: '5ed0f54797dbb9544fb8b5d0',
            productId: '5eb268f64ecb317d0e3c483e',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-03T00:00:00Z',
            end: '2021-01-02T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Reported by Sanolabor,d.d. (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:27.825Z',
            product: {
                id: '5eb268f64ecb317d0e3c483e',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 679462,
                lastUpdate: '14/07/2020 20:08:38',
                name:
                    'REMIFENTANIL HAMELN 1 MG PRAŠEK ZA KONCENTRAT ZA RAZTOPINO ZA INJICIRANJE/INFUNDIRANJE, ŠKATLA S 5 VIALAMI (VOLUMNA 4 ML) S PRAŠKOM',
                activeSubstances: ['REMIFENTANIL'],
                atc: 'N01AH06',
                drugForm: 'SOLID POWDER',
                productCode: '144046',
                strength: '1 MG',
                package: '5',
                maHolder: 'SANOLABOR, d.d.',
                status: ProductStatus.Shortage,
                wholesalePrice: 14.05,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-03T00:00:00Z',
                    end: '2021-01-02T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Sanolabor,d.d. (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:50:27.785Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b5e6',
            productId: '5eb1827f4ecb317d0e016555',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-17T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Johnson&Johnson d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:27.904Z',
            product: {
                id: '5eb1827f4ecb317d0e016555',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 44286,
                lastUpdate: '13/07/2020 15:46:10',
                name: 'INVEGA 6 MG TABLETE S PODALJŠANIM SPROŠČANJEM, 28 TABLET V PRETISNEM OMOTU IZ PVC/PFCTE/ALU',
                activeSubstances: ['PALIPERIDONE'],
                atc: 'N05AX13',
                drugForm: 'SOLID TABLET',
                productCode: '023400',
                strength: '6 MG',
                package: '28',
                maHolder: 'Janssen-Cilag International NV',
                status: ProductStatus.Discontinued,
                wholesalePrice: 114.28,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-17T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Johnson&Johnson d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:27.866Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b5fc',
            productId: '5eb1827f4ecb317d0e016577',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-31T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Johnson&Johnson d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:27.982Z',
            product: {
                id: '5eb1827f4ecb317d0e016577',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 44283,
                lastUpdate: '13/07/2020 15:46:09',
                name: 'INVEGA 3 MG TABLETE S PODALJŠANIM SPROŠČANJEM, 28 TABLET V PRETISNEM OMOTU IZ PVC/PFCTE/ALU',
                activeSubstances: ['PALIPERIDONE'],
                atc: 'N05AX13',
                drugForm: 'SOLID TABLET',
                productCode: '023370',
                strength: '3 MG',
                package: '28',
                maHolder: 'Janssen-Cilag International NV',
                status: ProductStatus.Discontinued,
                wholesalePrice: 110.07,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-31T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Johnson&Johnson d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:27.945Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b612',
            productId: '5eb182ab4ecb317d0e018962',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-04-15T00:00:00Z',
            end: '2020-05-30T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Krka, d.d., Novo mesto (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:45.848Z',
            product: {
                id: '5eb182ab4ecb317d0e018962',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 46032,
                lastUpdate: '13/07/2020 15:48:23',
                name: 'MOLOXIN 400 MG FILMSKO OBLOŽENE TABLETE, ŠKATLA S 5 TABLETAMI (1 X 5 TABLET V PRETISNEM OMOTU)',
                activeSubstances: ['MOXIFLOXACIN'],
                atc: 'J01MA14',
                drugForm: 'SOLID TABLET',
                productCode: '146543',
                strength: '400 MG',
                package: '5',
                maHolder: 'KRKA, d.d., Novo mesto',
                status: ProductStatus.Marketed,
                wholesalePrice: 7.79,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-04-15T00:00:00Z',
                    end: '2020-05-30T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Krka, d.d., Novo mesto (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:50:45.811Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b626',
            productId: '5eb225084ecb317d0e2f9f1e',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-23T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Lenis d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:28.138Z',
            product: {
                id: '5eb225084ecb317d0e2f9f1e',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 598130,
                lastUpdate: '14/07/2020 18:07:19',
                name: 'ATARAX 25 MG FILMSKO OBLOŽENE TABLETE, ŠKATLA Z 28 FILMSKO OBLOŽENIMI TABLETAMI',
                activeSubstances: ['HYDROXYZINE'],
                atc: 'N05BB01',
                drugForm: 'SOLID TABLET',
                productCode: '143472',
                strength: '25 MG',
                package: '28',
                maHolder: 'LENIS d.o.o.',
                status: ProductStatus.Shortage,
                wholesalePrice: 3.5,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-06-03T00:00:00Z',
                    end: '2021-01-02T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Lenis d.o.o. (Marketing authorization holder) Notification of suspension of traffic',
                    lastUpdate: '2020-06-03T13:50:44.549Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b63a',
            productId: '5eb1823e4ecb317d0e012f8d',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-06-02T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Lek farmacevtska družba d.d. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:28.221Z',
            product: {
                id: '5eb1823e4ecb317d0e012f8d',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 41414,
                lastUpdate: '13/07/2020 15:42:07',
                name:
                    'CEFTRIAKSON LEK 1 G PRAŠEK ZA RAZTOPINO ZA INJICIRANJE ALI INFUNDIRANJE, ŠKATLA Z 10 VIALAMI S PRAŠKOM',
                activeSubstances: ['CEFTRIAXONE'],
                atc: 'J01DD04',
                drugForm: 'SOLID POWDER',
                productCode: '092401',
                strength: '1000 MG',
                package: '10',
                maHolder: 'Lek d.d.',
                status: ProductStatus.Discontinued,
                wholesalePrice: 68.6,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-06-02T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Lek farmacevtska družba d.d. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:28.179Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b651',
            productId: '5eb1f1704ecb317d0e24abf8',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-02-03T00:00:00Z',
            end: '2020-03-22T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Lek farmacevtska družba d.d. (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:51:20.171Z',
            product: {
                id: '5eb1f1704ecb317d0e24abf8',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 524177,
                lastUpdate: '14/07/2020 15:46:32',
                name: 'AMCANDIN 8 MG/5 MG TRDE KAPSULE, ŠKATLA Z 28 KAPSULAMI (4 X 7 KAPSUL V PRETISNEM OMOTU)',
                activeSubstances: ['CANDESARTAN', 'AMLODIPINE'],
                atc: 'C09DB07',
                drugForm: 'SOLID CAPSULE',
                productCode: '148043',
                package: '28',
                maHolder: 'Sandoz d.d.',
                status: ProductStatus.Marketed,
                wholesalePrice: 26.66,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2018-11-28T00:00:00Z',
                    end: '2019-02-14T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Lek farmacevtska družba d.d. (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:53:16.339Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b668',
            productId: '5eb182a24ecb317d0e0181bd',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-05-22T00:00:00Z',
            end: '2020-07-16T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-29T15:08:20.85Z',
            product: {
                id: '5eb182a24ecb317d0e0181bd',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 45639,
                lastUpdate: '13/07/2020 15:47:49',
                name: 'MEDROL 4 MG TABLETE, ŠKATLA S STEKLENIČKO S 30 TABLETAMI',
                activeSubstances: ['METHYLPREDNISOLONE'],
                atc: 'H02AB04',
                drugForm: 'SOLID TABLET',
                productCode: '066516',
                strength: '4 MG',
                package: '30',
                maHolder: 'Pfizer Luxembourg SARL',
                status: ProductStatus.Marketed,
                wholesalePrice: 2.49,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-22T00:00:00Z',
                    end: '2020-07-16T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-29T15:08:20.813Z',
                },
            },
        },
        {
            id: '5ed0f54797dbb9544fb8b67b',
            productId: '5eb1823e4ecb317d0e012f5b',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-02-23T00:00:00Z',
            end: '2020-03-25T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:51:03.917Z',
            product: {
                id: '5eb1823e4ecb317d0e012f5b',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 41346,
                lastUpdate: '13/07/2020 15:42:04',
                name:
                    'CAVERJECT 10 MIKROGRAMOV PRAŠEK IN VEHIKEL ZA RATOPINO ZA INJICIRANJE, ŠKATLA Z 1 VIALO S PRAŠKOM, INJEKCIJSKO BRIZGO Z VEHIKLOM, 2 INJEKCIJSKI IGLI IN 2 KRPICAMA PREPOJENIMA Z ALKOHOLOM',
                activeSubstances: ['ALPROSTADIL'],
                atc: 'G04BE01',
                productCode: '095230',
                maHolder: 'Pfizer Luxembourg SARL',
                status: ProductStatus.Marketed,
                wholesalePrice: 8.7,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2019-08-11T00:00:00Z',
                    end: '2019-12-04T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:52:19.04Z',
                },
            } as any,
        },
        {
            id: '5ed0f54797dbb9544fb8b69d',
            productId: '5eb182df4ecb317d0e01b190',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-01-24T00:00:00Z',
            end: '2020-02-16T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:51:19.04Z',
            product: {
                id: '5eb182df4ecb317d0e01b190',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 47919,
                lastUpdate: '13/07/2020 15:51:26',
                name: 'RELPAX 20 MG FILMSKO OBLOŽENE TABLETE, ŠKATLA S 6 TABLETAMI (1 X 6 TABLET V PRETISNEM OMOTU)',
                activeSubstances: ['ELETRIPTAN'],
                atc: 'N02CC06',
                drugForm: 'SOLID TABLET',
                productCode: '024511',
                strength: '20 MG',
                package: '6',
                maHolder: 'Pfizer Luxembourg SARL',
                status: ProductStatus.Marketed,
                wholesalePrice: 7.17,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2019-09-29T00:00:00Z',
                    end: '2019-11-03T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes:
                        'Reported by Pfizer Luxembour SARL, Pfizer, podružnica za svetovanje s področja farmacevtske dejavnosti, Ljubljana (Marketing authorization holder) Report a supply disruption',
                    lastUpdate: '2020-06-03T13:52:05.69Z',
                },
            },
        },
        {
            id: '5ed0f54897dbb9544fb8b6b1',
            productId: '5eb1f0774ecb317d0e247392',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2018-08-11T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:28.638Z',
            product: {
                id: '5eb1f0774ecb317d0e247392',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 522583,
                lastUpdate: '14/07/2020 15:45:02',
                name: 'HUMULIN N 100 I.E./ML SUSPENZIJA ZA INJICIRANJE V VLOŽKU, ŠKATLA S 5 VLOŽKI S 3 ML SUSPENZIJE',
                activeSubstances: ['INSULIN (HUMAN)'],
                atc: 'A10AC01',
                drugForm: 'LIQUID SUSPENSION',
                productCode: '147909',
                maHolder: 'ELI LILLY FARMACEVTSKA DRUŽBA, d.o.o.',
                status: ProductStatus.Discontinued,
                wholesalePrice: 25.35,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2018-08-11T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:28.595Z',
                },
            },
        },
        {
            id: '5ed0f54897dbb9544fb8b6c7',
            productId: '5eb1f0774ecb317d0e247383',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2018-10-31T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:28.722Z',
            product: {
                id: '5eb1f0774ecb317d0e247383',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 522584,
                lastUpdate: '14/07/2020 15:45:02',
                name:
                    'HUMULIN N KWIKPEN 100 I.E./ML SUSPENZIJA ZA INJICIRANJE, ŠKATLA S 5 NAPOLNJENIMI INJEKCIJSKIMI PERESNIKI S 3 ML SUSPENZIJE',
                activeSubstances: ['INSULIN (HUMAN)'],
                atc: 'A10AC01',
                drugForm: 'LIQUID SUSPENSION',
                productCode: '147910',
                maHolder: 'ELI LILLY FARMACEVTSKA DRUŽBA, d.o.o.',
                status: ProductStatus.Discontinued,
                wholesalePrice: 26.39,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2018-10-31T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:28.685Z',
                },
            },
        },
        {
            id: '5ed0f54897dbb9544fb8b6dc',
            productId: '5eb1f0774ecb317d0e2473a3',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2018-06-20T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:28.816Z',
            product: {
                id: '5eb1f0774ecb317d0e2473a3',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 522585,
                lastUpdate: '14/07/2020 15:45:02',
                name: 'HUMULIN R 100 I.E./ML RAZTOPINA ZA INJICIRANJE V VLOŽKU, ŠKATLA S 5 VLOŽKI S 3 ML RAZTOPINE',
                activeSubstances: ['INSULIN (HUMAN)'],
                atc: 'A10AB01',
                productCode: '147911',
                maHolder: 'ELI LILLY FARMACEVTSKA DRUŽBA, d.o.o.',
                status: ProductStatus.Discontinued,
                wholesalePrice: 25.35,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2018-06-20T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:28.768Z',
                },
            } as any,
        },
        {
            id: '5ed0f54897dbb9544fb8b6f1',
            productId: '5eb1f0774ecb317d0e2473a8',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2019-01-27T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:28.904Z',
            product: {
                id: '5eb1f0774ecb317d0e2473a8',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 522582,
                lastUpdate: '14/07/2020 15:45:03',
                name:
                    'HUMULIN M3 KWIKPEN 100 I.E./ML SUSPENZIJA ZA INJICIRANJE, ŠKATLA S 5 NAPOLNJENIMI INJEKCIJSKIMI PERESNIKI S 3 ML SUSPENZIJE',
                activeSubstances: ['INSULIN (HUMAN)'],
                atc: 'A10AD01',
                drugForm: 'LIQUID SUSPENSION',
                productCode: '147908',
                maHolder: 'ELI LILLY FARMACEVTSKA DRUŽBA, d.o.o.',
                status: ProductStatus.Discontinued,
                wholesalePrice: 29.25,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2019-01-27T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Eli Lilly farmacevtska družba, d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:28.861Z',
                },
            },
        },
        {
            id: '5ed0f54897dbb9544fb8b6fd',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-27T00:00:00Z',
            end: '2020-08-23T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Merck Sharp & Dohme, inovativna zdravila d.o.o. (Marketing authorization holder) Report a supply disruption',
            lastUpdate: '2020-06-03T13:50:28.981Z',
            product: {
                scrapingOriginId: 0,
                name: 'PROPECIA 1 mg filmsko obložene tablete, škatla z 28 tabletami (4 x 7 tablet v pretisnem omotu)',
                activeSubstances: [],
                productCode: '002674',
                maHolder: 'Merck Sharp & Dohme, inovativna zdravila d.o.o.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed0f54897dbb9544fb8b711',
            productId: '5eb182604ecb317d0e014c16',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: true,
            start: '2020-05-20T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes:
                'Reported by Lenis d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
            lastUpdate: '2020-06-03T13:50:29.062Z',
            product: {
                id: '5eb182604ecb317d0e014c16',
                country: 'Slovenia',
                countryCode: 'SI',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 43039,
                lastUpdate: '13/07/2020 15:44:55',
                name:
                    'ESTIMA GE 200 MG MEHKE KAPSULE ZA PERORALNO ALI VAGINALNO UPORABO, ŠKATLA S 15 KAPSULAMI V PRETISNEM OMOTU',
                activeSubstances: ['PROGESTERONE'],
                atc: 'G03DA04',
                drugForm: 'SOLID CAPSULE',
                productCode: '144497',
                maHolder: 'LENIS d.o.o.',
                status: ProductStatus.Discontinued,
                wholesalePrice: 6.07,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-05-20T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes:
                        'Reported by Lenis d.o.o. (Marketing authorization holder) Notification of permanent cessation of traffic',
                    lastUpdate: '2020-06-03T13:50:29.025Z',
                },
            },
        },
        {
            id: '5ed0f54897dbb9544fb8b727',
            scrapingOrigin: 'jazmp.si',
            country: 'Slovenia',
            countryCode: 'SI',
            isActive: false,
            start: '2020-05-17T00:00:00Z',
            end: '2020-06-19T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Reported by Pliva Ljubljana d.o.o. (Marketing authorization holder) Notification of the actual re-entry of the medicinal product on the market following a previously notified supply disruption',
            lastUpdate: '2020-06-29T15:08:20.137Z',
            product: {
                scrapingOriginId: 0,
                name: 'PLIVADON tablete, škatla z 10 tabletami (1 x 10 tablet v pretisnem omotu)',
                activeSubstances: [],
                productCode: '009652',
                maHolder: 'Pliva Ljubljana d.o.o.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8644d64a7a0716393b',
            productId: '5eb19f674ecb317d0e0e58ea',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2016-12-16T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:31.293Z',
        },
        {
            id: '5ed8ab8644d64a7a07163948',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2019-04-07T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-06-04T08:35:42.741Z',
            product: {
                scrapingOriginId: 0,
                name: 'SILDENAFIL AUROBINDO',
                activeSubstances: ['SILDENAFIL'],
                drugForm: '50 MG COMPRESSE RIVESTITE CON FILM, 8 COMPRESSE',
                productCode: '42078042',
                maHolder: 'AUROBINDO PHARMA (ITALIA) S.R.L.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8644d64a7a07163955',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-01-31T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:36:02.161Z',
            product: {
                scrapingOriginId: 0,
                name: 'TRONOTENE',
                activeSubstances: ['PROMOCAINA'],
                drugForm: '1% CREMA, TUBO DA 30G',
                productCode: '11351018',
                maHolder: 'TEOFARMA S.r.l.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8644d64a7a07163967',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-05-11T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:31.53Z',
            product: {
                scrapingOriginId: 0,
                name: 'LIBRAX',
                activeSubstances: ['CLORDIAZEPOSSIDO/CLIDINIO BROMURO'],
                drugForm: '5MG+2,5MG COMPRESSE RIVESTITE, 20 COMPRESSE',
                productCode: '19711035',
                maHolder: 'MEDA PHARMA S.p.A.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8644d64a7a0716397e',
            productId: '5eb17d194ecb317d0efd9ee6',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2019-11-01T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-06-04T08:34:31.616Z',
            product: {
                id: '5eb17d194ecb317d0efd9ee6',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 242,
                lastUpdate: '13/07/2020 14:32:09',
                name: 'LIKACIN',
                activeSubstances: ['AMIKACIN'],
                atc: 'J01GB06',
                drugForm: 'LIQUID SOLUTION',
                productCode: '24475055',
                strength: '250 MG/ML',
                package: '1 X 2 ML',
                maHolder: 'LABORATORIO ITALIANO BIOCHIMICO FARMACEUTICO LISAPHARMA S.P.A.',
                status: ProductStatus.Shortage,
                retailPriceWithVat: 5.03,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2019-11-01T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: Production problems. ',
                    lastUpdate: '2020-06-04T08:34:31.574Z',
                },
                notes: '"500 MG/2 ML SOLUZIONE INIETTABILE" 1 FIALA',
            },
        },
        {
            id: '5ed8ab8644d64a7a07163993',
            productId: '5eb17d194ecb317d0efd9e35',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-02-28T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-06-04T08:34:31.695Z',
            product: {
                id: '5eb17d194ecb317d0efd9e35',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 234,
                lastUpdate: '13/07/2020 14:32:08',
                name: 'LIKACIN',
                activeSubstances: ['AMIKACIN'],
                atc: 'J01GB06',
                drugForm: 'LIQUID SOLUTION',
                productCode: '24475067',
                strength: '250 MG/ML',
                package: '1 X 4 ML',
                maHolder: 'LABORATORIO ITALIANO BIOCHIMICO FARMACEUTICO LISAPHARMA S.P.A.',
                status: ProductStatus.Shortage,
                retailPriceWithVat: 6.88,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-02-28T00:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: Production problems. ',
                    lastUpdate: '2020-06-04T08:34:31.657Z',
                },
                notes: '"1 G/4 ML SOLUZIONE INIETTABILE" 1 FIALA',
            },
        },
        {
            id: '5ed8ab8644d64a7a071639a6',
            productId: '5eb1a0014ecb317d0e0e8aab',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2020-03-23T00:00:00Z',
            end: '2020-06-29T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-06-05T10:35:47.846Z',
            product: {
                id: '5eb1a0014ecb317d0e0e8aab',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 245027,
                lastUpdate: '13/07/2020 19:38:05',
                name: 'LINCOCIN*12 CPS 500 MG',
                activeSubstances: ['LINCOMYCIN'],
                atc: 'J01FF02',
                drugForm: 'SOLID CAPSULE',
                productCode: '20601023',
                maHolder: 'PFIZER ITALIA Srl',
                status: ProductStatus.Marketed,
                retailPriceWithVat: 5.46,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-03-23T00:00:00Z',
                    end: '2020-06-29T23:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: Production problems. ',
                    lastUpdate: '2020-06-05T10:35:47.803Z',
                },
            },
        },
        {
            id: '5ed8ab8644d64a7a071639c8',
            productId: '5eb1a0014ecb317d0e0e8a6b',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2019-12-11T00:00:00Z',
            end: '2020-02-29T00:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-06-04T08:34:31.866Z',
            product: {
                id: '5eb1a0014ecb317d0e0e8a6b',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 245026,
                lastUpdate: '13/07/2020 19:38:05',
                name: 'LINCOCIN*1 FIALA INIETT 600 MG 2 ML + SIRINGA',
                activeSubstances: ['LINCOMYCIN'],
                atc: 'J01FF02',
                drugForm: 'LIQUID SYRUP',
                productCode: '20601035',
                maHolder: 'PFIZER ITALIA Srl',
                status: ProductStatus.Marketed,
                retailPriceWithVat: 2.38,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2019-12-11T00:00:00Z',
                    end: '2020-02-29T00:00:00Z',
                    isActive: false,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: Production problems. ',
                    lastUpdate: '2020-06-04T08:34:31.83Z',
                },
            },
        },
        {
            id: '5ed8ab8644d64a7a071639dc',
            productId: '5eb276684ecb317d0e3ed270',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-04-14T23:00:00Z',
            end: '2020-09-29T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: High demand. ',
            lastUpdate: '2020-06-04T08:34:31.95Z',
            product: {
                id: '5eb276684ecb317d0e3ed270',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 697336,
                lastUpdate: '14/07/2020 20:29:30',
                name: 'LINEZOLID*10 SACCHE EV 300 ML 2 MG/ML',
                activeSubstances: ['LINEZOLID'],
                atc: 'J01XX08',
                productCode: '45707015',
                maHolder: 'ACCORD HEALTHCARE SLU',
                status: ProductStatus.Shortage,
                exFactoryPrice: 378.36,
                retailPriceWithVat: 624.44,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-04-14T23:00:00Z',
                    end: '2020-09-29T23:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: High demand. ',
                    lastUpdate: '2020-06-04T08:34:31.914Z',
                },
            } as any,
        },
        {
            id: '5ed8ab8744d64a7a071639e9',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2019-06-12T23:00:00Z',
            end: '2020-09-29T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-06-04T08:34:32.029Z',
            product: {
                scrapingOriginId: 0,
                name: 'LINEZOLID B. BRAUN',
                activeSubstances: ['LINEZOLID'],
                drugForm: '2MG/ML  SOLUZIONE PER INFUSIONE, 10 FLACONI',
                productCode: '45128028',
                maHolder: 'B. BRAUN MELSUNGEN AG',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8744d64a7a07163a0e',
            productId: '5eb1a9464ecb317d0e11811d',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-06-30T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.108Z',
            product: {
                id: '5eb1a9464ecb317d0e11811d',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 364823,
                lastUpdate: '14/07/2020 11:52:59',
                name: 'LINEZOLID SANDOZ',
                activeSubstances: ['LINEZOLID'],
                atc: 'J01XX08',
                drugForm: 'SOLID TABLET',
                productCode: '43491012',
                strength: '600 MG',
                package: '10',
                maHolder: 'SANDOZ S.P.A.',
                status: ProductStatus.Discontinued,
                retailPriceWithVat: 479.99,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-06-30T23:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes: 'Motivation: Permanent marketing ceased. ',
                    lastUpdate: '2020-06-04T08:34:32.072Z',
                },
                notes: '"600 MG COMPRESSE RIVESTITE CON FILM" 10 COMPRESSE IN BLISTER OPA/AL',
            },
        },
        {
            id: '5ed8ab8744d64a7a07163a1f',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2019-05-30T23:00:00Z',
            end: '2020-06-04T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes:
                'Motivation: Production problems: contingent distribution. la distribuzione delle confezioni disponibili viene gestita in modalit\ufffd contingentata',
            lastUpdate: '2020-06-04T08:35:41.276Z',
            product: {
                scrapingOriginId: 0,
                name: 'SENSHIO',
                activeSubstances: ['OSPEMIFENE'],
                drugForm: '60 MG COMPRESSE RIVESTITE CON FILM, 28 COMPRESSE',
                productCode: '43868025',
                maHolder: 'SHIONOGI B.V.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8744d64a7a07163a2a',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2020-08-30T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-05T10:35:51.649Z',
            product: {
                scrapingOriginId: 0,
                name: 'PUPILLA LIGHT',
                activeSubstances: ['BENZALCONIO CLORURO'],
                drugForm: '0,1MG/ML COLLIRIO, SOLUZIONE FLACONE DA 10ML',
                productCode: '32190011',
                maHolder: "LABORATORIO FARMACEUTICO SIT  SPECIALITA' IGIENICO TERAPEUTICHE SRL",
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8744d64a7a07163a3f',
            productId: '5eb1a0014ecb317d0e0e8aad',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2017-07-14T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.349Z',
        },
        {
            id: '5ed8ab8744d64a7a07163a4b',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2018-02-08T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:35:52.34Z',
            product: {
                scrapingOriginId: 0,
                name: 'TAZOCIN',
                activeSubstances: ['PIPERACILLINA SODICA/TAZOBACTAM SODICO'],
                drugForm: '2 G / 250 MG POLVERE PER SOLUZIONE PER INFUSIONE',
                productCode: '28249050',
                maHolder: 'PFIZER ITALIA S.r.l.',
                status: ProductStatus.Authorised,
            } as any,
        },
        {
            id: '5ed8ab8744d64a7a07163a61',
            productId: '5eb17f8b4ecb317d0efe9b38',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-04-27T23:00:00Z',
            end: '2020-09-29T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems. ',
            lastUpdate: '2020-07-02T15:57:42.433Z',
            product: {
                id: '5eb17f8b4ecb317d0efe9b38',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 5243,
                lastUpdate: '13/07/2020 14:40:33',
                name: 'LISINOPRIL MYLAN GENERICS',
                activeSubstances: ['LISINOPRIL'],
                atc: 'C09AA03',
                drugForm: 'SOLID TABLET',
                productCode: '37710074',
                strength: '20 MG',
                package: '14',
                maHolder: 'MYLAN S.P.A.',
                status: ProductStatus.Shortage,
                retailPriceWithVat: 3.3,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-04-27T23:00:00Z',
                    end: '2020-09-29T23:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: Production problems. ',
                    lastUpdate: '2020-07-02T15:57:42.389Z',
                },
                notes: '"20 MG COMPRESSE" 14 COMPRESSE IN BLISTER PVC/AL',
            },
        },
        {
            id: '5ed8ab8744d64a7a07163a71',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2015-03-19T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.587Z',
            product: {
                scrapingOriginId: 0,
                name: 'LISOMUCIL TOSSE MUCOLITICO',
                activeSubstances: ['CARBOCISTEINA'],
                drugForm: '1,5 G GRANULATO PER SOSPENSIONE ORALE, 20 BUSTINE',
                productCode: '23185073',
                maHolder: 'SANOFI S.p.A.',
                status: ProductStatus.Authorised,
            },
        },
        {
            id: '5ed8ab8744d64a7a07163a7d',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2021-02-28T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.666Z',
            product: {
                scrapingOriginId: 0,
                name: 'LISOMUCIL TOSSE SEDATIVO',
                activeSubstances: ['CARBOCISTEINA'],
                drugForm: '10 MG PASTIGLIE, 24 PASTIGLIE',
                productCode: '19396050',
                maHolder: 'SANOFI S.p.A.',
                status: ProductStatus.Authorised,
            },
        },
        {
            id: '5ed8ab8744d64a7a07163a88',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2014-12-01T00:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.75Z',
            product: {
                scrapingOriginId: 0,
                name: 'LISOZIMA SPA',
                activeSubstances: ['LISOZIMA CLORIDRATO'],
                drugForm: '250MG COMPRESSE, 30 COMPRESSE',
                productCode: '4446050',
                maHolder: 'SPA S.p.A.',
                status: ProductStatus.Authorised,
            },
        },
        {
            id: '5ed8ab8744d64a7a07163a94',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2020-05-10T23:00:00Z',
            end: '2020-06-29T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: High demand / production problems. ',
            lastUpdate: '2020-06-04T08:34:32.832Z',
            product: {
                scrapingOriginId: 0,
                name: 'LISOZIMA SPA',
                activeSubstances: ['LISOZIMA CLORIDRATO'],
                drugForm: '500MG COMPRESSE, 30 COMPRESSE',
                productCode: '4446062',
                maHolder: 'SPA S.p.A.',
                status: ProductStatus.Authorised,
            },
        },
        {
            id: '5ed8ab8744d64a7a07163aa7',
            productId: '5eb17cf94ecb317d0efd94d3',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: false,
            start: '2020-08-31T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.915Z',
            product: {
                id: '5eb17cf94ecb317d0efd94d3',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 158,
                lastUpdate: '13/07/2020 14:31:23',
                name: 'LITURSOL',
                activeSubstances: ['URSODEOXYCHOLIC ACID'],
                atc: 'A05AA02',
                drugForm: 'SOLID TABLET',
                productCode: '24615080',
                maHolder: 'EG S.P.A.',
                status: ProductStatus.Marketed,
                retailPriceWithVat: 6.35,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-08-31T23:00:00Z',
                    isActive: false,
                    type: ShortageType.Permanent,
                    additionalNotes: 'Motivation: Permanent marketing ceased. ',
                    lastUpdate: '2020-06-04T08:34:32.878Z',
                },
                notes: '"300 MG COMPRESSE"20 COMPRESSE"',
            },
        },
        {
            id: '5ed8ab8844d64a7a07163abb',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2015-04-29T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:32.992Z',
            product: {
                scrapingOriginId: 0,
                name: 'LITURSOL',
                activeSubstances: ['ACIDO URSODESOSSICOLICO'],
                drugForm: '450MG CAPSULE RIGIDE A RILASCIO PROLUNGATO, 20 CAPSULE',
                productCode: '24615092',
                maHolder: 'EG S.p.A.',
                status: ProductStatus.Authorised,
            },
        },
        {
            id: '5ed8ab8844d64a7a07163acf',
            productId: '5eb17fff4ecb317d0eff0331',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2020-01-31T00:00:00Z',
            end: '2020-09-29T23:00:00Z',
            type: ShortageType.Temporary,
            additionalNotes: 'Motivation: Production problems: discontinuous supplies. ',
            lastUpdate: '2020-06-04T08:34:33.071Z',
            product: {
                id: '5eb17fff4ecb317d0eff0331',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 11040,
                lastUpdate: '13/07/2020 14:58:34',
                name: 'LIVIAL',
                activeSubstances: ['TIBOLONE'],
                atc: 'G03CX01',
                drugForm: 'SOLID TABLET',
                productCode: '28035018',
                maHolder: 'MSD ITALIA S.R.L.',
                status: ProductStatus.Shortage,
                retailPriceWithVat: 14.28,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2020-01-31T00:00:00Z',
                    end: '2020-09-29T23:00:00Z',
                    isActive: true,
                    type: ShortageType.Temporary,
                    additionalNotes: 'Motivation: Production problems: discontinuous supplies. ',
                    lastUpdate: '2020-06-04T08:34:33.034Z',
                },
                notes: '"2,5 MG COMPRESSE" 30 COMPRESSE',
            },
        },
        {
            id: '5ed8ab8844d64a7a07163ae2',
            productId: '5eb1efca4ecb317d0e244c3a',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2019-05-30T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:33.154Z',
            product: {
                id: '5eb1efca4ecb317d0e244c3a',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 521298,
                lastUpdate: '14/07/2020 15:43:18',
                name: 'LIXIANA*10X1 CPR RIV 30 MG',
                activeSubstances: ['EDOXABAN'],
                atc: 'B01AF03',
                drugForm: 'SOLID TABLET',
                productCode: '44315137',
                strength: '30 MG',
                package: '10',
                maHolder: 'DAIICHI SANKYO EUROPE GmbH',
                status: ProductStatus.Discontinued,
                retailPriceWithVat: 34.56,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2019-05-30T23:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes: 'Motivation: Permanent marketing ceased. ',
                    lastUpdate: '2020-06-04T08:34:33.112Z',
                },
            },
        },
        {
            id: '5ed8ab8844d64a7a07163b00',
            productId: '5eb1efca4ecb317d0e244c3c',
            scrapingOrigin: 'aifa.gov.it',
            country: 'Italy',
            countryCode: 'IT',
            isActive: true,
            start: '2018-09-29T23:00:00Z',
            type: ShortageType.Permanent,
            additionalNotes: 'Motivation: Permanent marketing ceased. ',
            lastUpdate: '2020-06-04T08:34:33.238Z',
            product: {
                id: '5eb1efca4ecb317d0e244c3c',
                country: 'Italy',
                countryCode: 'IT',
                scrapingOrigin: 'exprice',
                scrapingOriginId: 521302,
                lastUpdate: '14/07/2020 15:43:17',
                name: 'LIXIANA*50X1 CPR RIV 30 MG DOSE UNITARIA',
                activeSubstances: ['EDOXABAN'],
                atc: 'B01AF03',
                drugForm: 'SOLID TABLET',
                productCode: '44315149',
                strength: '30 MG',
                package: '50',
                maHolder: 'DAIICHI SANKYO EUROPE GmbH',
                status: ProductStatus.Discontinued,
                retailPriceWithVat: 172.79,
                currencyCode: 'EUR',
                shortageInfo: {
                    start: '2018-09-29T23:00:00Z',
                    isActive: true,
                    type: ShortageType.Permanent,
                    additionalNotes: 'Motivation: Permanent marketing ceased. ',
                    lastUpdate: '2020-06-04T08:34:33.201Z',
                },
            },
        },
    ],
};
