import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { IconButton, Link, makeStyles } from '@material-ui/core';
import SingleSelectInput from '../../../components/inputs/SingleSelectInput';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';

interface IAdditionalCostProps {
    item: any;
    getAdditionalCost: (item: {
        id: string;
        supplierId: string;
        additionalCost: number;
        costList: ICostItem[];
    }) => void;
}

const useStyles = makeStyles(() => ({
    dropdown: {
      "&.MuiAutocomplete-root .MuiAutocomplete-inputRoot": {
        padding: "2px !important"
      },
    }
  }));

export interface ICostItem {
    label: string;
    value: number;
    clientFreightForwarder?: string;
    supplierFreightForwarder?: string;
}

export const AdditionalCost: React.FC<IAdditionalCostProps> = function ({ item, getAdditionalCost }) {
    const [costList, setCostList] = React.useState<ICostItem[]>(item.additionalCostList || [{ label: '', value: 0 }]);
    const [totalCost, setTotalCost] = React.useState<number>(0);
    const { id } = item;

    const t = useTranslations();

    const classes = useStyles();
      
    React.useEffect(() => {
        getAdditionalCost({
            id: item.id,
            supplierId: item.supplierId,
            additionalCost: totalCost,
            costList: [...costList],
        });
    }, [totalCost]);

    React.useEffect(() => {
        let sum = costList.reduce((prevSum, item) => prevSum + item.value, 0);
        setTotalCost(sum);
    }, [costList, setTotalCost]);

    const onHandleAddMoreCost = useCallback(() => {
        costList.push({ label: '', value: 0 });
        setCostList([...costList]);
    }, [setCostList, costList]);

    const onHandleLabelChange = useCallback(
        (value, key, id) => {
            costList[key].label = value;

            setCostList([...costList]);
        },
        [setCostList, costList],
    );

    const onHandleValueChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key, id) => {
            costList[key].value = parseFloat(e.target.value) as number;
            setCostList([...costList]);
        },
        [setCostList, costList, setTotalCost],
    );

    const onHandleKeydown = useCallback(
        (e) => {
            onHandleAddMoreCost();
        },
        [onHandleAddMoreCost],
    );

    const onHandleRemove = useCallback(
        (key) => {
            const updatedCostList = costList.filter((item, index) => index !== key);
            setCostList([...updatedCostList]);
        },
        [setCostList, costList],
    );

    const onClientForwarderChange = useCallback (
        (key: number, value: string) => {
            costList[key].clientFreightForwarder = value;
            setCostList([...costList]);

    }, [setCostList, costList]);

    const onSupplierForwarderChange = useCallback (
        (key: number, value: string) => {
            costList[key].supplierFreightForwarder = value;
            setCostList([...costList]);

    }, [setCostList, costList]);

    const elementId = 'simple-popover' + item.id;

    return (
        <div id={elementId}>
            <Box display="flex" flexDirection="column" justifyContent="center">
                {/* <Box display="flex" justifyContent="right">
                    <Link style={{ outline: 'none' }} component="button" variant="body2" onClick={onHandleAddMoreCost}>
                        Add more
                    </Link>
                </Box> */}
                {costList.map((costItem, key) => {
                    return (
                        <Box display="flex" justifyContent="center" flexDirection="column" width={320} key={`${key}-${elementId}`}>
                            <Box display="flex" justifyContent="center">
                                <Box style={{ width: '100%' }} padding={2}>
                                    <SingleSelectInput
                                        dropdownClassName={classes.dropdown}
                                        freeSolo={true}
                                        value={costItem.label}
                                        options={["Customs", "Import Licenses", "Export Licenses", "Cold Box", "Loggers"]}
                                        onChange={(value) => onHandleLabelChange(value, key, id)} />
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="right">
                                    <Box paddingY={2}>
                                        <TextField
                                            id={`${key}-number-${elementId}`}
                                            type="number"
                                            style={{ width: '60px' }}
                                            value={costItem.value}
                                            onChange={(e) => onHandleValueChange(e, key, id)}
                                        />
                                    </Box>
                                    <Box paddingTop={3}>€</Box>
                                    {costList.length > 1 && key < costList.length - 1 && (
                                        <Box paddingTop="12px" width={60}>
                                            <IconButton
                                                color="primary"
                                                aria-label="Remove"
                                                component="span"
                                                disabled={costItem.label === 'Client Freight' || costItem.label === 'Supplier Freight'}
                                                onClick={() => onHandleRemove(key)}
                                            >
                                            </IconButton>
                                        </Box>
                                    )}
                                    {key === costList.length - 1 && (
                                        <Box paddingTop={3} width={60}>
                                            <Link
                                                style={{fontSize: '13px', marginLeft: '12px', cursor: 'pointer'}}
                                                onClick={(
                                                    e:
                                                        | React.MouseEvent<HTMLAnchorElement, MouseEvent>
                                                        | React.MouseEvent<HTMLSpanElement, MouseEvent>,
                                                ) => onHandleKeydown(e)}
                                            >
                                                Add line
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {(costItem.label === 'Supplier Freight' || costItem.label === 'Client Freight') && (
                            <Box display="flex" justifyContent="center">
                                <Box display="flex" flexDirection="row" justifyContent="space-between" marginLeft="20px" width={320}>
                                    <Box>Forwarder: </Box>
                                    <Box>
                                        {costItem.label === 'Supplier Freight' && (
                                            <SingleSelectInput 
                                                value={costItem.supplierFreightForwarder}
                                                options={["DHL", "TNT", "Others"]}
                                                onChange={(value) => onSupplierForwarderChange(key, value)}
                                                getOptionLabel={v => t(v as TK)}
                                            />
                                        )}
                                        {costItem.label === 'Client Freight' && (
                                            <SingleSelectInput 
                                                value={costItem.clientFreightForwarder}
                                                options={["DHL", "TNT", "Others"]}
                                                onChange={(value) => onClientForwarderChange(key, value)}
                                                getOptionLabel={v => t(v as TK)}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </div>
    );
};
