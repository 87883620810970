import { routes } from './routes';
import { SearchResult } from '../models/SearchResult';
import { ProcessingPformsSettings } from '../models/ProcessingPformsSettings';
import { AuthenticatedUser } from '../models/AuthenticatedUser';
import { CreateUser } from '../models/CreateUser';
import { UpdateUser } from '../models/UpdateUser';
import { User } from '../models/User';
import { Supplier } from '../models/Supplier';
import { RFQSummary } from '../models/RFQSummary';
import { Shortage } from '../models/Shortage';
import { IRFQDueRminderRequest, IRFQStateReasonRequest, RFQRequest } from '../models/RFQRequest';
import { IPformTermData, ProcessingSettings } from '../models/ProcessingSettings';
import { LogsScrapper } from '../models/LogsScrapper';
import { RFQDetails } from '../models/RFQDetails';
import { MapOf } from '../utils/Types';
import { CreateRFQResult } from '../models/CreateRFQResult';
import { ResultHandler } from '../models/ResultHandler';
import { RFQSupplierDetails } from '../models/RFQSupplierDetails';
import { ProductV1 } from '../models/ProductV1';
import { ProductV2 } from '../models/ProductV2';
import { Login } from '../models/Login';
import { RFQQuote } from '../models/RFQQuote';
import { SupplierRFQQuotesChange } from '../models/SupplierRFQQuotesChange';
import { TermsTranslations } from '../models/TermsMapping';
import { RequestAccessLink } from '../models/RequestAccessLink';
import { RFQQuoteTableItem } from '../pages/RFQDetailsV2';
import { LogExScrapper } from '../models/LogExScrapper';
import { EditComment } from '../models/EditComment';
import { AddComment } from '../models/AddComment';
import { DeleteComment } from '../models/DeleteComment';
import { Signal } from '../models/Signal';
import { useSelector } from 'react-redux';
import { ReportComment } from '../models/ReportComment';
import { Sources } from '../models/Sources';
import { ProductsCompareList } from '../models/ProductCompare';
import { Country } from '../models/Country';

export class RequestError extends Error {
    statusCode: number;
    body: any;

    constructor(statusCode: number, body: any) {
        super(`Request error: ${statusCode}`);
        this.statusCode = statusCode;
        this.body = body;
    }
}

const getHeaders = (token?: string, csrfToken?: string): Headers => {
    const headers = new Headers();

    headers.append('content-type', 'application/json');
    headers.append('headerName', 'RequestVerificationToken');
    if (token) headers.append('Authorization', `Bearer ${token}`);
    if (csrfToken) headers.append('X-CSRF-Token', csrfToken);
    return headers;
};

const responseHandleMiddleware = async (r: Response): Promise<any> => {
    if (!r.ok) {
        let bodyObj = {};
        try {
            bodyObj = await r.json();
        } catch (e) { }
        throw new RequestError(r.status, bodyObj);
    }
    var isJson = r.headers.get('content-type')?.includes('application/json');
    return isJson ? r.json() : null;
};

function getParams(params?: { [name: string]: any }) {
    if (!params) return '';

    const paramsString = Object.keys(params)
        .filter((k) => !!params[k])
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k].toString()))
        .join('&');

    return paramsString.length === 0 ? '' : '?'.concat(paramsString);
}

const post = (uri: string, body: any, token?: string, csrfToken?: string): Promise<any> =>
    fetch(uri, {
        method: 'post',
        headers: getHeaders(token, csrfToken),
        body: JSON.stringify(body),
    }).then(responseHandleMiddleware);

const put = (uri: string, body: any, token?: string, csrfToken?: string): Promise<any> =>
    fetch(uri, {
        method: 'put',
        headers: getHeaders(token, csrfToken),
        body: JSON.stringify(body),
    }).then(responseHandleMiddleware);

const get = (uri: string, token?: string, csrfToken?: string, params?: { [name: string]: any }): Promise<any> =>
    fetch(uri + getParams(params), {
        method: 'get',
        headers: getHeaders(token, csrfToken),
    }).then(responseHandleMiddleware);

export const getProductsAsync = (
    offset: number,
    pageSize: number,
    filters: { [property: string]: string | string[] | null | undefined },
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<ProductV1>> => get(routes.products(), token, csrfToken, { offset, pageSize, ...filters });

export const getProductsV2Async = (
    offset: number,
    pageSize: number,
    filters: { [property: string]: string | string[] | null | undefined },
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<ProductV2>> => get(routes.productsV2(), token, csrfToken, { offset, pageSize, ...filters });

export const getShortagesAsync = (
    offset: number,
    pageSize: number,
    filters: { [property: string]: string | string[] | undefined },
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<Shortage>> => get(routes.shortages(), token, csrfToken, { offset, pageSize, ...filters });

export const getSuppliersAsync = (
    offset: number,
    pageSize: number,
    filters: { [property: string]: string | string[] | undefined },
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<Supplier>> => get(routes.suppliers(), token, csrfToken, { offset, pageSize, ...filters });

export const getSetAsync = (setName: string, token?: string, csrfToken?: string): Promise<any[]> =>
    get(routes.productsSet(setName), token, csrfToken);

export const getSetV2Async = (setName: string, token?: string, csrfToken?: string): Promise<any[]> =>
    get(routes.productsV2Set(setName), token, csrfToken);

export const getUsersAsync = (token?: string, csrfToken?: string): Promise<User[]> =>
    get(routes.users(), token, csrfToken);

export const getUsersCollaboratorsAsync = (token?: string, csrfToken?: string): Promise<User[]> =>
    get(routes.usersCollaborators(), token, csrfToken);

export const getMyUserAsync = (token?: string, csrfToken?: string): Promise<User> =>
    get(routes.myUser(), token, csrfToken);

export const postUserAsync = (user: CreateUser, token?: string, csrfToken?: string): Promise<void> =>
    post(routes.users(), user, token, csrfToken);

export const putUserAsync = (username: string, user: UpdateUser, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.user(username), user, token, csrfToken);

export const putMyUserGoogleLinkAsync = (
    googleProvider: string,
    googleId: string,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.myUserThirdPartyLink(), { provider: googleProvider, id: googleId }, token, csrfToken);

export const putMyUserAsync = (user: UpdateUser, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.myUser(), user, token, csrfToken);

export const postLoginAsync = (login: Login): Promise<AuthenticatedUser> => post(routes.login(), login);

export const postRequestAccessLinkAsync = (request: RequestAccessLink): Promise<void> =>
    post(routes.accessLinkRequest(), request);

export const getRFQsAsync = (
    onlyMine: boolean,
    search: string | undefined,
    createdBy: string | undefined,
    expiredIn: string | undefined,
    offset: number,
    pageSize: number,
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<RFQSummary>> =>
    get(routes.rfqs(), token, csrfToken, { offset, search, createdBy, expiredIn, pageSize, onlyMine });

export const getRFQsAsyncV2 = (
    onlyMine: boolean,
    search: string | undefined,
    expiredIn: string | undefined,
    createdBy: string | undefined,
    offset: number,
    pageSize: number,
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<RFQSummary>> =>
    get(routes.rfqs(), token, csrfToken, { offset, search, expiredIn, createdBy, pageSize, onlyMine });

export const getRFQsDetailsAsync = (rfqNumbers: string[], token?: string, csrfToken?: string): Promise<RFQDetails[]> =>
    get(routes.rfqsDetails(), token, csrfToken, { rfqNumbers });

export const getRFQDetailsAsync = (rfqNumber: string, token?: string, csrfToken?: string): Promise<RFQDetails> =>
    get(routes.rfqDetails(rfqNumber), token, csrfToken);

export const getRFQHistoryAsync = (rfqNumber: string, token?: string, csrfToken?: string): Promise<RFQDetails[]> =>
    get(routes.rfqHistory(rfqNumber), token, csrfToken);

export const getLogsReportAsync = (origin: string, token?: string, csrfToken?: string): Promise<ReportComment> =>
    get(routes.getReport(), token, csrfToken, { excellFile: origin });

export const getSourcesLogsAsync = (token?: string, csrfToken?: string): Promise<{ [key: string]: Sources }> =>
    get(routes.sourcesLogs(), token, csrfToken);

export const putUserSettingsAsync = (
    settings: { [key: string]: any },
    token?: string,
    csrfToken?: string,
): Promise<AuthenticatedUser> => put(routes.mySettings(), settings, token, csrfToken);

export const postRFQRequestAsync = (
    request: RFQRequest,
    token?: string,
    csrfToken?: string,
): Promise<ResultHandler<CreateRFQResult>> => post(routes.rfqs(), request, token, csrfToken);

export const putRFQDueReminderRequestAsync = (
    rfqNumber: string,
    request: IRFQDueRminderRequest,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.rfqsDueDate(rfqNumber), request, token, csrfToken);

export const putRFQStateReasonAsync = (
    rfqNumber: string,
    request: IRFQStateReasonRequest,
    token?: string,
    csrfToken?: string,
): Promise<RFQSummary> => put(routes.rfqsReasonState(rfqNumber), request, token, csrfToken);

export const putRFQAssigneeAsync = (
    rfqNumber: string,
    username?: string,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.rfqAssignee(rfqNumber), username, token, csrfToken);

export const putRFQDataAsync = (
    rfqNumber: string,
    dataByThreadId: MapOf<RFQQuote[]>,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.rfqTableData(rfqNumber), dataByThreadId, token, csrfToken);

export const putRFQCardsDataAsync = (
    rfqNumber: string,
    cardsData: RFQQuoteTableItem[],
    token?: string,
    csrfToken?: string,
): Promise<RFQSummary> => put(routes.rfqCardsData(rfqNumber), cardsData, token, csrfToken);

export const getRFQNextNumberAsync = (token?: string, csrfToken?: string): Promise<{ nextRfqNumber: string }> =>
    get(routes.rfqNextNumber(), token, csrfToken);

export const getPlatformProcessingSettingsAsync = (
    origin: string,
    token?: string,
    csrfToken?: string,
): Promise<ProcessingSettings> => get(routes.processingSettings(origin), token, csrfToken);

export const getPlatformPformProcessingSettingsAsync = (
    origin: string,
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<ProcessingPformsSettings>> => get(routes.processingPformSettings(origin), token, csrfToken);

export const getScrapperLogsAsync = (
    sortBy?: string,
    sortType?: string,
    token?: string,
    csrfToken?: string,
): Promise<LogsScrapper> => get(routes.scrapperLogs(), token, csrfToken);

export const getScrapperLogsHistAsync = (
    sortBy?: string,
    sortType?: string,
    token?: string,
    csrfToken?: string,
): Promise<LogExScrapper[]> => get(routes.scrapperLogsHist(), token, csrfToken);

export const putEditCommentAsync = (comment: EditComment, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.editComment(), comment, token, csrfToken);

export const putStartNowAsync = (log: LogExScrapper, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.startNow(), log, token, csrfToken);

export const putAcceptToDev = (log: LogExScrapper, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.acceptDev(), log, token, csrfToken);

export const putAcceptToProd = (log: LogExScrapper, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.acceptProd(), log, token, csrfToken);

export const putRejectToDev = (log: LogExScrapper, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.rejectDev(), log, token, csrfToken);

export const putRejectToProd = (log: LogExScrapper, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.rejectProd(), log, token, csrfToken);

export const putRescheduleAsync = (log: Signal, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.reschedule(), log, token, csrfToken);

export const putAddCommentAsync = (comment: AddComment, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.addComment(), comment, token, csrfToken);

export const putDeleteCommentAsync = (comment: DeleteComment, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.deleteComment(), comment, token, csrfToken);
export const postPlatformProcessingSettingsAsync = (
    origin: string,
    settings: ProcessingSettings,
    token?: string,
    csrfToken?: string,
): Promise<void> => post(routes.processingSettings(origin), settings, token, csrfToken);

export const putPFormPlatformProcessSettingsAsync = (
    origin: string,
    pformData: any,
    token?: string,
    csrfToken?: string,
): Promise<IPformTermData> => put(routes.processingPformNewItemSettings(origin), pformData, token, csrfToken);

export const postPlatformSenReProcessRequestAsync = (
    origin: string,
    valuesAffected: string[],
    token?: string,
    csrfToken?: string,
): Promise<void> => post(routes.processing(origin), valuesAffected, token, csrfToken);
export const postPlatformRestoreBackupRequestAsync = (
    origin: string,
    valuesAffected: string[],
    token?: string,
    csrfToken?: string,
): Promise<void> => post(routes.processingBackup(origin), valuesAffected, token, csrfToken);

export const getPlatformTermsTranslationsAsync = (
    language: string,
    token?: string,
    csrfToken?: string,
): Promise<TermsTranslations> => get(routes.termsTranslation(language), token, csrfToken);

export const getExternalRFQsSupplierDataAsync = (
    rfqsNumbers?: string[],
    token?: string,
    csrfToken?: string,
): Promise<RFQSupplierDetails> => get(routes.externalSupplierRfqsData(), token, csrfToken, { rfqsNumbers });

export const putExternalSupplierRFQsDataAsync = (
    data: SupplierRFQQuotesChange,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.externalSupplierRfqsData(), data, token, csrfToken);

export const getExternalSupplierProductsSearchAsync = (
    term: string,
    token?: string,
    csrfToken?: string,
): Promise<ProductV2[]> => get(routes.externalSupplierProductsSearch(), token, csrfToken, { name: term });

export const putProccesCountry = (
    country: string,
    body? :any,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.productsV2ProccessCountry(country), body, token, csrfToken);


export const getProductsCompareTable = (
    country: string,
    token?: string,
    csrfToken?: string,
): Promise<ProductsCompareList> => get(routes.productsV2ComparesTable(country), token, csrfToken);
export const getListCountries = (
    token?: string,
    csrfToken?: string,
): Promise<Country[]> => get(routes.productsV2ListCountries(), token, csrfToken);

export const getExternalSupplierCollaboratorsAsync = (token?: string, csrfToken?: string): Promise<User[]> =>
    get(routes.externalSupplierCollaborators(), token, csrfToken);

export const getExternalSupplierRFQsResposiblesAsync = (
    rfqsNumbers: string[],
    token?: string,
    csrfToken?: string,
): Promise<MapOf<string>> => get(routes.externalSupplierRfqsResponsibles(), token, csrfToken, { rfqsNumbers });
