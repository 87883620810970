import appSettings from '../appSettings';
import { countries } from './mocks/countries';

export const routes = {
    login: () => `${appSettings.backend_url}v1/users/authenticate`,
    users: () => `${appSettings.backend_url}v1/users`,
    accessLinkRequest: () => `${appSettings.backend_url}v1/users/accessLinkRequest`,
    usersCollaborators: () => `${appSettings.backend_url}v1/users/collaborators`,
    myUser: () => `${appSettings.backend_url}v1/users/me`,
    myUserThirdPartyLink: () => `${appSettings.backend_url}v1/users/me/thirdpartylink`,
    scrapperLogs: () => `${appSettings.backend_url}v1/logs`,
    sourcesLogs: () => `${appSettings.backend_url}v1/logs/sources`,
    scrapperLogsHist: () => `${appSettings.backend_url}v1/logs/history`,
    mySettings: () => `${appSettings.backend_url}v1/users/me/settings`,
    user: (username: string) => `${appSettings.backend_url}v1/users/${username}`,
    products: () => `${appSettings.backend_url}v1/products`,
    productsV2: () => `${appSettings.backend_url}v2/products`,
    shortages: () => `${appSettings.backend_url}v1/shortages`,
    productsSet: (setName: string) => `${appSettings.backend_url}v2/products/sets/${setName}`,
    productsV2Set: (setName: string) => `${appSettings.backend_url}v2/products/sets/${setName}`,
    productsV2ComparesTable: (country: string) => `${appSettings.backend_url}v2/products/getCompare/${country}`,
    productsV2ListCountries: () => `${appSettings.backend_url}v2/products/getListCountries`,
    productsV2ProccessCountry: (country: string) => `${appSettings.backend_url}v2/products/proccess/${country}`,
    //productsSet: (setName: string) => `${appSettings.backend_url}products/${setName}`,
    //productsV2Set: (setName: string) => `${appSettings.backend_url}products/${setName}`,
    suppliers: () => `${appSettings.backend_url}v1/suppliers`,
    rfqs: () => `${appSettings.backend_url}v1/rfqs`,
    rfqsDetails: () => `${appSettings.backend_url}v1/rfqs/details`,
    rfqDetails: (rfqNumber: string) => `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}`,
    rfqHistory: (rfqNumber: string) => `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}/history`,
    rfqAssignee: (rfqNumber: string) => `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}/assignee`,
    rfqsDueDate: (rfqNumber: string) =>
        `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}/dueDate/reminder`,
    rfqsReasonState: (rfqNumber: string) =>
        `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}/state/reason`,
    rfqTableData: (rfqNumber: string) => `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}/data`,
    rfqCardsData: (rfqNumber: string) => `${appSettings.backend_url}v1/rfqs/${encodeURIComponent(rfqNumber)}/cards`,
    rfqNextNumber: () => `${appSettings.backend_url}v1/rfqs/nextNumber`,
    externalSupplierRfqsData: () => `${appSettings.backend_url}v1/external/rfqs/data`,
    externalSupplierCollaborators: () => `${appSettings.backend_url}v1/external/collaborators`,
    externalSupplierRfqsResponsibles: () => `${appSettings.backend_url}v1/external/rfqs/responsibles`,
    externalSupplierProductsSearch: () => `${appSettings.backend_url}v1/external/products/search`,
    //supplierProductsSearch: () => `v1/external/productsV1/search`, // use this for products V2
    editComment: () => `${appSettings.backend_url}v1/logs/editComent`,
    startNow: () => `${appSettings.backend_url}v1/logs/startNow`,
    acceptDev: () => `${appSettings.backend_url}v1/logs/acceptDev`,
    acceptProd: () => `${appSettings.backend_url}v1/logs/acceptProd`,
    rejectDev: () => `${appSettings.backend_url}v1/logs/rejectDev`,
    rejectProd: () => `${appSettings.backend_url}v1/logs/rejectProd`,
    reschedule: () => `${appSettings.backend_url}v1/logs/reschedule`,
    deleteComment: () => `${appSettings.backend_url}v1/logs/deleteComment`,
    addComment: () => `${appSettings.backend_url}v1/logs/addComent`,
    getReport: () => `${appSettings.backend_url}v1/service/GetComparison`,
    getReportExcelFile: (id: string) => `${appSettings.backend_url}v1/service/GetExcellFile?excellFile=${id}`,
    processingSettings: (origin: string) => `${appSettings.backend_url}v1/platform/processingSettings/${origin}`,
    processingPformNewItemSettings: (country: string) =>
        `${appSettings.backend_url}v1/platform/processingSettings/pformsRoutes/${country}/addPform`,
    processingPformSettings: (country: string) =>
        `${appSettings.backend_url}v1/platform/processingSettings/pformsRoutes/${country}`,
    processing: (origin: string) => `${appSettings.backend_url}v1/platform/processing/${origin}`,
    processingBackup: (origin: string) => `${appSettings.backend_url}v1/platform/processingBackup/${origin}`,
    termsTranslation: (language: string) => `${appSettings.backend_url}v1/platform/terms/${language}`,
};
