import React, { useCallback, useState } from 'react';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Button,
    Box,
    Chip,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Tooltip,
    makeStyles,
    Popover,
    Theme,
    Typography,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableBody,
} from '@material-ui/core';
//import {  } from '../../models/ProductV2';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AvailabilityColors, AvailabilityTerms, AvailabilityColorsFront } from '../../constants/general';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkIcon from '@material-ui/icons/Link';

import ReactCountryFlag from 'react-country-flag';
import { CloseIconWrapper, DialogTitleContainer } from '../../pages/ProductsListV3/styled';
import { LogExScrapper } from '../../models/LogExScrapper';
import { LogsScrapperResult } from '../../models/LogsScrapperResult';
import { LogMessage, LogMessageContent, Message } from '../../models/LogMessage';
import { useDispatch } from 'react-redux';
import { AddCommen, DeleteCommen, Reschedulue, StartNowSignal, UpdateComment } from '../../store/Logs2/actions';
import { goToProducts, goToRFQsListV2 } from '../../store/Router/actions';
import { Input } from 'reactstrap';
//import ProductContent from '../ProductContent';

interface ISignalDetailsDialog {
    log?: LogsScrapperResult;
    handleDialogClose: () => void;

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        lastUpdate: {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '30px',
            fontSize: '0.8rem',
        },
    }),
);

export const SignalDetailsDialog: React.FC<ISignalDetailsDialog> = ({
    log,
    handleDialogClose,
}) => {
    const t = useTranslations();
    const classes = useStyles();
    const dispatch = useDispatch();
    //const [values, setValues] = React.useState<string[]>([]);
    const minMin = 0;
    const minHour = 0;
    const minWeekday = 0;
    const minDay = 1;
    const minMonth = 1;
    const maxMin = 59;
    const maxHour = 23;
    const maxDay = 31;
    const maxMonth = 12;
    const maxWeekday = 6;
    const [min, setValue] = useState<number>();
    const [hour, setHour] = useState<number>();
    const [day, setDay] = useState<number>();
    const [month, setMonth] = useState<number>();
    const [weekday, setWeekday] = useState<number>();

    const handleChangeMin = (event: any) => {
        const value = Math.max(minMin, Math.min(maxMin, Number(event.target.value)));
        setValue(value);
    };
    const handleChangeHour = (event: any) => {
        const value = Math.max(minHour, Math.min(maxHour, Number(event.target.value)));
        setHour(value);
    };

    const handleChangeDay = (event: any) => {
        const value = Math.max(minDay, Math.min(maxDay, Number(event.target.value)));
        setDay(value);
    };
    const handleChangeMonth = (event: any) => {
        const value = Math.max(minMonth, Math.min(maxMonth, Number(event.target.value)));
        setMonth(value);
    };
    const handleChangeWeekday = (event: any) => {
        const value = Math.max(minWeekday, Math.min(maxWeekday, Number(event.target.value)));
        setWeekday(value);
    };
    const handleStartNow = () => {
        var l = log?.last as LogExScrapper;
        dispatch(StartNowSignal(l));
        handleDialogClose();
    };
    const handleReschedule = () => {
        var cronn = (min ?? "*") + " " + (hour ?? "*") + " " + (day ?? "*") + " " + (month ?? "*") + " " + (weekday ?? "*");
        
        dispatch(Reschedulue({ cronn: cronn, executionName: (log?.last.executionName ??"asd"),immedeateStart:false }));
        handleDialogClose();
    };

    return (
        <Dialog open={!!log} maxWidth="lg" onClose={handleDialogClose}>
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ fontWeight: 700 }}>{log?.messages?.name}</div>
                </DialogTitleContainer>
                <CloseIconWrapper>
                    <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                        <HighlightOffIcon color="primary" fontSize="large" />
                    </IconButton>
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Minutes</TableCell>
                            <TableCell>Hours</TableCell>
                            <TableCell>Days</TableCell>
                            <TableCell>Month</TableCell>
                            <TableCell>Day of Week</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell><input type="number" value={min }onChange={handleChangeMin} /></TableCell>
                            <TableCell><input type="number" value={hour} onChange={handleChangeHour} /></TableCell>
                            <TableCell><input type="number" value={day} onChange={handleChangeDay} /></TableCell>
                            <TableCell><input type="number" value={month} onChange={handleChangeMonth} /></TableCell>
                            <TableCell><input type="number" value={weekday} onChange={handleChangeWeekday} /></TableCell>                            
                            </TableRow>
                    </TableBody>
                    {/*<hr />*/}
                </Table>
                <br />
                <Button onClick={handleStartNow} color="primary" variant="contained">Start</Button>
                <Button onClick={handleReschedule} color="secondary" variant="contained">Reschedule</Button>
            </DialogContent>
        </Dialog>
    );
};
