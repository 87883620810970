import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, createStyles, Grid, makeStyles, Paper, Switch, Theme, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { AGSearch } from '../AGSearch';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { TK } from '../../../store/Translations/translationKeys';
import { useTranslations } from '../../../store/Translations/hooks';
import { getSelectedProducts } from '../../../store/ProductsV2/selectors';
import { Item } from '../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: '5px',
            textAlign: 'center',
            color: theme.palette.text.secondary,
            borderRadius: '0px',
            boxShadow: 'none',
        },
        switchButton: {},
    }),
);

interface IAGToolbar {
    data: Item[];
    onInputSearchChange: (val: string) => void;
    onBtnExport: () => void;
    isExportable: boolean;
    searchColumns: string[];
    gridType: string | undefined;
    onCreateRfq?: () => void;
    paginationComponent: string | JSX.Element;
}

export const AGToolbar: React.FC<IAGToolbar> = ({
    data,
    isExportable,
    searchColumns,
    onBtnExport,
    gridType,
    paginationComponent,
    onInputSearchChange,
}) => {
    const classes = useStyles();
    const t = useTranslations();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Box display={gridType ? 'flex' : 'block'} justifyContent="space-between">
                        <Box justifyContent="right" display="flex">
                            {data && data.length > 0 && isExportable ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<SaveAltIcon />}
                                    onClick={onBtnExport}
                                >
                                    {t(TK.export)}
                                </Button>
                            ) : (
                                ''
                            )}
                            {data && data.length > 0 && searchColumns.length > 0 && !gridType ? (
                                <AGSearch onHandleChange={onInputSearchChange} />
                            ) : (
                                ''
                            )}
                        </Box>
                        {<Box width={'100%'}>{paginationComponent}</Box>}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};
