import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, Box } from '@material-ui/core';
import { MapOf } from '../../../../utils/Types';
import { useTranslations } from '../../../../store/Translations/hooks';
import { ATCMappingTableContainer } from './styled';
import { ProcessingATCSettings } from '../../../../models/ProcessingATCSettings';
import SingleSelectInput from '../../../../components/inputs/SingleSelectInput';
import Pagination from '@material-ui/lab/Pagination';

export interface ATCMappingTableProps {
    values: MapOf<ProcessingATCSettings>;
    onChange: (values: MapOf<ProcessingATCSettings>) => void;
}

const ATCMappingTable: React.FC<ATCMappingTableProps> = ({ values, onChange }) => {
    const t = useTranslations();
    const [atcList, setAtcList] = React.useState<ProcessingATCSettings[]>([]);
    const [atcListData, setAtcListData] = React.useState<ProcessingATCSettings[]>([]);
    const [currentPage, setCurrentPage] = React.useState(1);

    const recordsPerPage = 10;
    const totalNumberOfRecords = atcListData.length;
    const totalNumberOfPages = Math.ceil(totalNumberOfRecords / recordsPerPage);

    React.useEffect(() => {
        const offset = currentPage * recordsPerPage;
        const startingNumber = offset - recordsPerPage;
        setAtcList(
            Object.keys(values)
                .map((key) => values[key])
                .slice(startingNumber, offset),
        );
        setAtcListData(Object.keys(values).map((key) => values[key]));
    }, [values, setAtcList, setAtcListData]);

    const onChangePageNumber = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
        const offset = pageNumber * recordsPerPage;
        const startingNumber = offset - recordsPerPage;
        setAtcList(atcListData.slice(startingNumber, offset));
        setCurrentPage(pageNumber);
    };

    const handleChange = React.useCallback(
        (key: string) => (rowValues: string[]) => {
            //onChange({ ...values, [key]: rowValues });
        },
        [onChange, values],
    );

    if (Object.keys(values).length == 0) {
        return <></>;
    }
    var _1 = '';
    return (
        <ATCMappingTableContainer>
            {/*<Typography variant="caption">* {t(TK.specifyTheKeywordsForTheCorrespondingValues)}.</Typography>*/}
            <Table size="small">
                <TableBody>
                    {atcList.map((item, index) => (
                        <TableRow key={item.atcCode}>
                            <TableCell>{item.atcCode}</TableCell>
                            <TableCell width="100%">
                                <SingleSelectInput
                                    options={Object.keys(values)
                                        .map((I) => values[I].description)
                                        .slice(0, 5)}
                                    freeSolo
                                    disabled
                                    value={item.description}
                                    onChange={handleChange(item.atcCode)}
                                    allowAdd
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {atcList.length > 0 && (
                <Box display="flex" justifyContent="right">
                    <Box margin="1">
                        <Pagination
                            color="primary"
                            count={totalNumberOfPages}
                            page={currentPage}
                            onChange={onChangePageNumber}
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                </Box>
            )}
        </ATCMappingTableContainer>
    );
};

export default ATCMappingTable;
