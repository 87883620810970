import React, { useCallback, useState } from 'react';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';

import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Button,
    Box,
    Chip,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Tooltip,
    makeStyles,
    Popover,
    Theme,
    Typography,
    DialogContentText,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
} from '@material-ui/core';
//import {  } from '../../models/ProductV2';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AvailabilityColors, AvailabilityTerms, AvailabilityColorsFront } from '../../constants/general';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkIcon from '@material-ui/icons/Link';

import ReactCountryFlag from 'react-country-flag';
import { CloseIconWrapper, DialogTitleContainer } from '../../pages/ProductsListV3/styled';
import { LogExScrapper } from '../../models/LogExScrapper';
import { LogsScrapperResult } from '../../models/LogsScrapperResult';
import { LogMessage, LogMessageContent, Message } from '../../models/LogMessage';
import { useDispatch } from 'react-redux';
import { AddCommen, DeleteCommen, UpdateComment } from '../../store/Logs2/actions';
import { goToProducts, goToRFQsListV2 } from '../../store/Router/actions';
import { ReportComment } from '../../models/ReportComment';
import { routes } from '../../fetch/routes';
import { AGIconHoc } from '../AGTable/CellFormatter/Hoc/AGIconHoc';
import { Sources } from '../../models/Sources';
//import ProductContent from '../ProductContent';

interface LogsSourcesHistoryDialogProps {
    log?: LogsScrapperResult;
    source?: Sources;
    hist: LogExScrapper[];
    handleDialogClose: () => void;

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        lastUpdate: {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '30px',
            fontSize: '0.8rem',
        },
    }),
);

export const LogsSourcesHistoryDialog: React.FC<LogsSourcesHistoryDialogProps> = ({
    log,
    source,
    hist,
    handleDialogClose
}) => {
    const t = useTranslations();
    const classes = useStyles();
    const dispatch = useDispatch();
    //const [values, setValues] = React.useState<string[]>([]);
    const getDuration = React.useCallback((log: LogExScrapper): string => {
        const endDate = log.endDate != null ? log.endDate.toString() : new Date().toString();
        const dateFuture = Date.parse(endDate || '');
        const dateNow = Date.parse(log.startDate.toString());
        const timeDiff = dateFuture - dateNow;
        let seconds = Math.floor(timeDiff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days == 0) {
            return (
                hours.toString().padStart(2, '0') +
                ':' +
                minutes.toString().padStart(2, '0') +
                ':' +
                seconds.toString().padStart(2, '0')
            );
        }
        return (
            days +
            '.' +
            hours.toString().padStart(2, '0') +
            ':' +
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
        );
    }, []);

    const getDurationLong = React.useCallback((log: LogExScrapper): string => {
        const endDate = log.endDate != null ? log.endDate.toString() : new Date().toString();
        const dateFuture = Date.parse(endDate || '');
        const dateNow = Date.parse(log.startDate.toString());
        const timeDiff = dateFuture - dateNow;
        let seconds = Math.floor(timeDiff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days == 0) {
            if (hours != 0) {
                return (hours.toString() + ' hr ' + (minutes == 0 ? '' : minutes + ' min'))
            } else if (minutes != 0) {
                return (minutes + ' min ' + (seconds == 0 ? '' : seconds + ' sec'))
            } else {
                return (seconds == 0 ? 'now' : seconds + 'sec')
            }
        } if (days >= 31) {
            return ((days / 31) + ' mo ' + (days % 31 == 0 ? '' : (days % 31 + ' days')))
        }
        return (
            days + ' days ' + (hours == 0 ? ' ' : hours + 'hr')
        );
    }, []);
    const getStatusProp = React.useCallback(
        (log: LogExScrapper): any => {
            if (log == null) {
                return '';
            }
            if (log.error == null && !log.endDate) {
                return (<><span>{getDuration(log)}</span><img alt="Free Running Icon" width="40" src="https://cdn.iconscout.com/icon/free/png-256/free-running-3079964-2561166.png?f=webp&amp;w=256" srcSet="https://cdn.iconscout.com/icon/free/png-256/free-running-3079964-2561166.png?f=webp&amp;w=256 1x, https://cdn.iconscout.com/icon/free/png-256/free-running-3079964-2561166.png?f=webp&amp;w=512 2x" /></>);
            }
            if (log.isSuccess && !log.isVerified) {
                return (<><Tooltip title={<h6>Pending Approval</h6>}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40" height="40"><path d="M13.096 2.002v.002a2.496 2.496 0 0 0-1.602.5A2.5 2.5 0 0 0 10.56 5H8.5a.5.5 0 0 0-.5.5V6H3.5a.5.5 0 0 0-.5.5v23a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-23a.5.5 0 0 0-.5-.5H18v-.5a.5.5 0 0 0-.5-.5h-2.055a2.493 2.493 0 0 0-2.35-2.998zM13.059 3A1.495 1.495 0 0 1 14.3 5.244a.5.5 0 0 0 .17.674.5.5 0 0 0 .021.014.5.5 0 0 0 .02.01.5.5 0 0 0 .011.005.5.5 0 0 0 .022.01.5.5 0 0 0 .205.043H17v.418a.5.5 0 0 0 0 .164v1.836a.5.5 0 0 0 0 .164V9H9v-.422a.5.5 0 0 0 0-.16v-1.84a.5.5 0 0 0 0-.16V6h2.25a.5.5 0 0 0 .43-.766.5.5 0 0 0-.035-.09 1.496 1.496 0 0 1 .453-1.841 1.5 1.5 0 0 1 .96-.303zM26.5 6a.5.5 0 0 0-.5.5v1.502h-1.5L24.498 8a.5.5 0 0 0-.498.502v6.002c-.008.675 1.008.675 1 0V9.002h1V25.5a.5.5 0 0 0 .5.5h.129v1.502a.5.5 0 0 0 .5.5h.371V30h1v-1.998h.373a.5.5 0 0 0 .498-.504V26h.129a.5.5 0 0 0 .5-.5V8.262a.5.5 0 0 0 0-.033V6.5a.5.5 0 0 0-.5-.5h-3zM4 7h4v1H5.5a.5.5 0 0 0-.5.5v19a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-19a.5.5 0 0 0-.5-.5H18V7h4v22H4V7zm23 0h2v1.002h-2V7zM6 9h2v.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V9h2v18H6V9zm21 .002h2V14h-2V9.002zM7.5 11a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-4zm5.957 0a.5.5 0 0 0 .05 1h4.983a.5.5 0 1 0 0-1h-4.982a.5.5 0 0 0-.051 0zM8 12h3v3H8v-3zm5.457 1a.5.5 0 0 0 .05 1h4.983a.5.5 0 1 0 0-1h-4.982a.5.5 0 0 0-.051 0zm0 2a.5.5 0 0 0 .05 1h4.983a.5.5 0 1 0 0-1h-4.982a.5.5 0 0 0-.051 0zM27 15h2v10h-.094a.5.5 0 0 0-.035-.002h-1.744a.5.5 0 0 0-.025.002H27V15zM7.46 17a.5.5 0 0 0 .052 1h10.976a.5.5 0 1 0 0-1H7.512a.5.5 0 0 0-.051 0zm0 2a.5.5 0 0 0 .052 1h10.976a.5.5 0 1 0 0-1H7.512a.5.5 0 0 0-.051 0zm-.003 2a.5.5 0 0 0 .05 1h3.968a.5.5 0 1 0 0-1H7.508a.5.5 0 0 0-.051 0zm7.99 4a.5.5 0 0 0 .051 1h3.02a.5.5 0 1 0 0-1h-3.02a.5.5 0 0 0-.05 0zm12.18 1h.744v1h-.744v-1z"></path></svg></Tooltip></>);
            }
            if (log.isSuccess && !log.isAprroved) {
                return (<><Tooltip title={<h6>Deployed on Dev</h6>}><img alt="Free Checklist Icon" width="40" src="https://cdn.iconscout.com/icon/free/png-256/free-checklist-1661505-1410324.png?f=webp&amp;w=256" srcSet="https://cdn.iconscout.com/icon/free/png-256/free-checklist-1661505-1410324.png?f=webp&amp;w=256 1x, https://cdn.iconscout.com/icon/free/png-256/free-checklist-1661505-1410324.png?f=webp&amp;w=512 2x" /></Tooltip></>);
            }
            if (log.isSuccess && log.isAprroved) {
                return (<><Tooltip title={<h6>Updated</h6>} ><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100" id="Success"><path d="M50 5C25.19 5 5 25.19 5 50s20.19 45 45 45 45-20.19 45-45S74.81 5 50 5zm0 86C27.39 91 9 72.61 9 50S27.39 9 50 9s41 18.39 41 41-18.39 41-41 41zm22.41-56.41c.78.78.78 2.05 0 2.83l-28 28c-.39.38-.9.58-1.41.58s-1.02-.2-1.41-.59l-14-14c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0L43 61.17l26.59-26.59c.78-.78 2.04-.78 2.82.01z" fill="#00c4aa"></path></svg></Tooltip></>);
            }
            if (log.error != null) {
                return (<><Tooltip title={<h6>Failed</h6>}><img alt="Failed Icon" width="40" src="https://cdn.iconscout.com/icon/premium/png-256-thumb/failed-5612713-4731659.png?f=webp&amp;w=256" srcSet="https://cdn.iconscout.com/icon/premium/png-256-thumb/failed-5612713-4731659.png?f=webp&amp;w=256 1x, https://cdn.iconscout.com/icon/premium/png-256-thumb/failed-5612713-4731659.png?f=webp&amp;w=512 2x" /></Tooltip></>);
            }

            return 'asda';
        },
        [getDuration],
    );


    return (
        <Dialog open={!!source} maxWidth="lg" onClose={handleDialogClose}>
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ fontWeight: 700 }}>{source?.executionName}</div>
                </DialogTitleContainer>
                <CloseIconWrapper>
                    <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                        <HighlightOffIcon color="primary" fontSize="large" />
                    </IconButton>
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <span>Main: </span>
                <ol>
                    {source?.main.map(I => { return (<li>{I}</li>)})}
                </ol>
                {source?.secundary.length != 0 && <>
                    <br />
                    <span>Secundary: </span>
                    <hr />
                    <ol>
                        {source?.secundary.map(I => { return (<li>{I}</li>) })}
                    </ol>
                </>}
                {source?.shortages.length != 0 && <>
                    <br />
                    <span>Shortage: </span>
                    <hr />
                    <ol>
                    {source?.shortages.map(I => { return (<li>{I}</li>) })}
                    </ol>
                </>}
                <br /><hr /><br/>
                <h6>History</h6>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Status</TableCell>

                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {hist.filter(i => i.executionName == source?.executionName).map(I => (
                            <TableRow>
                                <TableCell>{I.executionName}</TableCell>
                                <TableCell>{new Date(Date.parse(I.startDate.toString())).toLocaleString()}</TableCell>
                                <TableCell>{I.endDate === undefined ? '' : new Date(Date.parse(I.endDate.toString())).toLocaleString()}</TableCell>
                                <TableCell>{getDurationLong(I)}</TableCell>
                                <TableCell>{getStatusProp(I)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </DialogContent>
            
        </Dialog>
    );
};
