import { RFQDetails } from '../../models/RFQDetails';
import { RFQEntryState } from '../../models/RFQEntryState';
import { RFQQuoteState } from '../../models/RFQQuoteState';
import { SupplierType } from '../../models/SupplierType';

export const rfqsDetails: RFQDetails[] = [
    {
        rfqNumber: '795/22',
        notes: [],
        suppliersDetails: [
            {
                threadId: '1843e7bd3d4792c5',
                supplierId: '5f44fe2adfd6c89e1b171cb8',
                supplierName: 'Acta Pharma (Kaliman)',
                supplierContactName: 'Krasimir Vasilev',
                supplierContactEmail: 'k.vasilev@kaliman.bg',
                countryCode: 'BG',
                supplierType: SupplierType.DesignatedWholesaler,
                country: 'Bulgaria',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.382Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '635c9e492f95823abcbe0f05',
                        rfqNr: '795/22',
                        name: 'Minirin',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0.2 mg (H01BA02)',
                        unitQuant: '100',
                        packSize: '30',
                        productCode: '17396',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5ODdiY2ExNS04NDU4LTQwZmItODUzNi1iYzdhNmI5OTZmMjciLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNiOCIsIm5hbWVpZCI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNiOCIsImVtYWlsIjoiay52YXNpbGV2QGthbGltYW4uYmciLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.pHC_d1WmffHZGbmdZkU1YVEBRl2Q-aKVz_NyJsBe9So&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bd5d55b902',
                supplierId: '5f44fe28dfd6c89e1b1719c0',
                supplierName: 'Nomeco',
                supplierContactName: 'Malene Bank Thøgersen',
                supplierContactEmail: 'btb@etrack1.nomeco.dk',
                supplierType: SupplierType.DesignatedWholesaler,
                countryCode: 'DK',
                country: 'Denmark',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.382Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719c0',
                        lastUpdateDate: '2022-11-04T07:09:43.497Z',
                        id: '633ae5fabb4f8ee8354d30b1',
                        rfqNr: '795/22',
                        name: 'Nocutil',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        unitQuant: '34',
                        packSize: '90',
                        productCode: '060887',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkMzgyZjE1NS02MmYyLTQ4NjYtODM5ZS0wYTc0ZTlhMDFkNzAiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTljMCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTljMCIsImVtYWlsIjoiYnRiQGV0cmFjazEubm9tZWNvLmRrIiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY3OTA3NzgwLCJleHAiOjE2Njg1MTI1ODAsImlhdCI6MTY2NzkwNzc4MCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.SNsB6pO6GJfJDKRxer4Agqjw94UCouff9AmiRuh4z3I&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bdd353433d',
                supplierId: '5f44fe28dfd6c89e1b1719ec',
                supplierName: 'Active Pharma',
                supplierContactName: 'Manfred Moers',
                supplierContactEmail: 'm.moers@activepharma.de',
                supplierType: SupplierType.DesignatedWholesaler,
                countryCode: 'DE',
                country: 'Germany',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.382Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719ec',
                        lastUpdateDate: '2022-11-07T13:26:42.711Z',
                        id: '6254164a8274c7a8ff6ecf01',
                        rfqNr: '795/22',
                        name: 'Nocutil 0,2 mg Tabletten',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        leadTimeToDeliver: '120',
                        availabilityPacks: '27',
                        exwNetPriceEuro: '136,70',
                        unitQuant: '3000 tablets',
                        packSize: 'Please confirm ',
                        productCode: '01008222',
                    },
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.382Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719ec',
                        lastUpdateDate: '2022-11-07T13:18:48.739Z',
                        id: '6253f4018274c7a8ff6e9c88',
                        rfqNr: '795/22',
                        name: 'Minirin 0.2mg Tabletten',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        leadTimeToDeliver: '5',
                        availabilityPacks: '9',
                        exwNetPriceEuro: '174,30',
                        unitQuant: '3000 tablets ',
                        packSize: 'Please confirm ',
                        productCode: '07382737',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjNjJlZmRlMy1lYzFmLTQ3ZTYtYmIwMi03NGM0Yjc5MDI2OGIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllYyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllYyIsImVtYWlsIjoibS5tb2Vyc0BhY3RpdmVwaGFybWEuZGUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.zFINiIt57PvBpTgiyR73VfxC4dHeOwjEy_XbZneYoug&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7be0df62cdb',
                supplierId: '632e166d66f48287ff009961',
                supplierName: 'Flens Pharma',
                supplierContactName: 'Poul Erik Jorgenser',
                supplierContactEmail: 'pej@flens-pharma.de',
                countryCode: 'DE',
                country: 'Germany',
                supplierType: SupplierType.Distributor,
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.382Z',
                        updatedBy: 'supplier-632e166d66f48287ff009961',
                        lastUpdateDate: '2022-11-04T11:11:41.173Z',
                        id: '6254164a8274c7a8ff6ecf01',
                        rfqNr: '795/22',
                        name: 'Nocutil 0,2 mg Tabletten',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        leadTimeToDeliver: '10',
                        availabilityPacks: '34',
                        exwNetPriceEuro: '134,50',
                        unitQuant: '3000 tablets',
                        packSize: 'Please confirm ',
                        productCode: '01008222',
                    },
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.382Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '6253f4018274c7a8ff6e9c88',
                        rfqNr: '795/22',
                        name: 'Minirin 0.2mg Tabletten',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        unitQuant: '3000 tablets ',
                        packSize: 'Please confirm ',
                        productCode: '07382737',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZmFiMmE5My1lM2U5LTQ0OTktOWE4Mi03Yjk1MTJhOWJhYzkiLCJ1bmlxdWVfbmFtZSI6IjYzMmUxNjZkNjZmNDgyODdmZjAwOTk2MSIsIm5hbWVpZCI6IjYzMmUxNjZkNjZmNDgyODdmZjAwOTk2MSIsImVtYWlsIjoicGVqQGZsZW5zLXBoYXJtYS5kZSIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NzkwNzc4MCwiZXhwIjoxNjY4NTEyNTgwLCJpYXQiOjE2Njc5MDc3ODAsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.A6u9ezaCVwhNmsiKT6bAXhAF8EX7EbDSIfoIkyzi4Dw&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7be2b19645f',
                supplierId: '5f44fe28dfd6c89e1b1719f8',
                supplierName: 'Pharmaroad',
                supplierContactName: 'Kitti MÁTYUS',
                supplierContactEmail: 'kitti.matyus@pharmaroad.hu',
                supplierType: SupplierType.Distributor,
                countryCode: 'HU',
                country: 'Hungary',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '63524d7fcb148c5396c94d43',
                        rfqNr: '795/22',
                        name: 'NOCUTIL 0,2 mg tabletta',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        unitQuant: '100 ',
                        packSize: '30',
                        productCode: 'OGYI-T-20088/02',
                    },
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '63524d13cb148c5396c9233f',
                        rfqNr: '795/22',
                        name: 'MINIRIN 0,2 mg tabletta',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        unitQuant: '100 ',
                        packSize: '30',
                        productCode: 'OGYI-T-05644/01',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2YjI3NWFmZi01YzU0LTQ5Y2EtOTAyYS1jMmQyNWZiMmM2MTAiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmOCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmOCIsImVtYWlsIjoia2l0dGkubWF0eXVzQHBoYXJtYXJvYWQuaHUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.nE0X7MLjuCY7cegYLJHVxBgKwzgSJg2vfSHn_dPH1k0&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7be604d3805',
                supplierId: '618bc0c754958c47ba2081d2',
                supplierName: 'Pharmexpert',
                supplierContactName: 'Kalman',
                supplierContactEmail: 'kalman.lorincz@pharmexpert.hu',
                supplierType: SupplierType.DesignatedWholesaler,
                countryCode: 'HU',
                country: 'Hungary',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-618bc0c754958c47ba2081d2',
                        lastUpdateDate: '2022-11-07T09:51:52.465Z',
                        id: '63524d7fcb148c5396c94d43',
                        rfqNr: '795/22',
                        name: 'NOCUTIL 0,2 mg tabletta',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        leadTimeToDeliver: '30',
                        availabilityPacks: '100',
                        exwNetPriceEuro: '24,4',
                        unitQuant: '100 ',
                        packSize: '30',
                        productCode: 'OGYI-T-20088/02',
                    },
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-618bc0c754958c47ba2081d2',
                        lastUpdateDate: '2022-11-07T09:54:03.451Z',
                        id: '63524d13cb148c5396c9233f',
                        rfqNr: '795/22',
                        name: 'MINIRIN 0,2 mg tabletta',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        leadTimeToDeliver: '30',
                        availabilityPacks: '100',
                        exwNetPriceEuro: '35,3',
                        unitQuant: '100 ',
                        packSize: '30',
                        productCode: 'OGYI-T-05644/01',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MWQyNWY2ZS0zYjg5LTQ2NDctOThjOC1mMjA0ZjcwZWZjMzEiLCJ1bmlxdWVfbmFtZSI6IjYxOGJjMGM3NTQ5NThjNDdiYTIwODFkMiIsIm5hbWVpZCI6IjYxOGJjMGM3NTQ5NThjNDdiYTIwODFkMiIsImVtYWlsIjoia2FsbWFuLmxvcmluY3pAcGhhcm1leHBlcnQuaHUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.2RQuIGx3fxZUxiX2KLIzuQ28vPhTOzH3VcLHZETwQHo&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7be8408ba26',
                supplierId: '5f44fe28dfd6c89e1b171a94',
                supplierName: 'Adeofarma',
                supplierContactName: 'Eugenijus',
                supplierContactEmail: 'eugenijus@adeofarma.com',
                supplierType: SupplierType.Distributor,
                countryCode: 'LT',
                country: 'Lithuania',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '635a5703b1d91f7fa0b69b03',
                        rfqNr: '795/22',
                        name: 'Minirin',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        unitQuant: '100 ',
                        packSize: '30',
                        productCode: '1001287',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYThhNGM4Yi0wMGRkLTQ2ZDQtYjJlZi0xM2YwZjk3ODAyNjgiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NCIsImVtYWlsIjoiZXVnZW5panVzQGFkZW9mYXJtYS5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.qm5thWF-9hjTz05hRb8mVLYYsJwzcyZQDHTBb-wgHXs&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bea6dadea6',
                supplierId: '5f44fe28dfd6c89e1b171a7a',
                supplierName: 'Lex Ano',
                supplierContactName: 'Milda Tamulionienė',
                supplierContactEmail: 'milda.t@lexano.lt',
                supplierType: SupplierType.Distributor,
                countryCode: 'LT',
                country: 'Lithuania',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b171a7a',
                        lastUpdateDate: '2022-11-08T09:46:24.493Z',
                        id: '635a5703b1d91f7fa0b69b03',
                        rfqNr: '795/22',
                        name: 'Minirin',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2 mg (H01BA02)',
                        unitQuant: '100 ',
                        packSize: '30',
                        productCode: '1001287',
                        comments: 'Skip reason: Not commercialized. Comments: undefined.',
                    },
                    {
                        createdBy: 'supplier-5f44fe28dfd6c89e1b171a7a',
                        creationDate: '2022-11-08T09:45:34.11Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '42db56b2',
                        rfqNr: '795/22',
                        name: 'Desmopressine-acetaat Mylan 0,1mg',
                        state: RFQQuoteState.Alternative,
                        endingDate: '2022-11-08T17:00:00Z',
                        activeSubstances: 'desmopressin',
                        leadTimeToDeliver: '21',
                        availabilityPacks: '34',
                        exwNetPriceEuro: '28,90',
                        packSize: '90',
                        productCode: 'RVG 30506',
                        countryOfOrigin: 'NL',
                    },
                    {
                        createdBy: 'supplier-5f44fe28dfd6c89e1b171a7a',
                        creationDate: '2022-11-08T09:46:18.434Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '35551191',
                        rfqNr: '795/22',
                        name: 'Desmopressine-acetaat Mylan 0,12mg',
                        state: RFQQuoteState.Alternative,
                        endingDate: '2022-11-08T17:00:00Z',
                        activeSubstances: 'desmopressin',
                        leadTimeToDeliver: '21',
                        availabilityPacks: '34',
                        exwNetPriceEuro: '38,45',
                        packSize: '90',
                        productCode: 'RVG 30507',
                        countryOfOrigin: 'NL',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0MzBlZWJkYS0wODIxLTRhOTYtYTYyMi1lNzRmMTQwMmQzMjQiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE3YSIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE3YSIsImVtYWlsIjoibWlsZGEudEBsZXhhbm8ubHQiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.AD_AGM7NQSFIRrlH3E7YGj6v0oL51Hn_FuZ6Z_Bwt9c&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bef4997254',
                supplierId: '5f44fe2adfd6c89e1b171cc8',
                supplierName: 'Orange Pharma',
                supplierContactName: 'Camila Tessini',
                supplierContactEmail: 'c.tessini@orangepharma.nl',
                countryCode: 'NL',
                country: 'Netherlands',
                supplierType: SupplierType.DesignatedWholesaler,
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-5f44fe2adfd6c89e1b171cc8',
                        lastUpdateDate: '2022-11-03T20:21:43.74Z',
                        id: '6356acfdcb148c5396cd9407',
                        rfqNr: '795/22',
                        name: 'DESMOPRESSINE ACETAAT AUROBINDO TABLET 0,2MG',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2MG (H01BA02 - DESMOPRESSIN)',
                        unitQuant: '100',
                        packSize: '30',
                        productCode: '15060365',
                        comments: 'Skip reason: Export ban. Comments: undefined.',
                    },
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-5f44fe2adfd6c89e1b171cc8',
                        lastUpdateDate: '2022-11-03T20:22:35.66Z',
                        id: '6356c016cb148c5396d100e1',
                        rfqNr: '795/22',
                        name: 'MINRIN',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin, Desmopressin - 0,2MG (H01BA02 - DESMOPRESSIN)',
                        leadTimeToDeliver: '5',
                        availabilityPacks: '20',
                        exwNetPriceEuro: '95.95',
                        unitQuant: '34 ',
                        packSize: '90',
                        productCode: '13484613',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ZDc2MTkzMy00NTQyLTRkYzMtYmEwZC05M2NkNTBhNTlmZDMiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNjOCIsIm5hbWVpZCI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNjOCIsImVtYWlsIjoiYy50ZXNzaW5pQG9yYW5nZXBoYXJtYS5ubCIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NzkwNzc4MCwiZXhwIjoxNjY4NTEyNTgwLCJpYXQiOjE2Njc5MDc3ODAsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.3Ku0J0iAlo4DHghX3tUBCFjetpZP7SOrJk13QNDY434&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bf19dde9ac',
                supplierId: '61791b0054958c47ba6a1730',
                supplierName: 'Phardis',
                supplierContactName: 'Ana-Maria Popa',
                supplierContactEmail: 'a-m.popa@phardis.nl',
                countryCode: 'NL',
                country: 'Netherlands',
                lastIteration: '2022-11-03T17:13:44.378Z',
                supplierType: SupplierType.DesignatedWholesaler,
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-61791b0054958c47ba6a1730',
                        lastUpdateDate: '2022-11-04T08:23:42.511Z',
                        id: '6356acfdcb148c5396cd9407',
                        rfqNr: '795/22',
                        name: 'DESMOPRESSINE ACETAAT AUROBINDO TABLET 0,2MG',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin - 0,2MG (H01BA02 - DESMOPRESSIN)',
                        expDate: '01/2025',
                        leadTimeToDeliver: '28',
                        availabilityPacks: '100',
                        exwNetPriceEuro: '12,00',
                        unitQuant: '100',
                        packSize: '30',
                        productCode: '15060365',
                    },
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-61791b0054958c47ba6a1730',
                        lastUpdateDate: '2022-11-04T08:26:41.242Z',
                        id: '6356c016cb148c5396d100e1',
                        rfqNr: '795/22',
                        name: 'MINRIN',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressin, Desmopressin - 0,2MG (H01BA02 - DESMOPRESSIN)',
                        expDate: '07/2024',
                        leadTimeToDeliver: '28',
                        availabilityPacks: '34',
                        exwNetPriceEuro: '92,00',
                        unitQuant: '34 ',
                        packSize: '90',
                        productCode: '13484613',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZGU0MWY5MS0xZGJmLTQyZWItYWMyZC1kZTJiN2RkOWIxMjMiLCJ1bmlxdWVfbmFtZSI6IjYxNzkxYjAwNTQ5NThjNDdiYTZhMTczMCIsIm5hbWVpZCI6IjYxNzkxYjAwNTQ5NThjNDdiYTZhMTczMCIsImVtYWlsIjoiYS1tLnBvcGFAcGhhcmRpcy5ubCIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NzkwNzc4MCwiZXhwIjoxNjY4NTEyNTgwLCJpYXQiOjE2Njc5MDc3ODAsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.7H4NdiuNdyNP4XQp2W9Loi05FGUzzsiKHQrD4Lq3Mko&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bf510c92f8',
                supplierId: '5f44fe28dfd6c89e1b1719fc',
                supplierName: 'Cefarm SA',
                supplierContactName: 'Michal Gadomski',
                supplierContactEmail: 'mgadomski@cefarm.com.pl',
                supplierType: SupplierType.DesignatedWholesaler,
                countryCode: 'PL',
                country: 'Poland',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719fc',
                        lastUpdateDate: '2022-11-03T17:22:20.049Z',
                        id: '6219e8368d41edc9bcb95d4e',
                        rfqNr: '795/22',
                        name: 'Minirin 0,2',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressini acetas - 0,2 mg (H01BA02)',
                        unitQuant: ' 100',
                        packSize: '30',
                        productCode: '05909990767113',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMWM3NTdjMS03ZGM2LTQ4Y2ItOGFjMy00NjA2YTZiZjAxMjkiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmYyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmYyIsImVtYWlsIjoibWdhZG9tc2tpQGNlZmFybS5jb20ucGwiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.ApvF2cxhFrxiZqUlW_Aywiw2ve_idc11hNbsrE0ikZU&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bf90165f6d',
                supplierId: '619268f1a454d932a24b7518',
                supplierName: 'OPTIFARMA',
                supplierContactName: 'Magdalena Girtler-Braszczynska',
                supplierContactEmail: 'm.girtler@optifarma.com',
                countryCode: 'PL',
                country: 'Poland',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                supplierType: SupplierType.DesignatedWholesaler,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-619268f1a454d932a24b7518',
                        lastUpdateDate: '2022-11-04T10:08:28.339Z',
                        id: '6219e8368d41edc9bcb95d4e',
                        rfqNr: '795/22',
                        name: 'Minirin 0,2',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'Desmopressini acetas - 0,2 mg (H01BA02)',
                        unitQuant: ' 100',
                        packSize: '30',
                        productCode: '05909990767113',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZWJkYmJmYi02ZDAzLTQxNmEtOGE3YS04MWI1MDBlYjNlNGUiLCJ1bmlxdWVfbmFtZSI6IjYxOTI2OGYxYTQ1NGQ5MzJhMjRiNzUxOCIsIm5hbWVpZCI6IjYxOTI2OGYxYTQ1NGQ5MzJhMjRiNzUxOCIsImVtYWlsIjoibS5naXJ0bGVyQG9wdGlmYXJtYS5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.BHPJ5Oh9C8H2XWH8ebC_uqM0V_0BnUB0MPP2f6yUQF0&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bfae0ccd41',
                supplierId: '5f44fe28dfd6c89e1b171a80',
                supplierName: 'BCN Farma S.L.',
                supplierContactName: 'Laura Fornieles',
                supplierContactEmail: 'l.fornieles@bcnfarma.com',
                countryCode: 'ES',
                country: 'Spain',
                supplierType: SupplierType.DesignatedWholesaler,
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '61d702ea2205d457d4e66499',
                        rfqNr: '795/22',
                        name: 'WETIRIN  0,2 mg COMPRIMIDOS, 30 comprimidos',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'DESMOPRESINA - 0,2 mg (H01BA02)',
                        unitQuant: '100',
                        packSize: '30',
                        productCode: '815514',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ODIwOTMwZC05MGYxLTQzZmEtYWExYy0zNmQ4Y2Q3ZWRmYmYiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE4MCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE4MCIsImVtYWlsIjoibC5mb3JuaWVsZXNAYmNuZmFybWEuY29tIiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY3OTA3NzgwLCJleHAiOjE2Njg1MTI1ODAsImlhdCI6MTY2NzkwNzc4MCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.2ewx25mkFtvO70h_it2GBs7a9a_KhilknvHdUQXJ5-8&rfqsNrs=795%2f22',
            },
            {
                threadId: '1843e7bfc898e3f9',
                supplierId: '5f44fe28dfd6c89e1b171a96',
                supplierName: 'Euroserv (Grupo Cofares)',
                supplierContactName: 'Manuel Delgado López',
                supplierContactEmail: 'mdelgado@euroserv.es',
                supplierType: SupplierType.DesignatedWholesaler,
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2022-11-03T17:13:44.378Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 794/22 - Desmopressina 0,1mg Comprimidos (H01BA02), 795/22 - Desmopressina 0,2mg Comprimidos (H01BA02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'sjacinto',
                        creationDate: '2022-11-03T17:13:44.383Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b171a96',
                        lastUpdateDate: '2022-11-04T09:29:38.331Z',
                        id: '61d702ea2205d457d4e66499',
                        rfqNr: '795/22',
                        name: 'WETIRIN  0,2 mg COMPRIMIDOS, 30 comprimidos',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-11-08T17:00:00Z',
                        rfqDescription: 'Desmopressina 0,2mg Comprimidos (H01BA02)',
                        activeSubstances: 'DESMOPRESINA - 0,2 mg (H01BA02)',
                        unitQuant: '100',
                        packSize: '30',
                        productCode: '815514',
                        comments: 'Skip reason: Not commercialized. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwYzA0YmIzOS02MmM4LTQxMmQtYTA3ZC0xNmFlODVjODcyZWIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NiIsImVtYWlsIjoibWRlbGdhZG9AZXVyb3NlcnYuZXMiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2Njc5MDc3ODAsImV4cCI6MTY2ODUxMjU4MCwiaWF0IjoxNjY3OTA3NzgwLCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.c9_QpBy_pyi5CH5EtKzgE4x32zkZ-te_946gkeKP3vs&rfqsNrs=795%2f22',
            },
        ],
    },
    {
        rfqNumber: '725/22',
        notes: [],
        suppliersDetails: [
            {
                threadId: '183c190db83d5e35',
                supplierId: '5f44fe29dfd6c89e1b171b08',
                supplierName: 'Alphamed',
                supplierType: SupplierType.GenericLaboratory,
                supplierContactName: 'Gregory Schmitz',
                supplierContactEmail: 'gregory.schmitz@alphamed.at',
                countryCode: 'AT',
                country: 'Austria',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.568Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '623a407e9b2a603476b9e0f0',
                        rfqNr: '725/22',
                        name: 'Oxytocin Grindeks 5 I.E./ml Injektions-/Infusionslösung',
                        state: RFQQuoteState.InProgress,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'OXYTOCIN - 5 I.E./ml (H01BB02)',
                        unitQuant: '400 vials ',
                        productCode: '140479',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiOTdkMjE2Mi0wNTQ4LTRkY2ItYjg3NC1hOGMwMjM2MzA2ZmIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI5ZGZkNmM4OWUxYjE3MWIwOCIsIm5hbWVpZCI6IjVmNDRmZTI5ZGZkNmM4OWUxYjE3MWIwOCIsImVtYWlsIjoiZ3JlZ29yeS5zY2htaXR6QGFscGhhbWVkLmF0Iiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1NDc2NzU2LCJleHAiOjE2NjYwODE1NTYsImlhdCI6MTY2NTQ3Njc1NiwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.hnd5kJkrkR_bSZTwB5c8-r-SEQpL5OGETmZP6eiDDOs&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190de44655af',
                supplierId: '5f8eb8e6394750af1dc0d68f',
                supplierName: 'Leram',
                supplierContactName: 'Martin Kříž',
                supplierType: SupplierType.AimOwner,
                supplierContactEmail: 'krizmartin@leram.cz',
                countryCode: 'CZ',
                country: 'Czech Republic',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '624b11d19b2a603476455f36',
                        rfqNr: '725/22',
                        name: 'OXYTOCIN FERRING-LÉČIVA',
                        state: RFQQuoteState.InProgress,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'OXYTOCIN - 5IU (H01BB02)',
                        unitQuant: ' 80 packs',
                        packSize: '5X1ML',
                        productCode: '0000544',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZGY5YTgwYS0wZjEzLTQ3NTctYmIwZi1iZTk1NmVmZGJkYTAiLCJ1bmlxdWVfbmFtZSI6IjVmOGViOGU2Mzk0NzUwYWYxZGMwZDY4ZiIsIm5hbWVpZCI6IjVmOGViOGU2Mzk0NzUwYWYxZGMwZDY4ZiIsImVtYWlsIjoia3Jpem1hcnRpbkBsZXJhbS5jeiIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTQ3Njc1NiwiZXhwIjoxNjY2MDgxNTU2LCJpYXQiOjE2NjU0NzY3NTYsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.znY-bv52oeYrA-uXkY09ERbYElrzu69VLzj6S2HQyRc&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190e07981062',
                supplierId: '5f44fe28dfd6c89e1b1719d0',
                supplierName: 'VMT UNION',
                supplierType: SupplierType.DesignatedWholesaler,
                supplierContactName: 'Martina',
                supplierContactEmail: 'martina@vmtunion.cz',
                countryCode: 'CZ',
                country: 'Czech Republic',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719d0',
                        lastUpdateDate: '2022-10-10T11:06:58.062Z',
                        id: '624b11d19b2a603476455f36',
                        rfqNr: '725/22',
                        name: 'OXYTOCIN FERRING-LÉČIVA',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'OXYTOCIN - 5IU (H01BB02)',
                        leadTimeToDeliver: '10',
                        availabilityPacks: '80',
                        exwNetPriceEuro: '13,06',
                        unitQuant: ' 80 packs',
                        packSize: '5X1ML',
                        productCode: '0000544',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3OTdjYTY3My01YWVmLTQ0OTUtOWRmMC0zMzgzMDFjN2JhYjYiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlkMCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlkMCIsImVtYWlsIjoibWFydGluYUB2bXR1bmlvbi5jeiIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTQ3Njc1NywiZXhwIjoxNjY2MDgxNTU3LCJpYXQiOjE2NjU0NzY3NTcsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.c579C26tpFc2VMELZaw5hfrNFB2D1-4-M6Ze31oup9g&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190e3d725b63',
                supplierId: '5f44fe28dfd6c89e1b1719c2',
                supplierName: 'Cerp-Rouen',
                supplierType: SupplierType.Distributor,
                supplierContactName: 'Dorothée Bertin',
                supplierContactEmail: 'dorotheebertin@cerp-rouen.fr',
                countryCode: 'FR',
                country: 'France',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '63362c1fbb4f8ee83555f4f6',
                        rfqNr: '725/22',
                        name: 'OXYTOCINE PANPHARMA 5 UI/1 ml, solution injectable',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'oxytocine - 5 UI (H01BB02)',
                        unitQuant: ' 40 packs',
                        packSize: '10 ampoule(s) en verre de 1 ml',
                        productCode: '34009 584 141 9 0',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzZTA4Yjk3Yy00OGU3LTQzNzAtYjM1OS05OTEzZWJlMjc4MWMiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTljMiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTljMiIsImVtYWlsIjoiZG9yb3RoZWViZXJ0aW5AY2VycC1yb3Vlbi5mciIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTQ3Njc1NywiZXhwIjoxNjY2MDgxNTU3LCJpYXQiOjE2NjU0NzY3NTcsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.fDW4d5EPA4rXMNKvnD50p-U4MbC6ixMeXIpnsoY0JDU&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190e4036a82a',
                supplierId: '60d36204632e4358f6ca799d',
                supplierName: 'Pharmel',
                supplierType: SupplierType.Distributor,
                supplierContactName: 'Anne PEGORARO - PHARMEL',
                supplierContactEmail: 'aspegoraro@pharmel.fr',
                countryCode: 'FR',
                country: 'France',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-60d36204632e4358f6ca799d',
                        lastUpdateDate: '2022-10-10T11:45:29.688Z',
                        id: '63362c1fbb4f8ee83555f4f6',
                        rfqNr: '725/22',
                        name: 'OXYTOCINE PANPHARMA 5 UI/1 ml, solution injectable',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'oxytocine - 5 UI (H01BB02)',
                        unitQuant: ' 40 packs',
                        packSize: '10 ampoule(s) en verre de 1 ml',
                        productCode: '34009 584 141 9 0',
                        comments: 'Skip reason: Not in our range. Comments: for hospitals.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0MjZkY2VkMy02MzRlLTQzMGQtYmRmNy0zMWE3YjJiYmI4N2QiLCJ1bmlxdWVfbmFtZSI6IjYwZDM2MjA0NjMyZTQzNThmNmNhNzk5ZCIsIm5hbWVpZCI6IjYwZDM2MjA0NjMyZTQzNThmNmNhNzk5ZCIsImVtYWlsIjoiYXNwZWdvcmFyb0BwaGFybWVsLmZyIiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1NDc2NzU2LCJleHAiOjE2NjYwODE1NTYsImlhdCI6MTY2NTQ3Njc1NiwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.AToFkw2RLVFi0XnG9MD39UINeplDzXfwyKY3DI2Kjj0&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190e9f86374c',
                supplierId: '5f44fe28dfd6c89e1b1719ec',
                supplierName: 'Active Pharma',
                supplierType: SupplierType.GenericLaboratory,
                supplierContactName: 'Manfred Moers',
                supplierContactEmail: 'm.moers@activepharma.de',
                countryCode: 'DE',
                country: 'Germany',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719ec',
                        lastUpdateDate: '2022-10-10T12:21:35.466Z',
                        id: '62e82d45e5a357a304687598',
                        rfqNr: '725/22',
                        name: 'Oxytocin 5 I.E. Hexal',
                        state: RFQQuoteState.Quoted,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oxytocin - 5 IE/ml (H01BB02)',
                        leadTimeToDeliver: '1',
                        availabilityPacks: '11',
                        exwNetPriceEuro: '8,50',
                        unitQuant: ' 400 vials',
                        productCode: '03495433',
                        comments: 'for 40 packs I guess - approx 10 days',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxM2I3NTRmYi04OTQwLTQ2MzQtYWE3OC1iMTdhZWNkNDI4ZjIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllYyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllYyIsImVtYWlsIjoibS5tb2Vyc0BhY3RpdmVwaGFybWEuZGUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTYsImV4cCI6MTY2NjA4MTU1NiwiaWF0IjoxNjY1NDc2NzU2LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.K0Fdc-6BjR1AzQJZVsq3vI7LpOiAkao1Bf9YgVMPgaM&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190eab5f122b',
                supplierId: '5f44fe28dfd6c89e1b171a56',
                supplierName: 'Pharmahandel Köhnen',
                supplierType: SupplierType.Mah,
                supplierContactName: 'Maria-Rossella Parini',
                supplierContactEmail: 'mrparini@pharmakoehnen.de',
                countryCode: 'DE',
                country: 'Germany',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '62e82d45e5a357a304687598',
                        rfqNr: '725/22',
                        name: 'Oxytocin 5 I.E. Hexal',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oxytocin - 5 IE/ml (H01BB02)',
                        unitQuant: ' 400 vials',
                        productCode: '03495433',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlOTVhMTBlNC03MDc0LTQzZGYtYjM1Yi0yYjdhMWQxYjhkZmMiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE1NiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE1NiIsImVtYWlsIjoibXJwYXJpbmlAcGhhcm1ha29laG5lbi5kZSIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTQ3Njc1NiwiZXhwIjoxNjY2MDgxNTU2LCJpYXQiOjE2NjU0NzY3NTYsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.JneML3dYopAkyv1a8xtvTw2EPXQWdffueKgddG3aL4I&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190ed69d1abb',
                supplierId: '5f44fe28dfd6c89e1b1719f8',
                supplierName: 'Pharmaroad',
                supplierType: SupplierType.Manufacturer,
                supplierContactName: 'Kitti MÁTYUS',
                supplierContactEmail: 'kitti.matyus@pharmaroad.hu',
                countryCode: 'HU',
                country: 'Hungary',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719f8',
                        lastUpdateDate: '2022-10-10T13:54:47.438Z',
                        id: '6247063e9b2a603476201b41',
                        rfqNr: '725/22',
                        name: 'OXYTOCIN GRINDEKS  5 NE/ml oldatos injekció vagy infúzió',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'oxytocin - 5 NE/ml (H01BB02)',
                        unitQuant: ' 4 packs',
                        packSize: '100 X 1 ml ampulla',
                        productCode: 'OGYI-T-23732/03',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1ZDkyNWZkZS1jOWNlLTRkODktOGIxZi1mMjdhZDI0ODk2YzEiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmOCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmOCIsImVtYWlsIjoia2l0dGkubWF0eXVzQHBoYXJtYXJvYWQuaHUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTcsImV4cCI6MTY2NjA4MTU1NywiaWF0IjoxNjY1NDc2NzU3LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.j2gcDTJ-ZhPgPLXcxPHy0C534hMaBJMhBjZrRc89NZI&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190ef5b1ba2a',
                supplierId: '618bc0c754958c47ba2081d2',
                supplierName: 'Pharmexpert',
                supplierType: SupplierType.Manufacturer,
                supplierContactName: 'Kalman',
                supplierContactEmail: 'kalman.lorincz@pharmexpert.hu',
                countryCode: 'HU',
                country: 'Hungary',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-618bc0c754958c47ba2081d2',
                        lastUpdateDate: '2022-10-10T11:07:06.073Z',
                        id: '6247063e9b2a603476201b41',
                        rfqNr: '725/22',
                        name: 'OXYTOCIN GRINDEKS  5 NE/ml oldatos injekció vagy infúzió',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'oxytocin - 5 NE/ml (H01BB02)',
                        unitQuant: ' 4 packs',
                        packSize: '100 X 1 ml ampulla',
                        productCode: 'OGYI-T-23732/03',
                        comments:
                            'Skip reason: I can offer another product: OGYI-T-03058/01\t\tOXYTOCIN 5 NE/ML OLDATOS INJEKCIÓ\t5x1ml ampulla\tH01BB02\toxitocin.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MDQ1ZGIwOS0xNjZmLTQzNDctYjg1Ny1iMTMxMWIzNzFjYzQiLCJ1bmlxdWVfbmFtZSI6IjYxOGJjMGM3NTQ5NThjNDdiYTIwODFkMiIsIm5hbWVpZCI6IjYxOGJjMGM3NTQ5NThjNDdiYTIwODFkMiIsImVtYWlsIjoia2FsbWFuLmxvcmluY3pAcGhhcm1leHBlcnQuaHUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTYsImV4cCI6MTY2NjA4MTU1NiwiaWF0IjoxNjY1NDc2NzU2LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.CD6i2NV9nlvdqmAHs3l2ZeP8W7vxGnCi34Rl24hY9MA&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190f24eaa6d0',
                supplierId: '5f44fe2adfd6c89e1b171cbe',
                supplierName: 'Thera Pharmaceuticals (Axium)',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Louise Merry',
                supplierContactEmail: 'louisemerry@navi.ie',
                countryCode: 'IE',
                country: 'Ireland',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '61d8397f2205d457d4013f9e',
                        rfqNr: '725/22',
                        name:
                            'Ofost 5 IU/ml concentrate for solution for infusion or solution for intramuscular injection',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oxytocin - 5 international unit(s)/millilitre (H01BB02)',
                        unitQuant: '400 vials ',
                        productCode: 'PA22992/001/001',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjYWY4Nzk4OS04NTE2LTQyNzEtYTBhNC00YTVkNTQ5ZGFmOWQiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNiZSIsIm5hbWVpZCI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNiZSIsImVtYWlsIjoibG91aXNlbWVycnlAbmF2aS5pZSIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTQ3Njc1NiwiZXhwIjoxNjY2MDgxNTU2LCJpYXQiOjE2NjU0NzY3NTYsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.KzzB4dgQ3xsXc8t8Ijv0RIJBY2r0AYFGBnsTAmxzGN4&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190f576ced50',
                supplierId: '5f44fe28dfd6c89e1b171a94',
                supplierName: 'Adeofarma',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Eugenijus',
                supplierContactEmail: 'eugenijus@adeofarma.com',
                countryCode: 'LT',
                country: 'Lithuania',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b171a94',
                        lastUpdateDate: '2022-10-10T12:11:21.827Z',
                        id: '5f9c46cc1c0c5608272d18bb',
                        rfqNr: '725/22',
                        name: 'Oxytocin Panpharma',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oksitocinas - 5 TV/ml (H01BB02)',
                        unitQuant: '40 packs ',
                        packSize: '1 ml N10, ampulė',
                        productCode: '1084423',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxZDc0MWM5ZS1lZTI0LTQ0ZjEtYTg1Yy04N2EwYzNlNTI4ZTEiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NCIsImVtYWlsIjoiZXVnZW5panVzQGFkZW9mYXJtYS5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTYsImV4cCI6MTY2NjA4MTU1NiwiaWF0IjoxNjY1NDc2NzU2LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.MIdzGzuoZB1UkMcrzltrmPHC7B0Yy1TJplTwpeDBS60&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190f7dad7480',
                supplierId: '5f44fe28dfd6c89e1b171a7a',
                supplierName: 'Lex Ano',
                supplierContactName: 'Milda Tamulionienė',
                supplierType: SupplierType.Wholesaler,
                supplierContactEmail: 'milda.t@lexano.lt',
                countryCode: 'LT',
                country: 'Lithuania',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f9c46cc1c0c5608272d18bb',
                        rfqNr: '725/22',
                        name: 'Oxytocin Panpharma',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oksitocinas - 5 TV/ml (H01BB02)',
                        unitQuant: '40 packs ',
                        packSize: '1 ml N10, ampulė',
                        productCode: '1084423',
                    },
                    {
                        createdBy: 'supplier-5f44fe28dfd6c89e1b171a7a',
                        creationDate: '2022-10-10T13:38:01.332Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: 'fe10717e',
                        rfqNr: '725/22',
                        name: 'Ofost 5IU/ml 1ml sln. for inj./inf. x10',
                        state: RFQQuoteState.Alternative,
                        endingDate: '2022-10-13T10:45:30Z',
                        activeSubstances: 'oxytocin',
                        expDate: '08/2025',
                        leadTimeToDeliver: '10',
                        availabilityPacks: '40',
                        exwNetPriceEuro: '14,70',
                        packSize: '10',
                        productCode: '1091139',
                        countryOfOrigin: 'LT',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkMWIwMGU1Ny0wNmE0LTQ3NWItOWQ4Yi0zY2NlNWM4N2E4MjciLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE3YSIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE3YSIsImVtYWlsIjoibWlsZGEudEBsZXhhbm8ubHQiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTYsImV4cCI6MTY2NjA4MTU1NiwiaWF0IjoxNjY1NDc2NzU2LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.bcApnyR6V4UOwVKwgqTgChYszX8jRmg_o2UiAD19uOI&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190f95437c9a',
                supplierId: '5f44fe28dfd6c89e1b1719d6',
                supplierName: 'Rubicon Pharmaceuticals S.R.L.',
                supplierContactName: 'Patricia  Birau',
                supplierType: SupplierType.Wholesaler,
                supplierContactEmail: 'patricia.birau@rubiconpharmaceuticals.ro',
                countryCode: 'RO',
                country: 'Romania',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '6304cdb5676188709a9a0016',
                        rfqNr: '725/22',
                        name: 'OFOST 8,3 micrograme/ml',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'OXYTOCINUM - 8,3micrograme/ml (H01BB02)',
                        unitQuant: ' 80 packs',
                        packSize:
                            'Cutie cu 5 fiole din sticla borosilicata transparenta tip I, cu punct sau inel de rupere a cate 1 ml sol. inj./perf.',
                        productCode: 'W64163001',
                    },
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '6304cee4676188709a9a1b36',
                        rfqNr: '725/22',
                        name: 'OXITOCINA PANPHARMA 5 UI/ml',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'OXYTOCINUM - 5UI/ml (H01BB02)',
                        unitQuant: ' 40 packs',
                        packSize:
                            'Cutie cu 10 fiole din sticla incolora, cu capacitatea de 1 ml care contin conc. pt. sol. perf.',
                        productCode: 'W65329003',
                    },
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '6304cee5676188709a9a1b39',
                        rfqNr: '725/22',
                        name: 'OXITOCINA PANPHARMA 5 UI/ml',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'OXYTOCINUM - 5UI/ml (H01BB02)',
                        unitQuant: ' 8 packs',
                        packSize:
                            'Cutie cu 50 fiole din sticla incolora, cu capacitatea de 1 ml care contin conc. pt. sol. perf.',
                        productCode: 'W65329004',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZDc2YjA5NS1kZmNhLTRjYWMtOGEwYS0zNDRlNGI0NGU4ZDQiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlkNiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlkNiIsImVtYWlsIjoicGF0cmljaWEuYmlyYXVAcnViaWNvbnBoYXJtYWNldXRpY2Fscy5ybyIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTQ3Njc1NywiZXhwIjoxNjY2MDgxNTU3LCJpYXQiOjE2NjU0NzY3NTcsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.cAtzojyXANTHavPxcs_8dvnTJ158kUybUh42tcrWxLU&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190fd67ddb79',
                supplierId: '619268f1a454d932a24b7518',
                supplierName: 'OPTIFARMA',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Magdalena Girtler-Braszczynska',
                supplierContactEmail: 'm.girtler@optifarma.com',
                countryCode: 'PL',
                country: 'Poland',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-619268f1a454d932a24b7518',
                        lastUpdateDate: '2022-10-10T11:08:07.465Z',
                        id: '621a562b8d41edc9bcbc57a0',
                        rfqNr: '725/22',
                        name: 'Oxytocin PANPHARMA',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oxytocinum - 5 IU/ml (H01BB02)',
                        unitQuant: '40 packs ',
                        packSize: '10 amp. 1 ml',
                        productCode: '05909991354183',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3OGQ4OGU2My02ZmQ3LTRiNzctYjdmNy0xMDNjOGM0MzA2OTMiLCJ1bmlxdWVfbmFtZSI6IjYxOTI2OGYxYTQ1NGQ5MzJhMjRiNzUxOCIsIm5hbWVpZCI6IjYxOTI2OGYxYTQ1NGQ5MzJhMjRiNzUxOCIsImVtYWlsIjoibS5naXJ0bGVyQG9wdGlmYXJtYS5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTcsImV4cCI6MTY2NjA4MTU1NywiaWF0IjoxNjY1NDc2NzU3LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.2WfeMJ0HwfYo-PRf8OjTHr63QSOlie-TU5wGEYmldo4&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c190ff1fb03b4',
                supplierId: '5f44fe28dfd6c89e1b1719fc',
                supplierName: 'Cefarm SA',
                supplierContactName: 'Michal Gadomski',
                supplierType: SupplierType.Wholesaler,
                supplierContactEmail: 'mgadomski@cefarm.com.pl',
                countryCode: 'PL',
                country: 'Poland',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        updatedBy: 'supplier-5f44fe28dfd6c89e1b1719fc',
                        lastUpdateDate: '2022-10-10T11:09:14.635Z',
                        id: '621a562b8d41edc9bcbc57a0',
                        rfqNr: '725/22',
                        name: 'Oxytocin PANPHARMA',
                        state: RFQQuoteState.Declined,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'Oxytocinum - 5 IU/ml (H01BB02)',
                        unitQuant: '40 packs ',
                        packSize: '10 amp. 1 ml',
                        productCode: '05909991354183',
                        comments: 'Skip reason: Not in our range. Comments: undefined.',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNTgyMjQwYy03NzU1LTQ1ZjAtOGMxOC05MjY4OGJlYzBhOGEiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmYyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmYyIsImVtYWlsIjoibWdhZG9tc2tpQGNlZmFybS5jb20ucGwiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTcsImV4cCI6MTY2NjA4MTU1NywiaWF0IjoxNjY1NDc2NzU3LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.kJM-mqHgOG09f-Sqq9cuhVSpuF-ebDlcw1JOEvg93Z8&rfqsNrs=725%2f22',
            },
            {
                threadId: '183c191010138ac8',
                supplierId: '5f44fe29dfd6c89e1b171bd8',
                supplierName: 'Pramit Pharmaceuticals',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Trushil Joshi',
                supplierContactEmail: 'joshi.trushil@pramit.si',
                countryCode: 'SI',
                country: 'Slovenia',
                lastIteration: '2022-10-10T11:04:09.559Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 725/22 - OXITOCINA 5 I.E./ml  (H01BB02)',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'jaime.sousa',
                        creationDate: '2022-10-10T11:04:09.569Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '61ef7014a6a3d39e817659f6',
                        rfqNr: '725/22',
                        name: 'Ofost 5 i.e./ml raztopina za injiciranje/infundiranje',
                        state: RFQQuoteState.Open,
                        endingDate: '2022-10-13T10:45:30Z',
                        rfqDescription: 'OXITOCINA 5 I.E./ml  (H01BB02)',
                        activeSubstances: 'oksitocin - 5 i.e. /  1 ml (H01BB02)',
                        unitQuant: '400 vials ',
                        packSize: '1 ml',
                        productCode: '150610',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkMzY3OGExYy01NTVjLTRiZTktOGUzYS1jYzRkMGUyMGU5ZmYiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI5ZGZkNmM4OWUxYjE3MWJkOCIsIm5hbWVpZCI6IjVmNDRmZTI5ZGZkNmM4OWUxYjE3MWJkOCIsImVtYWlsIjoiam9zaGkudHJ1c2hpbEBwcmFtaXQuc2kiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjU0NzY3NTYsImV4cCI6MTY2NjA4MTU1NiwiaWF0IjoxNjY1NDc2NzU2LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.IzRapOerjlyhERSJmwH1qNM4oD9syr7eoIR-gBUHrqU&rfqsNrs=725%2f22',
            },
        ],
    },
    {
        rfqNumber: '363/21',
        notes: [],
        suppliersDetails: [
            {
                threadId: '1787de270ec6e3d0',
                supplierId: '5f44fe29dfd6c89e1b171b08',
                supplierName: 'Alphamed',
                supplierContactName: 'Gregory Schmitz',
                supplierType: SupplierType.Wholesaler,
                supplierContactEmail: 'gregory.schmitz@alphamed.at',
                countryCode: 'AT',
                country: 'Austria',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21 - BETAFERON 250 Mikrogramm/ml Pulver und Lösungsmittel zur Herstellung einer Injektionslösung, Pilocarpin "Agepha" 2 % - Augensalbe',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.481Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5fcfddc4cc002badc8ddf894',
                        rfqNr: '363/21',
                        name: 'Pilocarpin "Agepha" 2 % - Augensalbe',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPIN HYDROCHLORID (S01EB01)',
                        unitQuant: ' 250 packs',
                        productCode: '5986',
                        countryOfOrigin: 'Austria',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNWZmOGQzNC0yOTZkLTRiN2MtODFkYi01YjRiOWNkMGI4NjYiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI5ZGZkNmM4OWUxYjE3MWIwOCIsIm5hbWVpZCI6IjVmNDRmZTI5ZGZkNmM4OWUxYjE3MWIwOCIsImVtYWlsIjoiZ3JlZ29yeS5zY2htaXR6QGFscGhhbWVkLmF0Iiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1MTQ5NDI4LCJleHAiOjE2NjU3NTQyMjgsImlhdCI6MTY2NTE0OTQyOCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.dWVjKbgYlm5UGXmTrlkSxYq8w4DAzyKw_Kp0eBZc7rs&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de272af71cfb',
                supplierId: '5f44fe2adfd6c89e1b171ce2',
                supplierName: 'Astropharma',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Vogelsinger Andreas',
                supplierContactEmail: 'andreas.vogelsinger@astropharma.at',
                countryCode: 'AT',
                country: 'Austria',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21 - BETAFERON 250 Mikrogramm/ml Pulver und Lösungsmittel zur Herstellung einer Injektionslösung, Pilocarpin "Agepha" 2 % - Augensalbe',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5fcfddc4cc002badc8ddf894',
                        rfqNr: '363/21',
                        name: 'Pilocarpin "Agepha" 2 % - Augensalbe',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPIN HYDROCHLORID (S01EB01)',
                        unitQuant: ' 250 packs',
                        productCode: '5986',
                        countryOfOrigin: 'Austria',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjZWFkMzliNy0zY2RkLTRmNzUtODQ3Ni1mZmQ4NDhiYmU1OWMiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNlMiIsIm5hbWVpZCI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWNlMiIsImVtYWlsIjoiYW5kcmVhcy52b2dlbHNpbmdlckBhc3Ryb3BoYXJtYS5hdCIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTE0OTQyOCwiZXhwIjoxNjY1NzU0MjI4LCJpYXQiOjE2NjUxNDk0MjgsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.VxUryfphUbc77J1f_hVBB6us6inb6o1cmtOjZKj6KP8&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de275dd0d356',
                supplierId: '5f44fe28dfd6c89e1b1719a8',
                supplierName: 'Diprophar S.A.',
                supplierContactName: 'Mathieu Graitson',
                supplierType: SupplierType.Wholesaler,
                supplierContactEmail: 'm.graitson@diprophar.com',
                countryCode: 'BE',
                country: 'Belgium',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21 - BETAFERON SOL. INJ. (PDR+SOLV.) S.C. [FLAC.+SER. PRÉREMPLIE] 15X 250µG, PILOCARPINE-ISOPTO 2%',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb1977d4ecb317d0e0b9ceb',
                        rfqNr: '363/21',
                        name: 'PILOCARPINE-ISOPTO 2%',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE (S01EB01)',
                        unitQuant: ' 250 vials',
                        productCode: '0050-658',
                        countryOfOrigin: 'Belgium',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOWEzMDE1Yy1hZmYxLTRhNTgtYjlkMi05ZTY4ZmI0OGQ0YmIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlhOCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlhOCIsImVtYWlsIjoibS5ncmFpdHNvbkBkaXByb3BoYXIuY29tIiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1MTQ5NDI4LCJleHAiOjE2NjU3NTQyMjgsImlhdCI6MTY2NTE0OTQyOCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.chFV-YNcvH230cPQIxKLy-46NxkCPmvC_xPlIFuoMG0&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de277824f7bf',
                supplierId: '5f44fe28dfd6c89e1b1719e4',
                supplierName: 'Novitan',
                supplierContactName: 'Saskia Bekaert',
                supplierType: SupplierType.Wholesaler,
                supplierContactEmail: 'saskia.bekaert@novitan.com',
                countryCode: 'BE',
                country: 'Belgium',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21 - BETAFERON SOL. INJ. (PDR+SOLV.) S.C. [FLAC.+SER. PRÉREMPLIE] 15X 250µG, PILOCARPINE-ISOPTO 2%',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb1977d4ecb317d0e0b9ceb',
                        rfqNr: '363/21',
                        name: 'PILOCARPINE-ISOPTO 2%',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE (S01EB01)',
                        unitQuant: ' 250 vials',
                        productCode: '0050-658',
                        countryOfOrigin: 'Belgium',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzZjdhYjcwZS01NzQzLTRhZjYtOWY2NS01ZTk0YTRhYzE2ZDQiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllNCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllNCIsImVtYWlsIjoic2Fza2lhLmJla2FlcnRAbm92aXRhbi5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.BxhHU-_tua5KhONMOFIFra9gJEWOSAPzdkn3WyDRo-0&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de27c0b8e34b',
                supplierId: '5f44fe28dfd6c89e1b1719c2',
                supplierName: 'Cerp-Rouen',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Marie-Christine Duthil',
                supplierContactEmail: 'mariechristineduthil@cerp-rouen.fr',
                countryCode: 'FR',
                country: 'France',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21, 365/21 - BETAFERON 250 microgrammes/ml, poudre et solvant pour solution injectable, ISOPTO PILOCARPINE 2 POUR CENT, collyre, DEMECLOCYCLINE ACETLAB 150 mg, gélule',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f2be612bdeaa9bba36d95ef',
                        rfqNr: '363/21',
                        name: 'ISOPTO PILOCARPINE 2 POUR CENT, collyre',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'pilocarpine (chlorhydrate de) - 2 g',
                        unitQuant: ' 250 packs',
                        packSize: '1 flacon(s) compte-gouttes polyéthylène de 10  ml',
                        productCode: '34009 332 576 0 6',
                        countryOfOrigin: 'France',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNjU4NjI4NS1hYTc2LTQ3N2ItYTBkNS1mYjE5ZjcxNzgwMzciLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTljMiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTljMiIsImVtYWlsIjoibWFyaWVjaHJpc3RpbmVkdXRoaWxAY2VycC1yb3Vlbi5mciIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTE0OTQyOCwiZXhwIjoxNjY1NzU0MjI4LCJpYXQiOjE2NjUxNDk0MjgsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.KhlcM0notuAshnWCWbspko5L8_uqZmvb2YCCtAYKPZU&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de27c3b41c55',
                supplierId: '5f44fe28dfd6c89e1b1719de',
                supplierName: 'Euromedex',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Cathy Goerst',
                supplierContactEmail: 'cathy.goerst@euromedex.com',
                countryCode: 'FR',
                country: 'France',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21, 365/21 - BETAFERON 250 microgrammes/ml, poudre et solvant pour solution injectable, ISOPTO PILOCARPINE 2 POUR CENT, collyre, DEMECLOCYCLINE ACETLAB 150 mg, gélule',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f2be612bdeaa9bba36d95ef',
                        rfqNr: '363/21',
                        name: 'ISOPTO PILOCARPINE 2 POUR CENT, collyre',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'pilocarpine (chlorhydrate de) - 2 g',
                        unitQuant: ' 250 packs',
                        packSize: '1 flacon(s) compte-gouttes polyéthylène de 10  ml',
                        productCode: '34009 332 576 0 6',
                        countryOfOrigin: 'France',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzMTRiN2YxZC04MDY5LTQ2OWYtOTJiYi01Y2EwZTJiMWYzNzYiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlkZSIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlkZSIsImVtYWlsIjoiY2F0aHkuZ29lcnN0QGV1cm9tZWRleC5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.yAK6YvCJSNoqEYMnE-nSGNnwliB6SMmmye1elfDuNbk&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de27f33ba52d',
                supplierId: '5f44fe28dfd6c89e1b171a2e',
                supplierName: 'Lucien Ortscheit GmbH',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Angela Folz',
                supplierContactEmail: 'folz@ortscheit.de',
                countryCode: 'DE',
                country: 'Germany',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 360/21, 361/21, 362/21, 363/21, 364/21 - ',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb1fbe04ecb317d0e26dc59',
                        rfqNr: '363/21',
                        name: 'SPERSACARPIN® 2% (20 MG/ML) AUGENTROPFEN 3×10 ML (N3)',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE (S01EB01)',
                        unitQuant: ' 84 packs',
                        packSize: 'Spersacarpin® 2% (20 mg/ml) Augentropfen 3×10 ml (N3)',
                        productCode: '02235173',
                        countryOfOrigin: 'Germany',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiODExM2NkOC1iMGEwLTRjMzAtODBjNi1lNTA4NjhmZTY4YjIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWEyZSIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWEyZSIsImVtYWlsIjoiZm9sekBvcnRzY2hlaXQuZGUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.w6LL4xLBT4VCF3VbpH1gGnrjQEa7MAbqBfWn5lBGmnQ&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de280012d4ea',
                supplierId: '5f44fe28dfd6c89e1b171a56',
                supplierName: 'Pharmahandel Köhnen',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Peter Köhnen',
                supplierContactEmail: 'pkoehnen@pharmakoehnen.de',
                countryCode: 'DE',
                country: 'Germany',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 360/21, 361/21, 362/21, 363/21, 364/21 - ',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb1fbe04ecb317d0e26dc59',
                        rfqNr: '363/21',
                        name: 'SPERSACARPIN® 2% (20 MG/ML) AUGENTROPFEN 3×10 ML (N3)',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE (S01EB01)',
                        unitQuant: ' 84 packs',
                        packSize: 'Spersacarpin® 2% (20 mg/ml) Augentropfen 3×10 ml (N3)',
                        productCode: '02235173',
                        countryOfOrigin: 'Germany',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1OGZjNjExNS0wNmI4LTRlY2MtODFiNy0xOTcwYzg4ZDU1YjIiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE1NiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE1NiIsImVtYWlsIjoicGtvZWhuZW5AcGhhcm1ha29laG5lbi5kZSIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTE0OTQyOCwiZXhwIjoxNjY1NzU0MjI4LCJpYXQiOjE2NjUxNDk0MjgsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.F0jz2mE4ZDtQrp6tNh5QO2KNlTFtMMPCiISKxqWsEDg&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de2828ad23c1',
                supplierId: '5f44fe28dfd6c89e1b1719ec',
                supplierName: 'Active Pharma',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Manfred Moers',
                supplierContactEmail: 'm.moers@activepharma.de',
                countryCode: 'DE',
                country: 'Germany',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 360/21, 361/21, 362/21, 363/21, 364/21 - ',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.482Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb1fbe04ecb317d0e26dc59',
                        rfqNr: '363/21',
                        name: 'SPERSACARPIN® 2% (20 MG/ML) AUGENTROPFEN 3×10 ML (N3)',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE (S01EB01)',
                        unitQuant: ' 84 packs',
                        packSize: 'Spersacarpin® 2% (20 mg/ml) Augentropfen 3×10 ml (N3)',
                        productCode: '02235173',
                        countryOfOrigin: 'Germany',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYzg0YmNiNC1jYjEwLTQzZTQtODE2Ny1jYzM5MmYwYmUwZTQiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllYyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTllYyIsImVtYWlsIjoibS5tb2Vyc0BhY3RpdmVwaGFybWEuZGUiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.H3xMwf5OJBz4zJvAI6tpI7umPyz62Zsq5yvYJ4tVAnA&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de285cd0ce5c',
                supplierId: '5f44fe28dfd6c89e1b171a54',
                supplierName: 'Stroumsas & Liafarm Pharmaceuticals SA',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: '​​Vicky Theofilou',
                supplierContactEmail: 'vtheo@liafarm.gr',
                countryCode: 'GR',
                country: 'Greece',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 361/21, 362/21, 363/21, 364/21 - DEXAMETHASONE, BETAFERON, EXTAVIA, DISPERCARPINE, URSOFALK',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.483Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f35c4e3dfd6c89e1be67dab',
                        rfqNr: '363/21',
                        name: 'DISPERCARPINE',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE - 20MG/ML (S01EB01)',
                        unitQuant: ' 250 packs',
                        packSize: '1 VIAL * 10 ML',
                        productCode: '05116.05.01',
                        countryOfOrigin: 'Greece',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhZjVlY2RiOC01ZTA3LTRlNzYtOWI0YS0yMDExM2ZiZjVhNjgiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE1NCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE1NCIsImVtYWlsIjoidnRoZW9AbGlhZmFybS5nciIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTE0OTQyOCwiZXhwIjoxNjY1NzU0MjI4LCJpYXQiOjE2NjUxNDk0MjgsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0._DAMoC_PQ7Si79ur0zbPxqe7Z5czf1Os90JPshNIPiA&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de28d0a9f56f',
                supplierId: '5f44fe28dfd6c89e1b171a8c',
                supplierName: 'Sofarmamorra',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Giovanni Celotto',
                supplierContactEmail: 'gcelotto@sofarmamorra.it',
                countryCode: 'IT',
                country: 'Italy',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 361/21, 362/21, 363/21 - DECADRON, BETAFERON, EXTAVIA, PILOCARPINA CLORIDRATO ALLERGAN',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.483Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f2a7322bdeaa9bba367184f',
                        rfqNr: '363/21',
                        name: 'PILOCARPINA CLORIDRATO ALLERGAN',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'Pilocarpina (S01EB01)',
                        unitQuant: ' 250 pcks',
                        packSize: '"2% COLLIRIO, SOLUZIONE"FLACONE 10 ML',
                        productCode: '000248056',
                        countryOfOrigin: 'Italy',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNDlhMjgyOC05MWY2LTQyMTktODAyZC1lOGQ3NjNmNWI2YWYiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE4YyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE4YyIsImVtYWlsIjoiZ2NlbG90dG9Ac29mYXJtYW1vcnJhLml0Iiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1MTQ5NDI4LCJleHAiOjE2NjU3NTQyMjgsImlhdCI6MTY2NTE0OTQyOCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.ddMocz-xOA-Pv6AzSGo1DVmnKpAWNUQoOvK2jlplgpI&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de28edc1bfe4',
                supplierId: '5f44fe28dfd6c89e1b171a74',
                supplierName: 'Petrone',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Ilaria Dalba',
                supplierContactEmail: 'i.dalba@petrone.it',
                countryCode: 'IT',
                country: 'Italy',
                lastIteration: '2021-03-29T12:08:30.395Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 361/21, 362/21, 363/21 - DECADRON, BETAFERON, EXTAVIA, PILOCARPINA CLORIDRATO ALLERGAN',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.483Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f2a7322bdeaa9bba367184f',
                        rfqNr: '363/21',
                        name: 'PILOCARPINA CLORIDRATO ALLERGAN',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'Pilocarpina (S01EB01)',
                        unitQuant: ' 250 pcks',
                        packSize: '"2% COLLIRIO, SOLUZIONE"FLACONE 10 ML',
                        productCode: '000248056',
                        countryOfOrigin: 'Italy',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMzZlMjkzYS0xMThjLTRiNjItODE2NC1jNzljZWU1NjJjMTgiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE3NCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE3NCIsImVtYWlsIjoiaS5kYWxiYUBwZXRyb25lLml0Iiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1MTQ5NDI4LCJleHAiOjE2NjU3NTQyMjgsImlhdCI6MTY2NTE0OTQyOCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.BVVHPJ5tLd1Rnn_3oqsL4onQsTPh4i-u5gOEFnR-NBE&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de298cf2830b',
                supplierId: '5f44fe28dfd6c89e1b171996',
                supplierName: 'Salus International',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Rafał Barylak',
                supplierContactEmail: 'r.barylak@salusint.com.pl',
                countryCode: 'PL',
                country: 'Poland',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21 - BETAFERON, PROSZEK I ROZPUSZCZALNIK DO SPORZĄDZANIA ROZTWORU DO WSTRZYKIWAŃ, 300 ΜG, PILOCARPINUM WZF 2%, KROPLE DO OCZU, ROZTWÓR, 20 MG/ML',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb183bf4ecb317d0e025a45',
                        rfqNr: '363/21',
                        name: 'PILOCARPINUM WZF 2%, KROPLE DO OCZU, ROZTWÓR, 20 MG/ML',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE - 20 mg/ml (S01EB01)',
                        unitQuant: ' 125 packs',
                        packSize: '2 butelki 5 ml',
                        productCode: '5909990237524',
                        countryOfOrigin: 'Poland',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhNDEyYmMxMS1iOWI3LTQ3NTctYTRlNS1hM2MzMzBiOWEyNWEiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTk5NiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTk5NiIsImVtYWlsIjoici5iYXJ5bGFrQHNhbHVzaW50LmNvbS5wbCIsInJvbGUiOiJFeHRlcm5hbCIsIm5iZiI6MTY2NTE0OTQyOCwiZXhwIjoxNjY1NzU0MjI4LCJpYXQiOjE2NjUxNDk0MjgsImlzcyI6IldoaWNoUGhhcm1hLklkZW50aXR5U2VydmljZSIsImF1ZCI6IldoaWNoUGhhcm1hIn0.7Ws5AyvYey1Awt7BcuVEC3pOvwRWuIBeqOSFDXuNV8I&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de29b6d0b0ea',
                supplierId: '5f44fe28dfd6c89e1b1719fc',
                supplierName: 'Cefarm SA',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Julia Dałek',
                supplierContactEmail: 'jdalek@cefarm.com.pl',
                countryCode: 'PL',
                country: 'Poland',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject:
                    'RFQ 362/21, 363/21 - BETAFERON, PROSZEK I ROZPUSZCZALNIK DO SPORZĄDZANIA ROZTWORU DO WSTRZYKIWAŃ, 300 ΜG, PILOCARPINUM WZF 2%, KROPLE DO OCZU, ROZTWÓR, 20 MG/ML',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb183bf4ecb317d0e025a45',
                        rfqNr: '363/21',
                        name: 'PILOCARPINUM WZF 2%, KROPLE DO OCZU, ROZTWÓR, 20 MG/ML',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE - 20 mg/ml (S01EB01)',
                        unitQuant: ' 125 packs',
                        packSize: '2 butelki 5 ml',
                        productCode: '5909990237524',
                        countryOfOrigin: 'Poland',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NjNhNWQ1OC1hZmE1LTQ3MGUtOGIwZi1hYzRjYzY3MjhlZjkiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmYyIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTlmYyIsImVtYWlsIjoiamRhbGVrQGNlZmFybS5jb20ucGwiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.zKZNKBTeIuvEyO_tXXe5UFn4mP0aRSzHGSBVvDCCyHI&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de29f45f4ecc',
                supplierId: '5f44fe28dfd6c89e1b1719b4',
                supplierName: 'Transmedic',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Pavel Ctvrtnicek',
                supplierContactEmail: 'ctvrtnicek@transmedic.sk',
                countryCode: 'SK',
                country: 'Slovakia',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 363/21 - PILOKARPIN 2 %',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb181c54ecb317d0e00c4c4',
                        rfqNr: '363/21',
                        name: 'PILOKARPIN 2 %',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE - 20 MG/ML (S01EB01)',
                        unitQuant: ' 250 packs',
                        packSize: '1 X 10 ML',
                        productCode: '15736',
                        countryOfOrigin: 'Slovakia',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1OWYxZjhhMS0wODM4LTQ0OGItYjlmNi01YmVkODdmNjJhNTEiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTliNCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MTliNCIsImVtYWlsIjoiY3R2cnRuaWNla0B0cmFuc21lZGljLnNrIiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1MTQ5NDI4LCJleHAiOjE2NjU3NTQyMjgsImlhdCI6MTY2NTE0OTQyOCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.-nLun7oYs0jj67Sx-C3MUFgkBjkP5rWBfGGnMn_raF0&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de2a036962af',
                supplierId: '5f44fe2adfd6c89e1b171be6',
                supplierName: 'Magnica Pharma',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Daniel Grzelak',
                supplierContactEmail: 'daniel.grzelak@magnicapharma.com',
                countryCode: 'SK',
                country: 'Slovakia',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 363/21 - PILOKARPIN 2 %',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5eb181c54ecb317d0e00c4c4',
                        rfqNr: '363/21',
                        name: 'PILOKARPIN 2 %',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINE - 20 MG/ML (S01EB01)',
                        unitQuant: ' 250 packs',
                        packSize: '1 X 10 ML',
                        productCode: '15736',
                        countryOfOrigin: 'Slovakia',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMmJmODg4Yi1lMmYxLTQ5M2QtYTdkYi0zMTNjM2UwN2IyMDQiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWJlNiIsIm5hbWVpZCI6IjVmNDRmZTJhZGZkNmM4OWUxYjE3MWJlNiIsImVtYWlsIjoiZGFuaWVsLmdyemVsYWtAbWFnbmljYXBoYXJtYS5jb20iLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.t7ec6RxPq0qP3a5nr-AxFFjt8QIb5N_1gsfaVH1jd1U&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de2a67d10ba7',
                supplierId: '5f44fe28dfd6c89e1b171a3e',
                supplierName: 'Hefame/Hplus',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Angela Carrillo',
                supplierContactEmail: 'angela.garcia@hefame.es',
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 362/21, 363/21 - ',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f295502bdeaa9bba36577b9',
                        rfqNr: '363/21',
                        name: 'COLIRCUSI PILOCARPINA 2%, 1 frasco de 10 ml',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINA HIDROCLORURO - 20 mg (S01EB01)',
                        unitQuant: ' 250 packs',
                        packSize: 'IRCUSI PILOCARPINA 2%, 1 frasco de 10 ml',
                        productCode: '653478',
                        countryOfOrigin: 'Spain',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiYjk5MmZhNy0yZjNlLTQ1ZDEtYmI1NC1iMjk0ZWEyZWRlYzAiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWEzZSIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWEzZSIsImVtYWlsIjoiYW5nZWxhLmdhcmNpYUBoZWZhbWUuZXMiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.OYB8T4Ka_szvSGpufjvZ_ht0uExOJNmcRtTc26aVaa4&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de2a995b6a34',
                supplierId: '5f44fe28dfd6c89e1b171a80',
                supplierName: 'BCN Farma S.L.',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Laura Fornieles',
                supplierContactEmail: 'l.fornieles@bcnfarma.com',
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 362/21, 363/21 - ',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f295502bdeaa9bba36577b9',
                        rfqNr: '363/21',
                        name: 'COLIRCUSI PILOCARPINA 2%, 1 frasco de 10 ml',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINA HIDROCLORURO - 20 mg (S01EB01)',
                        unitQuant: ' 250 packs',
                        packSize: 'IRCUSI PILOCARPINA 2%, 1 frasco de 10 ml',
                        productCode: '653478',
                        countryOfOrigin: 'Spain',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyOWYwMTdiNy1iM2JjLTQxNGYtYTkwMy1kZmI4ZmRjZDEyZDciLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE4MCIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE4MCIsImVtYWlsIjoibC5mb3JuaWVsZXNAYmNuZmFybWEuY29tIiwicm9sZSI6IkV4dGVybmFsIiwibmJmIjoxNjY1MTQ5NDI4LCJleHAiOjE2NjU3NTQyMjgsImlhdCI6MTY2NTE0OTQyOCwiaXNzIjoiV2hpY2hQaGFybWEuSWRlbnRpdHlTZXJ2aWNlIiwiYXVkIjoiV2hpY2hQaGFybWEifQ.7jQcoko-zhnzqaOzjYYZzHgTJvoIjMiH-vLpzGqDo_4&rfqsNrs=363%2f21',
            },
            {
                threadId: '1787de2aba93a146',
                supplierId: '5f44fe28dfd6c89e1b171a96',
                supplierName: 'Euroserv (Grupo Cofares)',
                supplierType: SupplierType.Wholesaler,
                supplierContactName: 'Manuel Delgado López',
                supplierContactEmail: 'mdelgado@euroserv.es',
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2021-03-29T12:08:30.396Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 362/21, 363/21 - ',
                unreadMessages: 0,
                dataTable: [
                    {
                        createdBy: 'joana',
                        creationDate: '2021-03-29T12:08:30.484Z',
                        lastUpdateDate: '0001-01-01T00:00:00Z',
                        id: '5f295502bdeaa9bba36577b9',
                        rfqNr: '363/21',
                        name: 'COLIRCUSI PILOCARPINA 2%, 1 frasco de 10 ml',
                        state: RFQQuoteState.Open,
                        endingDate: '2021-04-01T11:44:36Z',
                        rfqDescription: 'Pilocarpina 20 mg/ml Colírio  (S01EB01)',
                        activeSubstances: 'PILOCARPINA HIDROCLORURO - 20 mg (S01EB01)',
                        unitQuant: ' 250 packs',
                        packSize: 'IRCUSI PILOCARPINA 2%, 1 frasco de 10 ml',
                        productCode: '653478',
                        countryOfOrigin: 'Spain',
                    },
                ],
                externalAccessLink:
                    '/replyform?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYmI4OWNmNC03MTM5LTQ2MzMtYTY2Mi00ZDFjYjhhMjZmMjAiLCJ1bmlxdWVfbmFtZSI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NiIsIm5hbWVpZCI6IjVmNDRmZTI4ZGZkNmM4OWUxYjE3MWE5NiIsImVtYWlsIjoibWRlbGdhZG9AZXVyb3NlcnYuZXMiLCJyb2xlIjoiRXh0ZXJuYWwiLCJuYmYiOjE2NjUxNDk0MjgsImV4cCI6MTY2NTc1NDIyOCwiaWF0IjoxNjY1MTQ5NDI4LCJpc3MiOiJXaGljaFBoYXJtYS5JZGVudGl0eVNlcnZpY2UiLCJhdWQiOiJXaGljaFBoYXJtYSJ9.VWFA1xsxSR-0q6jiuIT7PHuum-BzaJP25IK22H97Xcc&rfqsNrs=363%2f21',
            },
        ],
    },
    {
        rfqNumber: '123/20',
        notes: [],
        suppliersDetails: [
            {
                threadId: 't1',
                countryCode: 'PT',
                country: 'Portugal',
                lastIteration: '2020-06-25T00:25:00.000Z',
                state: RFQEntryState.SupplierWaitingReply,
                subject: 'RFQ 123 - Paracetamol',
                supplierContactEmail: 'john@qualquercoisa.com',
                supplierContactName: 'Jonh',
                supplierId: '123454521',
                supplierName: 'Find the Med',
                supplierType: SupplierType.Wholesaler,
                unreadMessages: 1,
                dataTable: [
                    {
                        id: '1',
                        state: RFQQuoteState.Open,
                        rfqNr: '123/20',
                        name: 'Paracetamol',
                        activeSubstances: 'Paracetamol',
                        countryOfOrigin: 'Portugal',
                        packSize: '30',
                        unitQuant: '10',
                        productCode: '1234',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                ],
            },
            {
                threadId: 't2',
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2020-06-25T00:25:00.000Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 123, 456 - Paracetamol, Bebegel',
                supplierContactEmail: 'otavio@qualquercoisa.com',
                supplierContactName: 'Otávio',
                supplierId: '874532454',
                supplierName: 'Medixx',
                supplierType: SupplierType.Wholesaler,
                unreadMessages: 0,
                dataTable: [
                    {
                        id: '2',
                        state: RFQQuoteState.Open,
                        rfqNr: '123/20',
                        name: 'Paracetamol',
                        activeSubstances: 'Paracetamol',
                        countryOfOrigin: 'Spain',
                        packSize: '30',
                        productCode: '1234',
                        endingDate: '2022-06-25T00:25:00.000Z',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                    {
                        id: '3',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        endingDate: '2022-06-25T00:25:00.000Z',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                    {
                        id: '3',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                    {
                        id: '5',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        endingDate: '2022-06-25T00:25:00.000Z',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                    {
                        id: '6',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        endingDate: '2022-06-25T00:25:00.000Z',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                    {
                        id: '7',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        endingDate: '2022-06-25T00:25:00.000Z',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                ],
            },
            {
                threadId: 't3',
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2020-06-25T00:25:00.000Z',
                state: RFQEntryState.Closed,
                subject: 'RFQ 123, 456 - Paracetamol, Bebegel',
                supplierContactEmail: 'outro.tal@qualquercoisa.com',
                externalAccessLink: '/replyform?rfqsNrs=123&token=coiso',
                supplierContactName: 'Sinhor',
                supplierId: '3453452',
                supplierName: 'Pharmadise',
                supplierType: SupplierType.Wholesaler,
                unreadMessages: 0,
                dataTable: [
                    {
                        id: '4',
                        state: RFQQuoteState.Open,
                        rfqNr: '123/20',
                        name: 'Paracetamol',
                        countryOfOrigin: 'Spain',
                        packSize: '30',
                        unitQuant: '10',
                        productCode: '1234',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                    {
                        id: '5',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                ],
            },
        ],
    },
    {
        rfqNumber: '333/20',
        notes: [],
        suppliersDetails: [
            {
                threadId: 't4',
                countryCode: 'PT',
                country: 'Portugal',
                lastIteration: '2020-06-25T00:25:00.000Z',
                state: RFQEntryState.Closed,
                subject: 'RFQ 333 - Coiso',
                supplierContactEmail: 'coiso@etal.com',
                supplierContactName: 'Richard',
                supplierId: '3454235234',
                supplierName: 'Global',
                supplierType: SupplierType.Wholesaler,
                unreadMessages: 0,
                dataTable: [
                    {
                        id: '6',
                        state: RFQQuoteState.Open,
                        rfqNr: '333',
                        name: 'Coiso',
                        activeSubstances: 'Coiso e tal',
                        countryOfOrigin: 'Portugal',
                        packSize: '100',
                        unitQuant: '10',
                        productCode: '34523',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                ],
            },
        ],
    },
    {
        rfqNumber: '456/20',
        notes: [],
        suppliersDetails: [
            {
                threadId: 't2',
                countryCode: 'ES',
                country: 'Spain',
                lastIteration: '2020-06-25T00:25:00.000Z',
                state: RFQEntryState.Open,
                subject: 'RFQ 123, 456 - Paracetamol, Bebegel',
                supplierContactEmail: 'coiso.tal@qualquercoisa.com',
                supplierContactName: 'Otávio',
                supplierId: '874532454',
                supplierName: 'Medixx',
                supplierType: SupplierType.Wholesaler,
                unreadMessages: 0,
                dataTable: [
                    {
                        id: '7',
                        state: RFQQuoteState.Open,
                        rfqNr: '456/20',
                        name: 'Bebegel',
                        activeSubstances: 'Coiso',
                        countryOfOrigin: 'Spain',
                        packSize: '1',
                        unitQuant: '10',
                        productCode: '34523',
                        createdBy: 'manel',
                        creationDate: '2020-06-25T00:25:00.000Z',
                        updatedBy: 'supplier-asdasdasecawcasecqw',
                        lastUpdateDate: '2020-07-25T00:25:00.000Z',
                    },
                ],
            },
        ],
    },
];
