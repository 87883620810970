import React, { useCallback, useState } from 'react';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';

import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Button,
    Box,
    Chip,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Tooltip,
    makeStyles,
    Popover,
    Theme,
    Typography,
    DialogContentText,
} from '@material-ui/core';
//import {  } from '../../models/ProductV2';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AvailabilityColors, AvailabilityTerms, AvailabilityColorsFront } from '../../constants/general';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkIcon from '@material-ui/icons/Link';

import ReactCountryFlag from 'react-country-flag';
import { CloseIconWrapper, DialogTitleContainer } from '../../pages/ProductsListV3/styled';
import { LogExScrapper } from '../../models/LogExScrapper';
import { LogsScrapperResult } from '../../models/LogsScrapperResult';
import { LogMessage, LogMessageContent, Message } from '../../models/LogMessage';
import { useDispatch } from 'react-redux';
import { AddCommen, DeleteCommen, UpdateComment } from '../../store/Logs2/actions';
import { goToProducts, goToRFQsListV2 } from '../../store/Router/actions';
import { ReportComment } from '../../models/ReportComment';
import { routes } from '../../fetch/routes';
import { AGIconHoc } from '../AGTable/CellFormatter/Hoc/AGIconHoc';
//import ProductContent from '../ProductContent';

interface IReportScrapperDialogProps {
    log?: LogsScrapperResult;
    report: ReportComment;
    handleDialogClose: () => void;

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        lastUpdate: {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '30px',
            fontSize: '0.8rem',
        },
    }),
);

export const ReportScrapperDialog: React.FC<IReportScrapperDialogProps> = ({
    log,
    report,
    handleDialogClose
}) => {
    const t = useTranslations();
    const classes = useStyles();
    const dispatch = useDispatch();
    //const [values, setValues] = React.useState<string[]>([]);


    return (
        <Dialog open={!!log} maxWidth="lg" onClose={handleDialogClose}>
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ fontWeight: 700 }}>{log?.messages?.name}</div>
                </DialogTitleContainer>
                <CloseIconWrapper>
                    <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                        <HighlightOffIcon color="primary" fontSize="large" />
                    </IconButton>
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <br /><span>Execution Time: {report.executionTime}</span>
                <br /><span>Previous load results: {report.previousLoadResults}</span>
                <br /><span>Current load total results: {report.currentLoadTotalResults}</span>
                <br /><span>-------------</span>
                <br /><h5>Changes</h5>
                <br /><span>New Products: {report.newProducts}</span>
                <br /><span>Update Products: {report.updatedProducts}</span>
                <br /><span>Removed Products: {report.removedProducts}</span>
                <br /><span>-------------</span>
                <br />
                <table>
                    {Object.keys(report.properties).map(i => {
                        return (<>
                            <tr>
                                <td>{i[0].toUpperCase() + i.substr(1)}</td>
                                <td>{report.properties[i]}</td>
                            </tr>
                        </>)
                    })}
                </table>
                <br />
                <a href={routes.getReportExcelFile(log?.last.executionId ?? "")}>
                    <DescriptionIcon color="primary" style={{ width: '36px', height:'36px' }} />
                </a>
            </DialogContent>
            
        </Dialog>
    );
};
