import { SearchResult } from '../../models/SearchResult';
import { ProductV2 } from '../../models/ProductV2';
import { ShortageType } from '../../models/ShortageType';

export const productsV2Mock: SearchResult<ProductV2> = {
    items: [
        {
            activeSubstances: ['Benzoylperoxid'],
            atc: 'D10AE01',
            countryCode: 'DE',
            countryName: 'Germany',
            id: '6253d6f18274c7a8ff6e075e',
            isAuthorised: true,
            isMarketed: true,
            lastUpdate: '2022-07-31T16:03:51.338Z',
            maHolder: 'Galderma Laboratorium GmbH',
            name: 'A. Vogel ArnicaforceMed Forte',
            package: '15 Gramm',
            packageDetailed: '1 x 15 g',
            pharmaceuticalForm: 'Gel',
            pharmaceuticalFormCategories: ['Concentrate for solution for', 'Gastro-resistant capsule, hard'],
            prices: [{ type: 'Retail', value: 7.63, currencyCode: 'EUR', includeVAT: false }],
            productCode: '15250547',
            scrapingOrigin: 'rote-liste.de',
            scrapingOriginIdentifier: '15250547',
            strength: '50 mg/g',
        },
        {
            activeSubstances: ['Dexmedetomidina'],
            administrationCategories: [],
            administrationRoute: 'Via intravenosa',
            atc: 'L01FX14 POLATUZUMAB VEDOTIN',
            codes: {
                CNPEM: '0',
                CHNM: '10092981',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952aa0c28ce25d65d1c95c',
            isAdditionalMonitoring: false,
            isAuthorised: false,
            isBiological: false,
            isGeneric: false,
            isHospitalar: true,
            isMarketed: false,
            isPrescription: true,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:39:27.167Z',
            maHolder: 'Not applicable',
            name: 'Precedex 100 µg/ml 5 unidade(s) Solução injetável',
            package: '5 unidade(s)',
            packageDetailed: '1 x 5 U',
            pharmaceuticalForm: 'Solução injetável',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-',
            strength: '100 µg/ml',
        },
        {
            activeSubstances: ['Galium molugo'],
            administrationCategories: ['Oral'],
            administrationRoute: 'Via oral',
            codes: {
                MANumber: '5/84/84',
                ProductId: '2000495',
                CNPEM: '0',
                CHNM: '0',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952e36c28ce25d65d2e65a',
            isAdditionalMonitoring: false,
            isAuthorised: false,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: false,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:33:13.498Z',
            maHolder: 'Paracélsia - Indústria Farmacêutica, S.A.',
            name: 'Acolema 1 mg/ml 6 unidade(s) Solução oral',
            package: '6 unidade(s)',
            packageDetailed: '1 x 6 U',
            pharmaceuticalForm: 'Solução oral',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2000495',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2000495',
            strength: '1 mg/ml',
        },
        {
            activeSubstances: ['Hidróxido de magnésio'],
            administrationCategories: ['Oral'],
            administrationRoute: 'Via oral',
            atc: 'A02AA04 MAGNESIUM HYDROXIDE',
            codes: {
                MANumber: '16/6/22',
                ProductId: '2000990',
                CNPEM: '50077724',
                CHNM: '10081068',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952fb1c28ce25d65d33fed',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: true,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:36:15.709Z',
            maHolder: 'Perrigo Portugal, Lda.',
            name: 'Leite Magnesia Phillips 83 mg/ml 1 unidade(s) Suspensão oral',
            package: '1 unidade(s)',
            packageDetailed: '1 x 1 U',
            pharmaceuticalForm: 'Suspensão oral',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2000990',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2000990',
            strength: '83 mg/ml',
        },
        {
            activeSubstances: ['Nafazolina'],
            administrationCategories: ['Nasal'],
            administrationRoute: 'Via nasal',
            atc: 'R01AA08 NAPHAZOLINE',
            codes: {
                MANumber: '5/27/85',
                ProductId: '2001295',
                CNPEM: '0',
                CHNM: '0',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952addc28ce25d65d1e2ab',
            isAdditionalMonitoring: false,
            isAuthorised: false,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: false,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:23:04.477Z',
            maHolder: 'Laboratórios Azevedos - Indústria Farmacêutica, S.A.',
            name: 'Rinal 1 mg/ml 1 unidade(s) Gotas nasais, solução',
            package: '1 unidade(s)',
            packageDetailed: '1 x 1 U',
            pharmaceuticalForm: 'Gotas nasais, solução',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2001295',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2001295',
            strength: '1 mg/ml',
        },
        {
            activeSubstances: ['Ácido acetilsalicílico'],
            administrationCategories: ['Oral'],
            administrationRoute: 'Via oral',
            atc: 'N02BA01 ACETYLSALICYLIC ACID',
            codes: {
                MANumber: '6/41/79',
                ProductId: '2002194',
                CNPEM: '50002813',
                CHNM: '10023773',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952b51c28ce25d65d21409',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: false,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:24:58.061Z',
            maHolder: 'Bayer Portugal, Lda.',
            name: 'Aspirina 500 mg 4 unidade(s) Comprimido',
            package: '4 unidade(s)',
            packageDetailed: '1 x 4 U',
            pharmaceuticalForm: 'Comprimido',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2002194',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2002194',
            strength: '500 mg',
        },
        {
            activeSubstances: ['Glicerol'],
            administrationCategories: [],
            administrationRoute: 'Via retal',
            atc: 'A06AX01 GLYCEROL',
            codes: {
                MANumber: '1/86/74',
                ProductId: '2003093',
                CNPEM: '50143816',
                CHNM: '10114447',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952e1fc28ce25d65d2e1ab',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: true,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:33:04.69Z',
            maHolder: 'BGP Products, Unipessoal Lda.',
            name: 'Bebegel 3830 mg/4.5 g 6 unidade(s) Gel retal',
            package: '6 unidade(s)',
            packageDetailed: '1 x 6 U',
            pharmaceuticalForm: 'Gel retal',
            pharmaceuticalFormCategories: ['Gel'],
            prices: [],
            productCode: '2003093',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2003093',
            strength: '3830 mg/4.5 g',
        },
        {
            activeSubstances: [''],
            administrationCategories: ['Oral'],
            administrationRoute: 'Via oral',
            atc: 'A03B BELLADONNA AND DERIVATIVES, PLAIN',
            codes: {
                MANumber: '5/153',
                ProductId: '2003291',
                CNPEM: '0',
                CHNM: '0',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952bb3c28ce25d65d23edd',
            isAdditionalMonitoring: false,
            isAuthorised: false,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: false,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:26:32.109Z',
            maHolder: '   Kevel - Produtos Farmacêuticos, Lda.',
            name: 'Belagastrina Associação 1 unidade(s) Pó oral',
            package: '1 unidade(s)',
            packageDetailed: '1 x 1 U',
            pharmaceuticalForm: 'Pó oral',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2003291',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2003291',
            strength: 'Associação',
        },
        {
            activeSubstances: ['Difenidramina'],
            administrationCategories: [],
            administrationRoute: 'Uso cutâneo',
            atc: 'D04AA32 DIPHENHYDRAMINE',
            codes: {
                MANumber: '5/156/84',
                ProductId: '2003390',
                CNPEM: '50066277',
                CHNM: '10053282',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952d24c28ce25d65d2a8a9',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: false,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:30:58.686Z',
            maHolder: 'Generis Farmacêutica, S.A.',
            name: 'Benaderma 20 mg/g 1 unidade(s) Gel',
            package: '1 unidade(s)',
            packageDetailed: '1 x 1 U',
            pharmaceuticalForm: 'Gel',
            pharmaceuticalFormCategories: ['Gel'],
            prices: [],
            productCode: '2003390',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2003390',
            strength: '20 mg/g',
        },
        {
            activeSubstances: ['Difenidramina'],
            administrationCategories: [],
            administrationRoute: 'Uso cutâneo',
            atc: 'D04AA32 DIPHENHYDRAMINE',
            codes: {
                MANumber: '5/156/84',
                ProductId: '2003499',
                CNPEM: '50066269',
                CHNM: '10053275',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952d24c28ce25d65d2a8ab',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: true,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:30:58.687Z',
            maHolder: 'Generis Farmacêutica, S.A.',
            name: 'Benaderma 20 mg/g 1 unidade(s) Gel',
            package: '1 unidade(s)',
            packageDetailed: '1 x 1 U',
            pharmaceuticalForm: 'Gel',
            pharmaceuticalFormCategories: ['Gel'],
            prices: [],
            productCode: '2003499',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2003499',
            strength: '20 mg/g',
        },
        {
            activeSubstances: ['Ácido salicílico'],
            administrationCategories: [],
            administrationRoute: 'Uso cutâneo',
            atc: 'D11AF WART AND ANTI-CORN PREPARATIONS',
            codes: {
                MANumber: '5/19/83',
                ProductId: '2005692',
                CNPEM: '50134019',
                CHNM: '10111458',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952ea5c28ce25d65d2fc18',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: true,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:34:03.211Z',
            maHolder: 'Farmácia Moreno, Lda',
            name: 'Calicida Moreno 83 mg/ml 1 unidade(s) Solução cutânea',
            package: '1 unidade(s)',
            packageDetailed: '1 x 1 U',
            pharmaceuticalForm: 'Solução cutânea',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2005692',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2005692',
            strength: '83 mg/ml',
        },
        {
            activeSubstances: [''],
            administrationCategories: ['Oral'],
            administrationRoute: 'Via oral',
            atc: 'A02AB02 ALGELDRATE',
            codes: {
                MANumber: '5/58',
                ProductId: '2006393',
                CNPEM: '0',
                CHNM: '0',
            },
            countryCode: 'PT',
            countryName: 'Portugal',
            id: '62952be8c28ce25d65d25324',
            isAdditionalMonitoring: false,
            isAuthorised: false,
            isGeneric: false,
            isHospitalar: false,
            isMarketed: false,
            isPrescription: false,
            isPsychotropic: false,
            lastUpdate: '2022-06-01T21:27:20.522Z',
            maHolder: 'Distrifarma - Companhia de Distribuição Farmacêutica, S.A.',
            name: 'Cremase Associação 25 unidade(s) Solução oral',
            package: '25 unidade(s)',
            packageDetailed: '1 x 25 U',
            pharmaceuticalForm: 'Solução oral',
            pharmaceuticalFormCategories: [],
            prices: [],
            productCode: '2006393',
            scrapingOrigin: 'mdb',
            scrapingOriginIdentifier: 'MDB-2006393',
            strength: 'Associação',
        },
        {
            activeSubstances: ['Benzoylperoxid'],
            atc: 'D10AE01',
            countryCode: 'DE',
            countryName: 'Germany',
            id: '6253d6f1338274c7a8ff6e075e',
            isAuthorised: true,
            lastUpdate: '2022-07-31T16:03:51.338Z',
            maHolder: 'Galderma Laboratorium GmbH',
            name: 'A. Vogel ArnicaforceMed Forte',
            package: '15 Gramm',
            packageDetailed: '1 x 15 g',
            pharmaceuticalForm: 'Gel',
            pharmaceuticalFormCategories: ['Concentrate for solution for', 'Gastro-resistant capsule, hard'],
            prices: [{ type: 'Retail', value: 7.63, currencyCode: 'EUR', includeVAT: false }],
            productCode: '15250547',
            scrapingOrigin: 'rote-liste.de',
            scrapingOriginIdentifier: '15250547',
            strength: '50 mg/g',
        },
        {
            activeSubstances: ['Dexamethasone Sodium Phosphate'],
            administrationCategories: ['Intraarticular', 'Intrabursal', 'Intramuscular', 'Intravenous', 'Rectal'],
            administrationRoute: 'Intravenous use, Intramuscular use, Rectal use, Intraarticular use, Intrabursal use',
            atc: 'H02AB02',
            codes: {
                CnkCode: '4102968',
                MANumber: 'BE080026',
                GTIN: '05060598251463',
            },
            countryCode: 'BE',
            countryName: 'Belgium',
            documents: {
                Web: 'https://banquededonneesmedicaments.afmps-fagg.be/#/query/human//medicinal-product/080026-00',
                PIL_FR:
                    'https://bijsluiters.fagg-afmps.be/registrationSearchServlet?key=BE080026&leafletType=leafletFR',
                PIL_NL:
                    'https://bijsluiters.fagg-afmps.be/registrationSearchServlet?key=BE080026&leafletType=leafletNL',
                PIL_DE:
                    'https://bijsluiters.fagg-afmps.be/registrationSearchServlet?key=BE080026&leafletType=leafletDE',
                SPC_FR: 'https://bijsluiters.fagg-afmps.be/registrationSearchServlet?key=BE080026&leafletType=rcp',
                SPC_NL: 'https://bijsluiters.fagg-afmps.be/registrationSearchServlet?key=BE080026&leafletType=skp',
            },
            id: '6329d97966f48287ff24e17e',
            isAdditionalMonitoring: false,
            isAuthorised: true,
            isMarketed: true,
            isParallelImport: false,
            isPrescription: true,
            lastUpdate: '2022-05-26T17:43:30.036Z',
            maHolder: 'Aspen Pharma Trading ',
            name: 'Aacidexam',
            originalName: 'Aacidexam',
            package: '10 x 1 ml',
            packageDetailed: '10 x 1 ml',
            pharmaceuticalForm: 'Solution for injection',
            pharmaceuticalFormCategories: ['Solution'],
            prices: [
                {
                    type: 'Retail',
                    value: 19.94,
                    currencyCode: 'EUR',
                    includeVAT: true,
                },
            ],
            productCode: '080026-03',
            scrapingOrigin: 'banquededonneesmedicaments.afmps-fagg.be/api',
            scrapingOriginIdentifier: '080026-03',
            shortageInfo: {
                start: '2022-04-04T00:00:00Z',
                end: '2022-05-27T00:00:00Z',
                isActive: false,
                type: ShortageType.Partial,
                additionalNotes: 'Import possible by the pharmacist',
                lastUpdate: '2022-05-26T21:03:33.198Z',
                reason: 'Delay in production',
            },
            strength: '5 mg/ml',
        },
        {
            id: '5f77009f394750af1da8dddd',
            scrapingOriginIdentifier: 'EU/1/15/1010/048',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.206Z',
            originalName: '0,9% SODIUM CHLORIDE IN WATER FOR INJECTION "FRESENIUS"',
            name: '0,9% SODIUM CHLORIDE IN WATER FOR INJECTION "FRESENIUS"',
            countryCode: 'CZ',
            countryName: 'Czech Republic',
            productCode: 'EU/1/15/1010/048',
            atc: 'N06AX21',
            activeSubstances: [],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '30 mg',
            package: '98 (PVH/PE/PVDH/Al blisteris, N98)',
            isAuthorised: true,
            isMarketed: true,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            isAdditionalMonitoring: true,
            isBiological: true,
            isParallelImport: true,
            isPsychotropic: true,
            isHospitalar: true,
            isPrescription: true,
            isGeneric: true,
            precautionsForStorage: '20-60',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/048',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/048/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/048/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/048',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/048',
                GTIN: '154333223432',
            },
            prices: [
                { currencyCode: 'EUR', includeVAT: true, type: 'retail', value: 20 },
                { currencyCode: 'EUR', includeVAT: false, type: 'retail', value: 15 },
                { currencyCode: 'USD', includeVAT: false, type: 'factory', value: 5 },
            ],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '30 mg',
            packageDetailed: '98',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
            shortageInfo: {
                start: '2020-06-25T00:25:00.000',
                end: '2020-10-25T00:25:00.000',
                isActive: true,
                type: ShortageType.Permanent,
                additionalNotes: 'Cenas que acontecem',
                lastUpdate: '2020-06-16T09:25:00.000',
            },
        },
        {
            id: '5f77009f394750af1da8dddcd',
            scrapingOriginIdentifier: 'EU/1/15/1010/048',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.206Z',
            originalName: 'Duloxetine Mylan 98 blisters',
            name: 'Duloxetine Mylan',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1010/048',
            atc: 'N06AX21',
            activeSubstances: [],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '30 mg',
            package: '98 (PVH/PE/PVDH/Al blisteris, N98)',
            isAuthorised: true,
            isMarketed: true,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            isAdditionalMonitoring: false,
            isBiological: true,
            isParallelImport: true,
            isPsychotropic: true,
            isHospitalar: true,
            isPrescription: true,
            isGeneric: true,
            precautionsForStorage: '20-60',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/048',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/048/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/048/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/048',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/048',
            },
            prices: [
                { currencyCode: 'EUR', includeVAT: true, type: 'retail', value: 20 },
                { currencyCode: 'EUR', includeVAT: false, type: 'retail', value: 15 },
                { currencyCode: 'USD', includeVAT: false, type: 'factory', value: 5 },
            ],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '30 mg',
            packageDetailed: '98',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
            shortageInfo: {
                start: '2020-06-25T00:25:00.000',
                end: '2020-10-25T00:25:00.000',
                isActive: true,
                type: ShortageType.Permanent,
                additionalNotes: 'Cenas que acontecem',
                lastUpdate: '2020-06-16T09:25:00.000',
            },
        },
        {
            id: '5f77009f394750af1da8dde0',
            scrapingOriginIdentifier: 'EU/1/15/1010/049',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.255Z',
            originalName: 'Duloxetine Mylan 14 blisters',
            name: 'Duloxetine Mylan',
            countryCode: 'BG',
            countryName: 'Bulgaria',
            productCode: 'EU/1/15/1010/049',
            atc: 'N06AX21',
            activeSubstances: ['Duloxetinum'],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '60 mg',
            package: '14 (PVH/PE/PVDH/Al blisteris, N14)',
            isAuthorised: true,
            isMarketed: false,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/049',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/049/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/049/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/049',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/049',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '60 mg',
            packageDetailed: '14',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8dde2',
            scrapingOriginIdentifier: 'EU/1/15/1010/050',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.255Z',
            originalName: 'Duloxetine Mylan',
            name: 'Duloxetine Mylan',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1010/050',
            atc: 'N06AX21',
            activeSubstances: ['Duloxetinum'],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '60 mg',
            package: '28 (PVH/PE/PVDH/Al blisteris, N28)',
            isAuthorised: false,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/050',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/050/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/050/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/050',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/050',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '60 mg',
            packageDetailed: '28',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f7700a4394750af1da8e033',
            scrapingOriginIdentifier: 'EU/1/17/1213/001',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.286Z',
            originalName: 'Maviret',
            name: 'Maviret',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/17/1213/001',
            atc: 'J05AP57',
            activeSubstances: [
                'Glecaprevirum',
                'Pibrentasvirum',
                'super substance',
                'test substance',
                'substance again',
            ],
            pharmaceuticalForm: 'Film-coated tablet',
            strength: '100 mg/40 mg',
            package: '84 (PVH/PE/PHTFE/alumīnija blisteris, N84)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'AbbVie Deutschland GmbH & Co. KG, Germany',
            manufacturer: 'AbbVie Deutschland GmbH & Co. KG, Germany; AbbVie Logistics B.V., Netherlands',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/17/1213/001',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/17/1213/001/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/17/1213/001/smpc',
            },
            codes: {
                MANumber: 'EU/1/17/1213/001',
                Procedure: 'EMEA/H/C/004430',
                ProductId: 'EU/1/17/1213/001',
            },
            prices: [
                {
                    type: 'Retail',
                    value: 19265.76,
                    currencyCode: 'EUR',
                    includeVAT: true,
                },
                {
                    type: 'Retail',
                    value: 17201.57,
                    currencyCode: 'EUR',
                    includeVAT: false,
                },
            ],
            otherFields: {
                'Authorisation date': '26-JUL-17',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '100 mg + 40 mg',
            packageDetailed: '84',
            pharmaceuticalFormCategories: ['Coated', 'Category_Solid', 'Film', 'Tablet', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8dde4',
            scrapingOriginIdentifier: 'EU/1/15/1010/051',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Duloxetine Mylan',
            name: 'Duloxetine Mylan',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1010/051',
            atc: 'N06AX21',
            activeSubstances: [],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '60 mg',
            package: '28 (PVH/PE/PVDH/Al blisteris, N28)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/051',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/051/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/051/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/051',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/051',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '60 mg',
            packageDetailed: '28',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8dde6',
            scrapingOriginIdentifier: 'EU/1/15/1010/052',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Duloxetine Mylan',
            name: 'Duloxetine Mylan',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1010/052',
            atc: 'N06AX21',
            activeSubstances: [],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '60 mg',
            package: '49 (PVH/PE/PVDH/Al blisteris, N49)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/052',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/052/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/052/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/052',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/052',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '60 mg',
            packageDetailed: '49',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8dde8',
            scrapingOriginIdentifier: 'EU/1/15/1010/053',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Duloxetine Mylan',
            name: 'Duloxetine Mylan',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1010/053',
            atc: 'N06AX21',
            activeSubstances: [],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '60 mg',
            package: '98 (PVH/PE/PVDH/Al blisteris, N98)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/053',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/053/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/053/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/053',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/053',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '60 mg',
            packageDetailed: '98',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddea',
            scrapingOriginIdentifier: 'EU/1/15/1010/054',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Duloxetine Mylan',
            name: 'Duloxetine Mylan',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1010/054',
            atc: 'N06AX21',
            activeSubstances: ['Duloxetinum'],
            pharmaceuticalForm: 'Gastro-resistant capsule, hard',
            strength: '60 mg',
            package: '98 (PVH/PE/PVDH/Al blisteris, N98)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Mylan S.A.S, France',
            manufacturer:
                'McDermott Laboratories Limited t/a Gerard Laboratories t/a Mylan Dublin, Ireland; Mylan BV, Netherlands; Mylan Hungary Kft., Hungary',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1010/054',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/054/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1010/054/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1010/054',
                Procedure: 'EMEA/H/C/003981',
                ProductId: 'EU/1/15/1010/054',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '60 mg',
            packageDetailed: '98',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddec',
            scrapingOriginIdentifier: 'EU/1/15/1011/001',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/001',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '14 (PVH/PVDH/Al blisteris, N14)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/001',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/001/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/001/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/001',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/001',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '14',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddee',
            scrapingOriginIdentifier: 'EU/1/15/1011/002',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/002',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '28 (PVH/PVDH/Al blisteris, N28)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/002',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/002/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/002/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/002',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/002',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '28',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddf0',
            scrapingOriginIdentifier: 'EU/1/15/1011/003',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/003',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '56 (PVH/PVDH/Al blisteris, N56)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/003',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/003/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/003/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/003',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/003',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '56',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddf2',
            scrapingOriginIdentifier: 'EU/1/15/1011/004',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/004',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '70 (PVH/PVDH/Al blisteris, N70)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/004',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/004/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/004/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/004',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/004',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '70',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddf4',
            scrapingOriginIdentifier: 'EU/1/15/1011/005',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.256Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/005',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '84 (PVH/PVDH/Al blisteris, N84)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/005',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/005/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/005/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/005',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/005',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '84',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddf6',
            scrapingOriginIdentifier: 'EU/1/15/1011/006',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.257Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/006',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '100 (PVH/PVDH/Al blisteris, N100)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/006',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/006/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/006/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/006',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/006',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '100',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddf8',
            scrapingOriginIdentifier: 'EU/1/15/1011/007',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.257Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/007',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '120 (PVH/PVDH/Al blisteris, N120)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/007',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/007/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/007/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/007',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/007',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '120',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddfa',
            scrapingOriginIdentifier: 'EU/1/15/1011/008',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.257Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/008',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '56 (PVH/PVDH/Al dozējamu vienību blisteris, N56)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/008',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/008/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/008/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/008',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/008',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '56',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddfc',
            scrapingOriginIdentifier: 'EU/1/15/1011/009',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/009',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '84 (PVH/PVDH/Al dozējamu vienību blisteris, N84)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/009',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/009/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/009/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/009',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/009',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '84',
            pharmaceuticalFormCategories: [],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8ddfe',
            scrapingOriginIdentifier: 'EU/1/15/1011/010',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/010',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '25 mg',
            package: '100 (PVH/PVDH/Al dozējamu vienību blisteris, N100)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/010',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/010/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/010/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/010',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/010',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '25 mg',
            packageDetailed: '100',
            pharmaceuticalFormCategories: [],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de00',
            scrapingOriginIdentifier: 'EU/1/15/1011/011',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/011',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '21 (PVH/PVDH/Al blisteris, N21)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/011',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/011/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/011/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/011',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/011',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '21',
            pharmaceuticalFormCategories: [],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de02',
            scrapingOriginIdentifier: 'EU/1/15/1011/012',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/012',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '28 (PVH/PVDH/Al blisteris, N28)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/012',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/012/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/012/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/012',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/012',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '28',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de04',
            scrapingOriginIdentifier: 'EU/1/15/1011/013',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/013',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '56 (PVH/PVDH/Al blisteris, N56)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/013',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/013/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/013/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/013',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/013',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '56',
            pharmaceuticalFormCategories: [],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de06',
            scrapingOriginIdentifier: 'EU/1/15/1011/014',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/014',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '84 (PVH/PVDH/Al blisteris, N84)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/014',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/014/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/014/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/014',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/014',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '84',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de0a',
            scrapingOriginIdentifier: 'EU/1/15/1011/015',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/015',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '100 (PVH/PVDH/Al blisteris, N100)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/015',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/015/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/015/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/015',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/015',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '100',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de0c',
            scrapingOriginIdentifier: 'EU/1/15/1011/016',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/016',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '84 (PVH/PVDH/Al dozējamu vienību blisteris, N84)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/016',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/016/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/016/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/016',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/016',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '84',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de0e',
            scrapingOriginIdentifier: 'EU/1/15/1011/017',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.258Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/017',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '50 mg',
            package: '14 (PVH/PVDH/Al blisteris, N14)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/017',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/017/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/017/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/017',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/017',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '50 mg',
            packageDetailed: '14',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
        {
            id: '5f77009f394750af1da8de10',
            scrapingOriginIdentifier: 'EU/1/15/1011/018',
            scrapingOrigin: 'zva.gov.lv',
            lastUpdate: '2020-10-02T10:27:41.259Z',
            originalName: 'Pregabalin Sandoz',
            name: 'Pregabalin Sandoz',
            countryCode: 'LV',
            countryName: 'Latvia',
            productCode: 'EU/1/15/1011/018',
            atc: 'N03AX16',
            activeSubstances: ['Pregabalinum'],
            pharmaceuticalForm: 'Capsule, hard',
            strength: '75 mg',
            package: '14 (PVH/PVDH/Al blisteris, N14)',
            isAuthorised: true,
            statusNotes: 'Registered',
            maHolder: 'Sandoz GmbH, Austria',
            manufacturer: 'Lek Pharmaceuticals d.d., Slovenia',
            documents: {
                Web: 'https://www.zva.gov.lv/zvais/zalu-registrs/en/info/EU/1/15/1011/018',
                PIL: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/018/pil',
                SPC: 'https://www.zva.gov.lv/zvais/zalu-registrs/info/EU/1/15/1011/018/smpc',
            },
            codes: {
                MANumber: 'EU/1/15/1011/018',
                Procedure: 'EMEA/H/C/004010',
                ProductId: 'EU/1/15/1011/018',
            },
            prices: [],
            otherFields: {
                'Authorisation date': '19-JUN-15',
                'Authorisation Procedure': 'Eiropas centralizētā reģistrācijas procedūra',
            },
            strengthDetailed: '75 mg',
            packageDetailed: '14',
            pharmaceuticalFormCategories: ['Category_Solid', 'Capsule', 'Hard', 'Blister'],
            administrationCategories: [],
        },
    ],
    total: 28895,
    timeInSeconds: 0.58,
};
