import { Search } from 'history';
import { ApplicationState } from '..';
import { ProcessingPformsSettings } from '../../models/ProcessingPformsSettings';
import { LogsScrapper } from '../../models/LogsScrapper';
import { ProcessingSettings } from '../../models/ProcessingSettings';
import { SearchResult } from '../../models/SearchResult';
import { LogExScrapper } from '../../models/LogExScrapper';
import { ReportComment } from '../../models/ReportComment';
import { Sources } from '../../models/Sources';

export const isLoadingLogsData = (state: ApplicationState): boolean => state.logs.isLoading;
export const getScrapperLogs = (state: ApplicationState): LogsScrapper => state.logs.logs;
export const getReportLog = (state: ApplicationState): any => state.logs.reportLog;
export const getSourceLog = (state: ApplicationState): { [key: string]: Sources } => state.logs.sources;
export const getScrapperLogsHistory = (state: ApplicationState): LogExScrapper[] => state.logs.logsHistory;
export const getPlatformPformProcessingSettings = (state: ApplicationState): { [country: string]: ProcessingSettings | SearchResult<ProcessingPformsSettings> } => state.platform.processingSettings ;
