import * as React from 'react';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import Panel, { PanelButtonsContainer } from '../../../components/Panel';
import { AppBar, Tabs, Tab, Button, Typography, Grid, Box } from '@material-ui/core';

import { useCountriesSet, useOriginsSet } from '../../../store/Sets/hooks';
import { useBackupSet } from '../../../store/SetsV2/hooks';
import SingleSelectInput from '../../../components/inputs/SingleSelectInput';
import { AdministrationForm } from '../../../models/AdministrationForm';
import { PharmaUnits } from '../../../models/PharmaUnits';
import { DrugForm } from '../../../models/DrugForm';
import {
    getPlatformPformProcessingSettings,
    getPlatformProcessingSettings,
    isLoadingPlatformData,
} from '../../../store/Platform/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchPformPlatformProcessingSettings,
    savePlatformProcessingSettings,
    sendReprocessRequest,
    sendRestoreFromBackup,
} from '../../../store/Platform/actions';

import { ProcessingSettings } from '../../../models/ProcessingSettings';
import { MapOf } from '../../../utils/Types';

import PFormMappingTable from './PFormMappingTable';
import { removeEmptyArrayFieldsAndUpdateMetadata } from '../../../utils/utils';
import FormDialog from '../../../components/FormDialog';

import { SearchResult } from '../../../models/SearchResult';
import { ProcessingPformsSettings } from '../../../models/ProcessingPformsSettings';
import CountryFlag from '../../../components/CountryFlag';
import { Country } from '../../../models/Country';

import ATCMappingTable from './ATCMappingTable';
import Loading from '../../../components/Loading';
import ActiveIngMappingTable from './ActiveIngMappingTable';
import PFormFullListMappingTable from './PFormFullListMappingTable';
import ActiveSubFullListMappingTable from './ActiveSubFullListMappingTable';
import { getMyUser, getUser } from '../../../store/Users/selectors';

const getMappings = (values?: ProcessingSettings): ProcessingSettings => ({
    administrationFormsMap: Object.keys(AdministrationForm)
        .filter((v) => typeof v === 'string' && v !== 'None')
        .map((key) => ({ [key]: values?.administrationFormsMap[key] || [] }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    drugFormsMap: Object.keys(DrugForm)
        .filter((v) => typeof v === 'string' && v !== 'None')
        .map((key) => ({ [key]: values?.drugFormsMap[key] || [] }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    pharmaUnitsMap: Object.keys(PharmaUnits)
        .filter((v) => typeof v === 'string' && v !== 'None')
        .map((key) => ({ [key]: values?.pharmaUnitsMap[key] || [] }))
        .reduce((prev, curr) => Object.assign(prev, curr), {}),
    administrationFormPresenceFields: values?.administrationFormPresenceFields || [],
    drugFormPresenceFields: values?.drugFormPresenceFields || [],
    packagePresenceFields: values?.packagePresenceFields || [],
    catsMap: {},
    atcMap: {},
    drugFormsMap2: {},
    dciFullMap: {},
    dciMap: {},
    strengthPresenceFields: values?.strengthPresenceFields || [],
    culture: values?.culture || '',
    decimalSeparator: values?.decimalSeparator || '',
    numberGroupSeparator: values?.numberGroupSeparator || '',
});

const productFields = ['name', 'package', 'strength', 'drugForm', 'administrationForm', 'notes'];

const ProcessingSettingsPanel: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const mappingsFromServer = useSelector(getPlatformProcessingSettings);
    const mappingsFromServerPf = useSelector(getPlatformPformProcessingSettings);
    const isLoading = useSelector(isLoadingPlatformData);
    const user = useSelector(getMyUser);
    const countriesList = useCountriesSet();
    const origins = useOriginsSet();
    const backups = useBackupSet();

    const [origin, setOrigin] = React.useState<string | null | undefined>(undefined);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [tab, setTab] = React.useState(0);
    const [values, setValues] = React.useState<ProcessingSettings>(getMappings());
    const [isShowFullList, setIsShowFullList] = React.useState<boolean>(false);
    const [valuesPforms, setPformsValues] = React.useState<SearchResult<ProcessingPformsSettings>>({
        items: [],
        total: 0,
    });
    const [valuesPforms2, setPformsValues2] = React.useState<MapOf<ProcessingPformsSettings>>({});
    const [processProductsDialog, setProcessProductsDialog] = React.useState(false);
    const [copyDialog, setCopyDialog] = React.useState(false);

    const handleMappingForDrug = React.useCallback(
        (propertyName: string) => (newValues: MapOf<ProcessingPformsSettings>) => {
            setChanged(true);
            // setPformsValues2(newValues);
            values.drugFormsMap2 = newValues;
            setValues(values);
        },
        [setChanged, setPformsValues2, setValues, values],
    );

    const handleMappingForActiveIngred = React.useCallback(
        (propertyName: string) => (newValues: MapOf<ProcessingPformsSettings>) => {
            setChanged(true);
            //setPformsValues2(newValues);
            values.dciMap = newValues;
            setValues(values);
        },
        [setChanged, setPformsValues2, setValues, values],
    );

    const handleFieldsValueChange = React.useCallback(
        (propertyName: string) => (newValues: any) => {
            setChanged(true);
            setValues((prev) => ({
                ...prev,
                [propertyName]: newValues,
            }));
        },
        [setChanged, setValues],
    );

    const handleSave = React.useCallback(() => {
        origin &&
            dispatch(
                savePlatformProcessingSettings(origin, {
                    drugFormsMap2: removeEmptyArrayFieldsAndUpdateMetadata(values.drugFormsMap2, user!.username),
                    atcMap: removeEmptyArrayFieldsAndUpdateMetadata(values.atcMap, user!.username),
                    dciMap: removeEmptyArrayFieldsAndUpdateMetadata(values.dciMap, user!.username),
                    culture: tab == 0 ? 'Pharmaceutical Form' : tab == 1 ? 'DCI' : '',
                } as any),
            );
    }, [dispatch, tab, origin, values]);

    const handleOriginChange = React.useCallback(
        (countryObject: Country) => {
            let origin = countryObject ? countryObject.alpha2Code : null;

            setValues(getMappings());
            origin && dispatch(fetchPformPlatformProcessingSettings(origin));
            setOrigin(origin);

            //setValues(fetcheddata);
        },
        [setOrigin, dispatch],
    );

    React.useEffect(() => {
        origin && mappingsFromServer[origin] && setValues(mappingsFromServer[origin] as ProcessingSettings);

        //origin && mappingsFromServerPf[origin] && setPformsValues(mappingsFromServerPf[origin] as SearchResult<ProcessingPformsSettings>);
        if (origin != undefined) {
            var pf = mappingsFromServerPf[origin] as ProcessingSettings;
            //setPformsValues(new { items: pf.drugFormsMap2, total: Object.keys(pf.drugFormsMap2).length } as SearchResult<ProcessingPformsSettings>)
            setPformsValues2(pf.drugFormsMap2);
        }
        setChanged(false);
        // eslint-disable-next-line
    }, [setChanged, mappingsFromServer, setPformsValues, setPformsValues2, setValues]);

    const openProcessProductsDialog = React.useCallback(() => setProcessProductsDialog(true), [
        setProcessProductsDialog,
    ]);

    const closeProcessProductsDialog = React.useCallback(() => setProcessProductsDialog(false), [
        setProcessProductsDialog,
    ]);

    const showFullList = React.useCallback(() => setIsShowFullList(true), [setIsShowFullList]);

    const showContentByTab = (tab: number) => {
        setIsShowFullList(false);
        setTab(tab);
    };

    const closeCopyDialog = React.useCallback(() => setCopyDialog(false), [setCopyDialog]);
    const openCopyDialog2 = React.useCallback(() => setCopyDialog(true), [setCopyDialog]);

    const handleInitiateBackup = React.useCallback(
        (values: MapOf<string>) => {
            closeProcessProductsDialog();
            if (origin) {
                dispatch(sendReprocessRequest(origin, [values['name']]));
            }
        },
        [closeProcessProductsDialog, dispatch, origin],
    );

    const handleCopyFromOther = React.useCallback(
        ({ origin: copyFromOrigin }) => {
            closeCopyDialog();
            if (origin && copyFromOrigin) {
                dispatch(sendRestoreFromBackup(origin, [copyFromOrigin]));
            }
            //const thunkDispatch = dispatch as AppThunkDispatch;
        },
        [dispatch, closeCopyDialog, values, origin],
    );

    return (
        <Panel title={t(TK.processingSettings)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={6}>
                    <SingleSelectInput
                        label={t(TK.scrapingOrigin)}
                        value={origin}
                        options={countriesList}
                        isLoading={!origins?.length}
                        isCustomFilter={true}
                        onChange={handleOriginChange}
                        renderOption={(option: Country): React.ReactNode => (
                            <React.Fragment>
                                {/*                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                />*/}
                                <CountryFlag
                                    countryCode={option.alpha2Code}
                                    showCode={false}
                                    country={`${option.name} (${option.alpha2Code})`}
                                />
                            </React.Fragment>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button disabled={tab > 1} onClick={showFullList} fullWidth variant="contained">
                        {t(TK.fullList)}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button disabled={!origin} onClick={openProcessProductsDialog} fullWidth variant="contained">
                        {t(TK.backupPlatform)}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Button disabled={!origin} onClick={openCopyDialog2} fullWidth variant="contained">
                        {t(TK.backupRestorePlatform)}
                    </Button>
                </Grid>
            </Grid>

            <FormDialog
                open={processProductsDialog}
                onClose={closeProcessProductsDialog}
                onSubmit={handleInitiateBackup}
                title={t(TK.backupPlatform)}
                fields={[
                    {
                        key: 'name',
                        label: t(TK.name),
                        placeholder: 'e.g. BEBEGEL',
                        freeSolo: true,
                        options: backups,
                        validate: (value) => (!value || value.length < 2 ? t(TK.minimumCharacters, 2) : undefined),
                    },
                ]}
            />

            <FormDialog
                open={copyDialog}
                onClose={closeCopyDialog}
                onSubmit={handleCopyFromOther}
                title={t(TK.backupRestorePlatform)}
                fields={[
                    {
                        key: 'origin',
                        label: t(TK.scrapingOrigin),
                        options: backups,
                    },
                ]}
            />

            {origin && (
                <>
                    <AppBar position="static">
                        <Tabs value={tab} onChange={(_, tab) => showContentByTab(tab)} variant="fullWidth">
                            {/* <Tab label={t(TK.general)} /> */}
                            <Tab label={t(TK.drugForm)} />
                            <Tab label={t(TK.activeSubstances)} />
                            <Tab label={t(TK.atc)} />
                        </Tabs>
                    </AppBar>
                    <Box>
                        {/* {tab == 0 && (
                            <MappingTableContainer>
                                <TextInput
                                    label={t(TK.culture)}
                                    value={values.culture}
                                    placeholder={'Example: en-GB'}
                                    onChange={handleFieldsValueChange('culture')}
                                />
                                <TextInput
                                    label={t(TK.decimalSeparator)}
                                    value={values.decimalSeparator}
                                    placeholder={'Example: .        (like 1,000.50)'}
                                    onChange={handleFieldsValueChange('decimalSeparator')}
                                />
                                <TextInput
                                    label={t(TK.numberGroupSeparator)}
                                    value={values.numberGroupSeparator}
                                    placeholder={'Example: ,        (like 1,000.50)'}
                                    onChange={handleFieldsValueChange('numberGroupSeparator')}
                                />
                                <Typography variant="h5">{t(TK.fields)}</Typography>
                                <Typography variant="caption">
                                    * {t(TK.specifyProductFieldsWhereInfoCanBeFound)}.
                                </Typography>
                                <MultipleSelectInput
                                    limitTags={10}
                                    allowAdd
                                    label={t(TK.drugForm)}
                                    values={values.drugFormPresenceFields}
                                    options={productFields}
                                    onChange={handleFieldsValueChange('drugFormPresenceFields')}
                                />
                                <MultipleSelectInput
                                    limitTags={10}
                                    allowAdd
                                    label={t(TK.administrationForm)}
                                    values={values.administrationFormPresenceFields}
                                    options={productFields}
                                    onChange={handleFieldsValueChange('administrationFormPresenceFields')}
                                />
                                <MultipleSelectInput
                                    limitTags={10}
                                    allowAdd
                                    label={t(TK.package)}
                                    values={values.packagePresenceFields}
                                    options={productFields}
                                    onChange={handleFieldsValueChange('packagePresenceFields')}
                                />
                                <MultipleSelectInput
                                    limitTags={10}
                                    allowAdd
                                    label={t(TK.strength)}
                                    values={values.strengthPresenceFields}
                                    options={productFields}
                                    onChange={handleFieldsValueChange('strengthPresenceFields')}
                                />
                            </MappingTableContainer>
                        )} */}
                        {isLoading ? (
                            <Loading isLoading={isLoading} style={{top:"0"}} />
                        ) : (
                            valuesPforms2 &&
                            !isShowFullList &&
                            tab == 0 && (
                                <PFormMappingTable
                                    drugMap={values.drugFormsMap2}
                                    categoriesMap={values.catsMap}
                                    origin={origin}
                                    onChange={handleMappingForDrug('drugFormsMap2')}
                                />
                            )
                        )}

                        {values.dciMap && !isShowFullList && tab == 1 && (
                            <ActiveIngMappingTable
                                drugMap={values.dciMap}
                                dciFullMap={values.dciFullMap}
                                onChange={handleMappingForActiveIngred('dciMap')}
                            />
                        )}
                        {values.atcMap && tab == 2 && <ATCMappingTable values={values.atcMap} onChange={(v) => {}} />}
                        {values.catsMap && isShowFullList && tab == 0 && (
                            <PFormFullListMappingTable values={values.catsMap} />
                        )}
                        {values.dciFullMap && isShowFullList && tab == 1 && (
                            <ActiveSubFullListMappingTable values={values.dciFullMap} />
                        )}
                    </Box>

                    <PanelButtonsContainer>
                        {/* <Button onClick={handleReset} disabled={isLoading || !changed}>
                            {t(TK.reset)}
                        </Button> */}
                        <Button
                            onClick={handleSave}
                            disabled={isLoading || !changed}
                            variant="contained"
                            color="primary"
                        >
                            {t(TK.save)}
                        </Button>
                    </PanelButtonsContainer>
                </>
            )}
        </Panel>
    );
};

export default ProcessingSettingsPanel;
