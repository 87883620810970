export enum DrugForm {
	None = 'None',
	Unknown = 'Unknown',
	Category_Gas = 'Category_Gas',
	Category_Liquid = 'Category_Liquid',
	Collodion = 'Collodion',
	Concentrate = 'Concentrate',
	Dispersion = 'Dispersion',
	Drops = 'Drops',
	Emulsion = 'Emulsion',
	Lacquer = 'Lacquer',
	Shampoo = 'Shampoo',
	Solution = 'Solution',
	Solvent = 'Solvent',
	Suspension = 'Suspension',
	Syrup = 'Syrup',
	Category_SemiSolid = 'Category_SemiSolid',
	Cement = 'Cement',
	Cream = 'Cream',
	Foam = 'Foam',
	Gel = 'Gel',
	Ointment = 'Ointment',
	Paste = 'Paste',
	Poultice = 'Poultice',
	Category_Solid = 'Category_Solid',
	Block = 'Block',
	Cachet = 'Cachet',
	Capsule = 'Capsule',
	Film = 'Film',
	Granules = 'Granules',
	Gum = 'Gum',
	HerbalMaterial = 'HerbalMaterial',
	Implant = 'Implant',
	ImpregnatedMaterial = 'ImpregnatedMaterial',
	Insert = 'Insert',
	IntraruminalDevice = 'IntraruminalDevice',
	Lozenge = 'Lozenge',
	Lyophilisate = 'Lyophilisate',
	Pastille = 'Pastille',
	Patch = 'Patch',
	Pellets = 'Pellets',
	Pessary = 'Pessary',
	Pillules = 'Pillules',
	Plaster = 'Plaster',
	Pouch = 'Pouch',
	Powder = 'Powder',
	Stick = 'Stick',
	Suppository = 'Suppository',
	Tablet = 'Tablet',
	Tea = 'Tea',
	Category_Unclear = 'Category_Unclear',
	AdditiveUnspecified = 'AdditiveUnspecified',
	LiveAnimal = 'LiveAnimal',
	Premix = 'Premix',
	Radiopharmaceutical = 'Radiopharmaceutical',
	Spray = 'Spray',
	System = 'System',
	Conventional = 'Conventional',
	Delayed = 'Delayed',
	Modified = 'Modified',
	Prolonged = 'Prolonged',
	Soft = 'Soft',
	Hard = 'Hard',
	PreFilled = 'PreFilled',
	Coated = 'Coated',
	Enteric = 'Enteric',
	Release = 'Release',
	Dragee = 'Dragee',
	Strip = 'Strip',
	Swab = 'Swab',
	Aerosol = 'Aerosol',
	Oil = 'Oil',
	Sachet = 'Sachet',
	Blister = 'Blister',
	Vial = 'Vial',
	Bottle = 'Bottle',
	Tube = 'Tube',
	Ampoule = 'Ampoule',
	Syringe = 'Syringe',
	Pen = 'Pen',
	Tape = 'Tape',
}