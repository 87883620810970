import * as React from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Dialog,
    Button,
    IconButton,
    DialogContent,
    TableHead,
    Box,
    Switch,
    Popover,
    Checkbox,
    makeStyles,
    createStyles,
    Theme,
    Tooltip,
    Chip,
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import { MapOf } from '../../../../utils/Types';
import MultipleSelectInput from '../../../../components/inputs/MultipleSelectInput';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ITranslate, useTranslations } from '../../../../store/Translations/hooks';
import { TK } from '../../../../store/Translations/translationKeys';
import { ProductsCompareTableContainer, ProductsCompareFiltersContainer } from './styled';
import { CloseIconWrapper, DialogTitleContainer } from '../../../ProductsListProdV1/styled';
import { Pagination } from '@material-ui/lab';
import SingleSelectInput from '../../../../components/inputs/SingleSelectInput';
import { ProcessingPformsSettings } from '../../../../models/ProcessingPformsSettings';
import { ProcessingCatSettings } from '../../../../models/ProcessingCatSettings';
import PlatformFilters from '../../../../components/Filter';
import { currencyFormatterSync, escapeHtmlSpecialChars, findStringComparison } from '../../../../utils/utils';
import { useDispatch } from 'react-redux';
import { savePFormNewItem } from '../../../../store/Platform/actions';
import TextInput from '../../../../components/inputs/TextInput';
import { ProductCompare, ProductsCompareList } from '../../../../models/ProductCompare';
import FilterListIcon from '@material-ui/icons/FilterList';
import PageSize from '../../../../components/AGTable/PageSize';
import { v4 as uuid4 } from 'uuid';
import { AGIconHoc } from '../../../../components/AGTable/CellFormatter/Hoc/AGIconHoc';
import { ProductUpdate } from '../../../../models/ProductUpdate';
import { Price as PriceBody } from '../../../../models/Price';
import { PriceTitle, PriceList, PriceListItem, PriceCaption } from '../../../../components/PriceV2/styled';
import { currencies } from '../../../../utils/currencies';

export interface ProductsCompareTableProps {
    products: ProductsCompareList;
}

interface ProductsCompareAux {
    id: string;
    url: string;
    type: string;
    compare?: ProductUpdate;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
    }),
);

const urlDiv = (ref?: string) => (<React.Fragment key={uuid4()}>
    <Tooltip
        title={
            <span>
                <h6>{"Web"}</h6>
            </span>
        }
    >
        <a key={"Web"} href={ref ?? ""} target="_blank" rel="noopener noreferrer">
            {ref}
        </a>
    </Tooltip>{' '}
</React.Fragment>);


const ProductsCompareTable: React.FC<ProductsCompareTableProps> = ({ products }) => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const [totalProductsNode, setTotalProductsNode] = React.useState<React.ReactNode[]>([]);
    const [totalProducts, setTotalProducts] = React.useState<ProductsCompareAux[]>([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [ids, setIDS] = React.useState<string[]>([]);
    const [propsName, setPropsName] = React.useState<string[]>([]);
    const [anchorType, setAnchorType] = React.useState<HTMLButtonElement | null>(null);
    const [anchorID, setAnchorElID] = React.useState<HTMLButtonElement | null>(null);
    const [anchorName, setAnchorName] = React.useState<HTMLButtonElement | null>(null);
    const [filterByType, setFilterByType] = React.useState('');
    const [filterByProperty, setFilterByProperty] = React.useState('');
    const [filterByID, setFilterByID] = React.useState('');
    const openPopoverType = Boolean(anchorType);
    const openPopoverID = Boolean(anchorID);
    const openPopoverName = Boolean(anchorName);
    const handlePopoverClickType = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorType(event.currentTarget);
    };
    const handlePopoverClickID = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElID(event.currentTarget);
    };
    const handlePopoverClickName = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorName(event.currentTarget);
    };
    const onClickResetByType = React.useCallback(() => {
        setFilterByType('');
        setCurrentPage(1);
    }, [setFilterByType, setCurrentPage]);
    const onClickResetByID = React.useCallback(() => {
        setFilterByID('');
        setCurrentPage(1);
    }, [setFilterByID, setCurrentPage]);
    const onClickResetByProperty = React.useCallback(() => {
        setFilterByProperty('');
        setCurrentPage(1);
    }, [setFilterByProperty, setCurrentPage]);

    const priceDiv = React.useCallback((price?: string) => {
        let valueJson = JSON.parse(price ?? "") as any[];
        let r = valueJson.map(I => {
            let value = I?.Value;
            if (!value) {
                return <></>;
            }
            let val = currencyFormatterSync(value, I.CurrencyCode.replace("DNK", "DKK"));
            let title = t((I.Type === 'Manufacturer Price' ? I.Type : I.Type + 'Price') as TK)
            return (
                <Tooltip
                    title={
                        <>
                            <PriceTitle>{title}</PriceTitle>
                            <PriceList>
                                {value && (
                                    <PriceListItem>
                                        <PriceCaption></PriceCaption>
                                        {<div style={{ fontSize: "0.9rem" }}>{val}</div>}
                                    </PriceListItem>
                                )}
                            </PriceList>
                        </>
                    }
                >
                    <span>
                        <Chip size="small" label={<div style={{ fontSize: "0.9rem" }}>{val}</div>} />
                    </span>
                </Tooltip>);
        }
        );
        return (<>{r}</>);
    }, []);

    const onChangePageSize = (value: number) => {
        setPageSize(value);
        setCurrentPage(1);
    };
    const handleClosePopoverType = () => {
        setAnchorType(null);

    };
    const handleClosePopoverID = () => {
        setAnchorElID(null);

    };
    const handleClosePopoverName = () => {
        setAnchorName(null);

    };
    const onFilterTypeInputChange = React.useCallback(
        (value) => {
            setFilterByType(value);
            setCurrentPage(1);
        },
        [setFilterByType, setCurrentPage],
    );
    const onFilterIDInputChange = React.useCallback(
        (value) => {
            setFilterByID(value);
            setCurrentPage(1);
        },
        [setFilterByID, setCurrentPage],
    );
    const onFilterPropertyInputChange = React.useCallback(
        (value) => {
            setFilterByProperty(value);
            setCurrentPage(1);
        },
        [setFilterByProperty, setCurrentPage],
    );

    let teste = totalProducts.slice(0, 9);
    let start = (currentPage - 1) * pageSize;
    let end = currentPage * pageSize;
    const classes = useStyles();
    const [count, setCount] = React.useState(0);
    React.useEffect(() => {
        let addCount = products?.added?.length ?? 0;
        let updateCount = products?.updated?.length ?? 0;
        let deleteCount = products?.deleted?.length ?? 0;

        setCount(addCount + updateCount + deleteCount);
    }, [products, setCount]);
    const onChangePageNumber = React.useCallback(
        (event: React.ChangeEvent<unknown>, pageNumber: number) => {
            setCurrentPage(pageNumber);
        },
        [setCurrentPage],
    );
    React.useEffect(() => {
        let ids = [] as string[]
        let props = [] as string[]
        let list = products?.added
            ?.filter(I => filterByType == undefined || filterByType == '' || filterByType == "Added")
            ?.filter(I => filterByID == undefined || filterByID == '' || filterByID == I.productIdentifier)
            ?.filter(I => filterByProperty == undefined || filterByProperty == '')
            ?.map((val) => {
                ids.push(val.productIdentifier)
                return { id: val?.productIdentifier, url: val?.url, type: "Added" };
            }) as ProductsCompareAux[];
        //    ?.map((value) => (
        //        <TableRow>
        //            <TableCell>{"Added"}</TableCell>
        //            <TableCell>{urlDiv(value?.url)}</TableCell>
        //            <TableCell>{value?.productIdentifier}</TableCell>
        //            <TableCell></TableCell>
        //            <TableCell></TableCell>
        //            <TableCell></TableCell>
        //        </TableRow>));
        let updated = products?.updated
            ?.filter(I => I.propertiesChanged?.length != 0)
            ?.filter(I => filterByType == undefined || filterByType == '' || filterByType == "Updated")
            ?.filter(I => filterByID == undefined || filterByID == '' || filterByID == I.productIdentifier)
            ?.map(propertyChanged => {
                ids.push(propertyChanged.productIdentifier)
                return propertyChanged.propertiesChanged
                    ?.filter(I => filterByProperty == undefined || filterByProperty == '' || filterByProperty == I?.propertyName)
                    ?.map(val => {
                        props.push(val.propertyName ?? '');
                        return { id: propertyChanged?.productIdentifier, url: propertyChanged?.url, type: "Updated", compare: val };
                    })
                //        ?.map(value =>
                //        (
                //            <TableRow>
                //                <TableCell>{"Updated"}</TableCell>
                //                <TableCell>{propertyChanged?.productIdentifier}</TableCell>
                //                <TableCell>{value?.propertyName}</TableCell>
                //                <TableCell>{value?.oldValue}</TableCell>
                //                <TableCell>{value?.newValue}</TableCell>
                //            </TableRow>
                //        ));
            }).flatMap(I => I);
        var deleted = products?.deleted
            ?.filter(I => filterByType == undefined || filterByType == '' || filterByType == "Deleted")
            ?.filter(I => filterByID == undefined || filterByID == '' || filterByID == I.productIdentifier)
            ?.filter(I => filterByProperty == undefined || filterByProperty == '')
            .map((val) => {
                ids.push(val.productIdentifier);
                return { id: val.productIdentifier, url: val.url, type: "Deleted" };
            })
        //    .map((value) => (
        //        <TableRow>
        //            <TableCell>{"Deleted"}</TableCell>
        //            <TableCell>{urlDiv(value?.url)}</TableCell>
        //            <TableCell>{value?.productIdentifier}</TableCell>
        //            <TableCell></TableCell>
        //            <TableCell></TableCell>
        //            <TableCell></TableCell>
        //        </TableRow>));
        setTotalPages((list?.length ?? 0) + (updated?.length ?? 0) + (deleted?.length ?? 0));
        let current = list?.slice(start, end);
        if (updated != undefined && updated != null && updated.length != 0 && list.slice(start, end).length < pageSize) {
            let updated2 = updated as ProductsCompareAux[];
            
            if (list.slice(start, end).length == 0) {
                let start2 = start - list.length;
                updated2 = updated2.slice(start2, (start2 + pageSize));
            } else {
                let end2 = pageSize - list.slice(start, end).length;
                updated2 = updated2.slice(0, end2);
            }
            list?.push(...updated2);
            current?.push(...updated2);
        }
        if (deleted != undefined && deleted != null && deleted.length != 0 && list.slice(start, end).length < pageSize) {
            let deleted2 = deleted as ProductsCompareAux[];

            if (list.slice(start, end).length == 0) {
                let start2 = (start - list.length) - (updated?.length ?? 0);
                deleted2 = deleted2.slice(start2, (start2 + pageSize));
            } else {
                let end2 = pageSize - list.slice(start, end).length;
                deleted2 = deleted2.slice(0, end2);
            }

            list?.push(...deleted2);
            current?.push(...deleted2);
        }
        
        setIDS(ids);
        props = [...new Set(props)].sort();
        setPropsName(props);
        setTotalProducts(current ?? []);
    }, [products, start, end, pageSize, setTotalPages, setTotalProducts, filterByType, filterByProperty, filterByID, setIDS, setPropsName, urlDiv, priceDiv]);
    React.useEffect(() => {
        let currentNodes = totalProducts?.slice(0, pageSize)?.map(val => {
            if (val.type == "Updated") {
                if (val.compare?.propertyName == "Prices") {
                    return (<TableRow>
                        <TableCell>{val.type}</TableCell>
                        <TableCell>{urlDiv(val?.url)}</TableCell>
                        <TableCell>{val?.id}</TableCell>
                        <TableCell>{val.compare?.propertyName}</TableCell>
                        <TableCell>{priceDiv(val?.compare?.oldValue)}</TableCell>
                        <TableCell>{priceDiv(val?.compare?.newValue)}</TableCell>
                    </TableRow>);
                }
                return (<TableRow>
                    <TableCell>{val.type}</TableCell>
                    <TableCell>{urlDiv(val?.url)}</TableCell>
                    <TableCell>{val?.id}</TableCell>
                    <TableCell>{val.compare?.propertyName}</TableCell>
                    <TableCell>{val.compare?.oldValue}</TableCell>
                    <TableCell>{val.compare?.newValue}</TableCell>
                </TableRow>);
            }
            return (
                <TableRow>
                    <TableCell>{val.type}</TableCell>
                    <TableCell>{urlDiv(val?.url)}</TableCell>
                    <TableCell>{val?.id}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>);
        });
        setTotalProductsNode(currentNodes);
    }, [setTotalProductsNode, totalProducts, pageSize, start, end, urlDiv, priceDiv]);
    return (
        <ProductsCompareTableContainer>
            <Box display="flex" justifyContent="end">
                <Box mr={9} display="flex" alignItems="center">
                    <PageSize
                        onHandleChange={onChangePageSize}
                        initialPageSize={pageSize}
                    ></PageSize>
                    <Typography>Total: {count} records</Typography>
                </Box>
            </Box>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width="500px">
                            <Box display="flex">
                                <Typography>Type</Typography>
                                <IconButton
                                    aria-label="filter"
                                    aria-describedby={"filter-type"}
                                    size="small"
                                    color="primary"
                                    onClick={handlePopoverClickType}
                                >
                                    <FilterListIcon fontSize="small" />
                                </IconButton>
                                <Popover id="filter-type"
                                    open={openPopoverType}
                                    anchorEl={anchorType}
                                    onClose={handleClosePopoverType}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}>
                                    <Box padding="5px" width="220px">
                                        <SingleSelectInput value={filterByType} options={["Added", "Updated", "Deleted"]} onChange={onFilterTypeInputChange} />
                                    </Box>
                                    <Box display="flex" justifyContent="right" >
                                        <Box marginTop="10px" marginBottom="10px">
                                            <Button onClick={() => onClickResetByType()} color="primary" variant="contained">
                                                Reset
                                            </Button>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Typography>URL</Typography>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Product Code</Typography>
                                <IconButton
                                    aria-label="filter"
                                    aria-describedby={"filter-code"}
                                    size="small"
                                    color="primary"
                                    onClick={handlePopoverClickID}
                                >
                                    <FilterListIcon fontSize="small" />
                                </IconButton>
                                <Popover id="filter-code"
                                    open={openPopoverID}
                                    anchorEl={anchorID}
                                    onClose={handleClosePopoverID}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}>
                                    <Box padding="5px" width="220px">
                                        <SingleSelectInput value={filterByID} options={ids} onChange={onFilterIDInputChange} />
                                    </Box>
                                    <Box display="flex" justifyContent="right" >
                                        <Box marginTop="10px" marginBottom="10px">
                                            <Button onClick={() => onClickResetByID()} color="primary" variant="contained">
                                                Reset
                                            </Button>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Property Name</Typography>
                                <IconButton
                                    aria-label="filter"
                                    aria-describedby={"filter-name"}
                                    size="small"
                                    color="primary"
                                    onClick={handlePopoverClickName}
                                >
                                    <FilterListIcon fontSize="small" />
                                </IconButton>
                                <Popover id="filter-name"
                                    open={openPopoverName}
                                    anchorEl={anchorName}
                                    onClose={handleClosePopoverName}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}>
                                    <Box padding="5px" width="220px">
                                        <SingleSelectInput value={filterByProperty} options={propsName} onChange={onFilterPropertyInputChange} />
                                    </Box>
                                    <Box display="flex" justifyContent="right" >
                                        <Box marginTop="10px" marginBottom="10px">
                                            <Button onClick={() => onClickResetByProperty()} color="primary" variant="contained">
                                                Reset
                                            </Button>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Typography>Old Value</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>New Value</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {totalProductsNode}
                    {/*    {products?.added?.map((value: any , index: number) => (*/}
                    {/*        <TableRow key={value.productIdentifier}>*/}
                    {/*            <TableCell>{"Added"}</TableCell>*/}
                    {/*            <TableCell>{value?.productIdentifier}</TableCell>*/}
                    {/*            <TableCell></TableCell>*/}
                    {/*            <TableCell></TableCell>*/}
                    {/*            <TableCell></TableCell>*/}
                    {/*        </TableRow> ))}*/}
                    {/*    {updated?.map((propertyChanged, index) =>*/}
                    {/*        propertyChanged?.propertiesChanged?.map((value) => (*/}
                    {/*            <TableRow key={propertyChanged.productIdentifier}>*/}
                    {/*                <TableCell>{"Updated"}</TableCell>*/}
                    {/*                <TableCell>{propertyChanged?.productIdentifier}</TableCell>*/}
                    {/*                <TableCell>{value?.propertyName}</TableCell>*/}
                    {/*                <TableCell>{value?.oldValue}</TableCell>*/}
                    {/*                <TableCell>{value?.newValue}</TableCell>*/}
                    {/*            </TableRow>*/}
                    {/*        ))*/}
                    {/*    )}*/}
                    {/*    {products?.deleted?.map((value, index) => (*/}
                    {/*        <TableRow key={value.productIdentifier}>*/}
                    {/*            <TableCell>{"Deleted"}</TableCell>*/}
                    {/*            <TableCell>{value?.productIdentifier}</TableCell>*/}
                    {/*            <TableCell></TableCell>*/}
                    {/*            <TableCell></TableCell>*/}
                    {/*            <TableCell></TableCell>*/}
                    {/*        </TableRow>*/}
                    {/*    ))}*/}
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="right">
                <Box margin="1">
                    <PageSize
                        onHandleChange={onChangePageSize}
                        initialPageSize={pageSize}
                    ></PageSize>
                    <Pagination
                        color="primary"
                        count={Math.ceil(totalPages / pageSize)}
                        page={currentPage}
                        onChange={onChangePageNumber}
                        showFirstButton
                        showLastButton
                    />
                </Box>
            </Box>
        </ProductsCompareTableContainer>
    );
};

export default ProductsCompareTable;
