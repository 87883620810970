import React, { useCallback, useState } from 'react';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Button,
    Box,
    Chip,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Tooltip,
    makeStyles,
    Popover,
    Theme,
    Typography,
    DialogContentText,
} from '@material-ui/core';
//import {  } from '../../models/ProductV2';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AvailabilityColors, AvailabilityTerms, AvailabilityColorsFront } from '../../constants/general';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinkIcon from '@material-ui/icons/Link';

import ReactCountryFlag from 'react-country-flag';
import { CloseIconWrapper, DialogTitleContainer } from '../../pages/ProductsListV3/styled';
import { LogExScrapper } from '../../models/LogExScrapper';
import { LogsScrapperResult } from '../../models/LogsScrapperResult';
import { LogMessage, LogMessageContent, Message } from '../../models/LogMessage';
import { useDispatch } from 'react-redux';
import { AcceptToDev, AcceptToProd, AddCommen, DeleteCommen, UpdateComment } from '../../store/Logs2/actions';
import { goToProducts, goToRFQsListV2 } from '../../store/Router/actions';
//import ProductContent from '../ProductContent';

interface ILogDialogProps {
    log?: LogsScrapperResult;
    handleDialogClose: () => void;

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        lastUpdate: {
            flex: 1,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '30px',
            fontSize: '0.8rem',
        },
    }),
);

export const AcceptToProdConfirmDialog: React.FC<ILogDialogProps> = ({
    log,
    handleDialogClose,
}) => {
    const t = useTranslations();
    const classes = useStyles();
    const dispatch = useDispatch();
    //const [values, setValues] = React.useState<string[]>([]);
    var handleAccept = () => {
        var l = log?.last as LogExScrapper;
        dispatch(AcceptToProd(l));
        handleDialogClose();
    };
    return (
        <Dialog open={!!log} maxWidth="lg" onClose={handleDialogClose}>
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ fontWeight: 700 }}>{log?.messages?.name}</div>
                </DialogTitleContainer>
                <CloseIconWrapper>
                    <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                        <HighlightOffIcon color="primary" fontSize="large" />
                    </IconButton>
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <DialogContentText>Are you sure you want to send {log?.last.executionName} to productions?</DialogContentText>
                <Button onClick={handleDialogClose} variant="contained">Cancel</Button>
                <Button onClick={handleAccept} color="primary" variant="contained">Accept</Button>

            </DialogContent>
        </Dialog>
    );
};
