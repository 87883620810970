import * as React from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Dialog,
    Button,
    IconButton,
    DialogContent,
    TableHead,
    Box,
    Switch,
    Checkbox,
    makeStyles,
    createStyles,
    Theme,
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';

import EditIcon from '@material-ui/icons/Edit';
import { MapOf } from '../../../../utils/Types';
import MultipleSelectInput from '../../../../components/inputs/MultipleSelectInput';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslations } from '../../../../store/Translations/hooks';
import { TK } from '../../../../store/Translations/translationKeys';
import { PFormMappingTableContainer } from './styled';
import { CloseIconWrapper, DialogTitleContainer } from '../../../ProductsListProdV1/styled';
import { Pagination } from '@material-ui/lab';
import SingleSelectInput from '../../../../components/inputs/SingleSelectInput';
import { ProcessingPformsSettings } from '../../../../models/ProcessingPformsSettings';
import { ProcessingCatSettings } from '../../../../models/ProcessingCatSettings';
import PlatformFilters from '../../../../components/Filter';
import { escapeHtmlSpecialChars, findStringComparison } from '../../../../utils/utils';
import { useDispatch } from 'react-redux';
import { savePFormNewItem } from '../../../../store/Platform/actions';
import TextInput from '../../../../components/inputs/TextInput';

export interface PFormMappingTableProps {
    drugMap: MapOf<ProcessingPformsSettings>;
    categoriesMap: MapOf<ProcessingCatSettings>;
    origin: string;
    onChange: (values: MapOf<ProcessingPformsSettings>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
    }),
);

const PFormMappingTable: React.FC<PFormMappingTableProps> = ({ drugMap, categoriesMap, origin, onChange }) => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [isApproved, setIsApproved] = React.useState(false);
    
    // Data shown on the table (filtered by current page and new terms filter)
    const [pFormsList, setPFormsList] = React.useState<ProcessingPformsSettings[]>([]);
    
    const [currentPage, setCurrentPage] = React.useState(1);
    
    // Filters
    const [selectFilterType, setSelectFilterType] = React.useState('');
    const [selectedFieldName, setSelectedFieldName] = React.useState('');
    const [selectedFilterValue, setSelectedFilterValue] = React.useState('');
    
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [showingRecordsCount, setShowingRecordsCount] = React.useState(0);

    const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(0);
    
    const [category, setCategory] = React.useState('');
    const [subCategory, setSubCategory] = React.useState('');
    const [pharmaForm, setPharmaForm] = React.useState('');
    const [adminRoute, setAdminRoute] = React.useState('');
    
    const recordsPerPage = 10;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? 'simple-popover' : undefined;

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    let pharmaceuticalPhorms = [] as string[];
    Object.values(categoriesMap).forEach((I) => pharmaceuticalPhorms.push(I.pharmaceuticalPhorm));
    let options3 = new Set<string>(pharmaceuticalPhorms);
    pharmaceuticalPhorms = Array.from(options3);

    let subCategories = Object.values(categoriesMap).map((I) => I.subCategory);
    options3 = new Set<string>(subCategories);
    subCategories = Array.from(options3);

    let categories = Object.values(categoriesMap).map((I) => I.category);
    options3 = new Set<string>(categories);
    categories = Array.from(options3);

    let adminRoutes = Object.values(categoriesMap).map((I) => I.administrationRoute);
    options3 = new Set<string>(adminRoutes);
    adminRoutes = Array.from(options3);

    React.useEffect(() => {
        setCurrentPage(1);
        setIsApproved(false);
        setSelectFilterType('');
        setSelectedFieldName('');
        setSelectedFilterValue('');
    }, [drugMap]);

    React.useEffect(() => {
        const offset = currentPage * recordsPerPage;
        const startingNumber = offset - recordsPerPage;

        const mappedList = Object.keys(drugMap)
            .map((key) => ({
                ...drugMap[key],
                editable: drugMap[key].closed,
                historyStack: [],
                originalLowerCased: key
            })); 


        const filteredIngList = mappedList
            // Column filters
            .filter((item) => (selectedFieldName === 'original' && findStringComparison(item.original, selectedFilterValue, selectFilterType) || true))
            .filter((item) => (selectedFieldName === 'translated' && findStringComparison(item.translated, selectedFilterValue, selectFilterType) || true))
            .filter((item) => (selectedFieldName === 'final' && findStringComparison(item.final?.join(' '), selectedFilterValue, selectFilterType) || true))
            // New Terms Filter
            .filter((item) => (isApproved && item.isOldTerm === false) || !isApproved);
        
        setTotalRecords(filteredIngList.length);
        setPFormsList(filteredIngList.slice(startingNumber, offset));
        setShowingRecordsCount(filteredIngList.slice(startingNumber, offset).length);

        const totalNumberOfPages = Math.ceil(filteredIngList.length / recordsPerPage);
        setTotalNumberOfPages(totalNumberOfPages);

    }, [drugMap,
        selectFilterType,
        selectedFieldName,
        selectedFilterValue,
        isApproved,
        currentPage]);

    const onChangePageNumber = React.useCallback(
        (event: React.ChangeEvent<unknown>, pageNumber: number) => {
            setCurrentPage(pageNumber);
        },
        [setCurrentPage],
    );

    const onSelectFilterChange = React.useCallback(
        (value) => {
            setSelectFilterType(value);
        },
        [selectFilterType, setSelectFilterType],
    );

    const onTextFilterChange = React.useCallback(
        (fieldName: string) => (filterVal: string) => {
            setSelectedFieldName(fieldName);
            setSelectedFilterValue(filterVal);
        },
        [
            selectedFieldName,
            setSelectedFieldName,
            selectedFilterValue,
            setSelectedFilterValue
        ],
    );

    const onClickToUndo = React.useCallback(
        (key: string, original: string) => {
            /*
                First find the index of the element by using key
            */
            const index = pFormsList.findIndex((s) => s.original == original);
            // check if the historyStack of the element have elements
            if (pFormsList[index].historyStack?.length) {
                //Pop the value from the history stack
                const value = pFormsList[index].historyStack?.pop();

                //Check if the drugMap has the final property exist
                if (drugMap[key].final) {
                    /*
                        Find the indexNumber of the poped value exist in the drugMap                       
                    */
                    let itemIndex = drugMap[key].final ? drugMap[key].final?.findIndex((s) => s === value) : -1;
                    /**
                     *  if it exist then update the drugMap final value by omitting
                     *  the poped value from the history stack
                     */
                    if (itemIndex !== undefined && itemIndex > 0) {
                        let strArray = drugMap[key].final?.filter((val) => val !== value) || [];
                        drugMap[key].final = [...strArray];
                    } else {
                        drugMap[key].final?.push(value ? value : '');
                    }
                }

                //Update the pFormsListData as well with the drugMap.final value
                pFormsList[index].final = drugMap[key].final;
                pFormsList[index].edited = false;
                //Update the original drugMap
                onChange(drugMap);
                // Create a new state reference. We're changing the item by reference and if we don't do this
                // react will not update the state
                setPFormsList(pFormsList => pFormsList.map((el) => el));
            }
        },
        [onChange, drugMap, pFormsList, currentPage, setPFormsList],
    );

    const handleChange = React.useCallback(
        (key: string, original: string) => (rowValues: string[]) => {
            /**
             * If user have picked the Add new term then
             * Open the dialog box
             */
            const itemIndex = rowValues.findIndex((i) => i == 'Add new');
            if (itemIndex >= 0) {
                console.log('Add new');
                setOpen(true);
                return;
            }

            /**
             * Get the count of the DrugMap final array
             */
            const currentValueCount = drugMap[key].final?.length;
            //Find the indexNumber of pFormsListData element
            const indexNumber = pFormsList.findIndex((s) => s.original.toLowerCase() == original.toLowerCase());

            //Assign the new rowValues to the pforms.final property
            pFormsList[indexNumber].final = rowValues;

            /**
             * If curentValueCount is not undefiend and has value greater than
             * rowValues length (selected from the list) then it means a value is removed so save
             * the item which is remove in historyStack and also update drugMap final array with rowValues
             * ELSE
             * it means new value has been added then
             * Update the history stack with new rowValues and also final property of drugMap
             */
            if (typeof currentValueCount !== 'undefined' && currentValueCount > rowValues.length) {
                const strNotInRowValues = drugMap[key].final?.filter((val) => !rowValues.includes(val)) || [];
                let strInHstackArray = pFormsList[indexNumber].historyStack || [];
                pFormsList[indexNumber].historyStack =
                    strInHstackArray.length > 0
                        ? Array.from(
                              new Set<string>([...strInHstackArray, ...strNotInRowValues]),
                          )
                        : [...strNotInRowValues];
            } else {
                const strNotInDrugMap = rowValues.filter((val) => !drugMap[key].final?.includes(val)) || [];
                let stringInHstackArray = pFormsList[indexNumber].historyStack || [];
                pFormsList[indexNumber].historyStack =
                    stringInHstackArray.length > 0
                        ? Array.from(
                              new Set<string>([...stringInHstackArray, ...strNotInDrugMap]),
                          )
                        : [...strNotInDrugMap];
            }

            drugMap[key].final = rowValues;
            onChange(drugMap);
            // Create a new state reference. We're changing the item by reference and if we don't do this
            // react will not update the state
            setPFormsList(pFormsList => pFormsList.map((el) => el));
        },
        [onChange, drugMap, setOpen, pFormsList, setPFormsList],
    );

    const onClickEditable = React.useCallback((originalName: string) => {
        let index = pFormsList.findIndex((item) => item.original === originalName);
        if (index >= 0) {
            pFormsList[index].editable = false;
            // Create a new state reference. We're changing the item by reference and if we don't do this
            // react will not update the state
            setPFormsList(pFormsList => pFormsList.map((el) => el));
        } else {
            console.error(`Did not find element with name ${originalName}: `, pFormsList);
        }
    }, [pFormsList, setPFormsList]);

    const onClickToAppove = React.useCallback((key: string, originalName: string) => {
        const index = pFormsList.findIndex((item) => item.original === originalName);
        if (index >= 0) {
            pFormsList[index].editable = true;
            pFormsList[index].closed = true;
            pFormsList[index].edited = true;
            drugMap[key].closed = true;
            drugMap[key].edited = true;
            onChange(drugMap);
            // Create a new state reference. We're changing the item by reference and if we don't do this
            // react will not update the state
            setPFormsList(pFormsList => pFormsList.map((el) => el));
        } else {
            console.error(`Did not find element with name ${originalName}: `, pFormsList);
        }
    }, [drugMap, pFormsList, setPFormsList, onChange]);

    const handleSwitchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsApproved(event.target.checked);
    },[]);

    const handleClose = () => {
        setCategory('');
        setSubCategory('');
        setPharmaForm('');
        setAdminRoute('');

        setOpen(false);
    };

    const createPForm = () => {
        const newTermData = {
            country: origin,
            category: category,
            subCategory: subCategory,
            pharmaceuticalPhorm: escapeHtmlSpecialChars(pharmaForm),
            administrationRoute: adminRoute,
        };

        if (origin && category && subCategory && pharmaForm && adminRoute) {
            dispatch(savePFormNewItem(origin, newTermData));
        }

        setCategory('');
        setSubCategory('');
        setPharmaForm('');
        setAdminRoute('');
        setOpen(false);
    };

    if (Object.keys(drugMap).length == 0) {
        return <></>;
    }

    return (
        <PFormMappingTableContainer>
            <Typography variant="caption">* {t(TK.specifyTheKeywordsForTheCorrespondingValues)}.</Typography>
            <Box display="flex" justifyContent="end">
                <Box mr={9} display="flex" alignItems="center">
                    <Typography>Show only new terms</Typography>
                    <Switch
                        color="primary"
                        checked={isApproved}
                        onChange={handleSwitchChange}
                        name="notCommercial"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {isApproved ? (
                        <Typography>
                            Showing {showingRecordsCount} of {totalRecords} records
                        </Typography>
                    ) : (
                        <Typography>Total: {totalRecords} records</Typography>
                    )}
                </Box>
            </Box>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width="500px">
                            <Box display="flex">
                                <Typography>Original</Typography>
                                <PlatformFilters
                                    elementId="original-popover"
                                    onTextFilterChange={onTextFilterChange('original')}
                                    onSelectFilterChange={onSelectFilterChange}
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Translated</Typography>
                                <PlatformFilters
                                    elementId="translated-popover"
                                    onTextFilterChange={onTextFilterChange('translated')}
                                    onSelectFilterChange={onSelectFilterChange}
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box display="flex">
                                <Typography>Final</Typography>
                                <PlatformFilters
                                    elementId="final-popover"
                                    onTextFilterChange={onTextFilterChange('translated')}
                                    onSelectFilterChange={onSelectFilterChange}
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Typography>Details</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pFormsList?.map((value, index) => (
                        <TableRow key={value.original}>
                            <TableCell>{value.original}</TableCell>
                            <TableCell>{value?.translated}</TableCell>
                            <TableCell>
                                <Box display="flex">
                                    <MultipleSelectInput
                                        values={value?.final}
                                        options={pharmaceuticalPhorms}
                                        disabled={value.editable}
                                        renderOption={(option, { selected }) => {
                                            if (option == 'Add new') {
                                                return (
                                                    <div>
                                                        <hr style={{ width: '200px' }} />
                                                        {option}
                                                    </div>
                                                );
                                            }
                                            return (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option}
                                                </React.Fragment>
                                            );
                                        }}
                                        showAdd={true}
                                        freeSolo
                                        onChange={handleChange(value.originalLowerCased, value.original)}
                                        style={{
                                            width: '80%',
                                            backgroundColor: value.edited ? '#c8e1cc' : (value.closed ? '#8080800d' : '#ffff0066'),
                                        }}
                                    />
                                    {(value.editable || (!value.editable && !value.isOldTerm))  && (
                                        <IconButton
                                            style={{ marginTop: '6px' }}
                                            aria-label="edit"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                onClickEditable(value.original);
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    )}

                                    {!value.editable && (
                                        <IconButton
                                            style={{ marginTop: '6px' }}
                                            aria-label="approve"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                onClickToAppove(value.originalLowerCased, value.original);
                                            }}
                                        >
                                            <CheckIcon fontSize="small" />
                                        </IconButton>
                                    )}

                                    {!value.editable && value.historyStack && value.historyStack.length > 0 ? (
                                        <IconButton
                                            style={{ marginTop: '6px' }}
                                            aria-label="approve"
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                onClickToUndo(value.originalLowerCased, value.original);
                                            }}
                                        >
                                            <UndoIcon fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        ''
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell>
                                Last updated by:
                                <br />
                                {value?.user}
                            </TableCell>
                        </TableRow>
                    ))}
                    {pFormsList.length == 0 && <Typography>No record found</Typography>}
                </TableBody>
            </Table>

            {(pFormsList.length >= 10 || currentPage == totalNumberOfPages) && (
                <Box display="flex" justifyContent="right">
                    <Box margin="1">
                        <Pagination
                            color="primary"
                            count={totalNumberOfPages}
                            page={currentPage}
                            onChange={onChangePageNumber}
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                </Box>
            )}
            <Dialog open={open} onClose={handleClose} style={{ width: '100%' }}>
                <DialogTitleContainer>
                    <CloseIconWrapper>
                        <IconButton onClick={handleClose} style={{ outline: 'none' }}>
                            <HighlightOffIcon color="primary" fontSize="large" />
                        </IconButton>
                    </CloseIconWrapper>
                </DialogTitleContainer>
                <DialogContent style={{ width: '500px' }}>
                    <div>
                        <b>Category</b>
                        <SingleSelectInput
                            options={categories}
                            value={category}
                            onChange={(val) => {
                                setCategory(val);
                            }}
                            //freeSolo
                        />
                    </div>
                    <div>
                        <b>Subcategory</b>
                        <SingleSelectInput
                            options={subCategories}
                            value={subCategory}
                            onChange={(val) => setSubCategory(val)}
                            //freeSolo
                        />
                    </div>
                    <div>
                        <b>Pharmaceutical form</b>
                        <TextInput
                            style={{ width: '425px' }}
                            value={pharmaForm}
                            onChange={(val) => setPharmaForm(val)}
                        />
                    </div>
                    <div>
                        <b>Administration route</b>
                        <SingleSelectInput
                            options={adminRoutes}
                            value={adminRoute}
                            onChange={(val) => setAdminRoute(val)}
                            //freeSolo
                        />
                    </div>
                    <Box display="flex" justifyContent="right">
                        <Box marginTop="10px" marginBottom="10px">
                            <Button onClick={createPForm} color="primary" variant="contained">
                                Create
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </PFormMappingTableContainer>
    );
};

export default PFormMappingTable;
