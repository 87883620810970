import { Tooltip } from '@material-ui/core';

import React from 'react';
import { RFQDetails } from '../../../models/RFQDetails';
import { useTranslations } from '../../../store/Translations/hooks';
import { IRfqProps } from '../interface';
import { RFQItemActionsPanel } from '../styled';
import { RFQQuoteInfo } from '../../../models/RFQQuoteInfo';
import { RFQQuoteState } from '../../../models/RFQQuoteState';
import { StatusRoundChip } from '../../../components/RFQQuoteAvatar/styled';

export const RFQRightPanel: React.FC<{
    details?: RFQDetails;
}> = ({ details }) => {
    const t = useTranslations();

    const dataTables = React.useMemo(() => {
        const dataTables: RFQQuoteInfo[] | undefined = details?.suppliersDetails
            .map((item) => item.dataTable)
            .reduce((prev, curr) => [...prev, ...curr], []);
        return dataTables;
    }, [details]);

    const openQuotes = React.useMemo(() => {
        let count = 0;
        if (dataTables) {
            dataTables.forEach((item) => {
                if (item.state === RFQQuoteState.Open || item.state === RFQQuoteState.InProgress || item.state === RFQQuoteState.ToQuote) {
                    count++;
                }
            });
        }
        return count;
    }, [dataTables]);

    const quotedQuotes = React.useMemo(() => {
        let count = 0;
        if (dataTables) {
            dataTables.forEach((item) => {
                if (item.state === RFQQuoteState.Quoted) {
                    count++;
                }
            });
        }
        return count;
    }, [dataTables]);

    const declinedQuotes = React.useMemo(() => {
        let count = 0;
        if (dataTables) {
            dataTables.forEach((item) => {
                if (item.state === RFQQuoteState.Declined) {
                    count++;
                }
            });
        }
        return count;
    }, [dataTables]);

    const alterntveQuotes = React.useMemo(() => {
        let count = 0;
        if (dataTables) {
            dataTables.forEach((item) => {
                if (item.state === RFQQuoteState.Alternative) {
                    count++;
                }
            });
        }
        return count;
    }, [dataTables]);
    const alterntveQuotes2 = React.useMemo(() => {
        let count = 0;
        if (dataTables) {
            dataTables.forEach((item) => {
                count = item.isAlternative ? count + 1 : count;
            });
        }
        return count;
    }, [dataTables]);

    return (
        <RFQItemActionsPanel>
            {(
                <Tooltip title={<h6>{RFQQuoteState.Open}</h6>}>
                    <StatusRoundChip state={RFQQuoteState.Open}>{openQuotes}</StatusRoundChip>
                </Tooltip>
            )}
            {(
                <Tooltip title={<h6>{RFQQuoteState.Quoted}</h6>}>
                    <StatusRoundChip state={RFQQuoteState.Quoted}>{quotedQuotes}</StatusRoundChip>
                </Tooltip>
            )}
            { (
                <Tooltip title={<h6>{RFQQuoteState.Declined}</h6>}>
                    <StatusRoundChip state={RFQQuoteState.Declined}>{declinedQuotes}</StatusRoundChip>
                </Tooltip>
            )}
            {!!alterntveQuotes && (
                <Tooltip title={<h6>{RFQQuoteState.Alternative}</h6>}>
                    <StatusRoundChip state={RFQQuoteState.Alternative}>{alterntveQuotes}</StatusRoundChip>
                </Tooltip>
            )}
            {!alterntveQuotes && !!alterntveQuotes2 && (
                <Tooltip title={<h6>{RFQQuoteState.Alternative}</h6>}>
                    <StatusRoundChip state={RFQQuoteState.Alternative}>{0}</StatusRoundChip>
                </Tooltip>
            )}
        </RFQItemActionsPanel>
    );
};
