import { AppThunkAction } from '..';
import { Action } from 'redux';
import { alertGenericError, requestServer, alertSuccess } from '../Session/actions';
import { LogsDataHistoryLoadedAction, LogsDataLoadedAction, LogsDataReportLoadedAction } from './types';
import { isLoadingLogsData } from './selectors';
import { ProcessingSettings } from '../../models/ProcessingSettings';
import {
    getScrapperLogsAsync,
    getPlatformPformProcessingSettingsAsync,
    postPlatformProcessingSettingsAsync,
    postPlatformSenReProcessRequestAsync as postPlatformSendReprocessRequestAsync,
    postPlatformRestoreBackupRequestAsync,
    getScrapperLogsHistAsync,
    putEditCommentAsync,
    putAddCommentAsync,
    putDeleteCommentAsync,
    putStartNowAsync,
    putRescheduleAsync,
    putAcceptToDev,
    putAcceptToProd,
    putRejectToProd,
    putRejectToDev,
    getSourcesLogsAsync,
    getLogsReportAsync,
} from '../../fetch/requests';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';
import { SearchResult } from '../../models/SearchResult';
import { ProcessingPformsSettings } from '../../models/ProcessingPformsSettings';
import { request } from 'https';
import { EditComment } from '../../models/EditComment';
import { AddComment } from '../../models/AddComment';
import { DeleteComment } from '../../models/DeleteComment';
import { goTo, goToHome, goToRFQsListV2 } from '../Router/actions';
import { routes } from '../Router/routes';
import { LogExScrapper } from '../../models/LogExScrapper';
import { Signal } from '../../models/Signal';

export const Actions = {
    logsDataLoading: '@@whichpharma.logs.dataLoading',
    logsDataLoaded: '@@whichpharma.logs.dataLoaded',
    logsDataHistoryLoaded: '@@whichpharma.logs.dataHistoryLoaded',
    logsDataLoadError: '@@whichpharma.logs.dataLoadError',
    logsSourcesLoaded: '@@whichpharma.logs.sourcesLoaded',
    logsDataReportLoaded: '@@whichpharma.logs.dataReportLoaded',

    //platformProcessingSettingsLoaded: '@@whichpharma.platform.mappingsLoaded',
    //platformProcessingPformsSettingsLoaded: '@@whichpharma.platform.mappingsPformsLoaded',
};

const logsDataLoaded = (result: any): LogsDataLoadedAction => ({
    type: Actions.logsDataLoaded,
    result,
});
const logsSourcesLoaded = (result: any): LogsDataLoadedAction => ({
    type: Actions.logsSourcesLoaded,
    result,
});
const logsDataReportLoaded = (result: any): LogsDataReportLoadedAction => ({
    type: Actions.logsDataReportLoaded,
    result,
});

const logsDataHistoryLoaded = (result: any): LogsDataHistoryLoadedAction => ({
    type: Actions.logsDataHistoryLoaded,
    result,
});
const logsDataLoading = (): Action => ({
    type: Actions.logsDataLoading,
});

const logsDataLoadError = (): Action => ({
    type: Actions.logsDataLoadError,
});

export const fetchSourcesLogs = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            const result = await dispatch(requestServer((token, csrfToken) => getSourcesLogsAsync(token, csrfToken)));
            dispatch(logsSourcesLoaded(result))
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }

    };
};
export const fetchReportLogs = (id: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            const result = await dispatch(requestServer((token, csrfToken) => getLogsReportAsync(id, token, csrfToken)));
            dispatch(logsDataReportLoaded(result))
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }

    };
};

export const fetchLogs = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getScrapperLogsAsync('', '', token, csrfToken)),
            );
            dispatch(logsDataLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const UpdateComment = (comment: EditComment): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => putEditCommentAsync(comment, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const StartNowSignal = (log: LogExScrapper): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => putStartNowAsync(log, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const AcceptToProd = (log: LogExScrapper): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            await dispatch(requestServer((token, csrfToken) => putAcceptToProd(log, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const RejectToProd = (log: LogExScrapper): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            await dispatch(requestServer((token, csrfToken) => putRejectToProd(log, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const AcceptToDev = (log: LogExScrapper): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            await dispatch(requestServer((token, csrfToken) => putAcceptToDev(log, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const RejectToDev = (log: LogExScrapper): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            await dispatch(requestServer((token, csrfToken) => putRejectToDev(log, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const Reschedulue = (log: Signal): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => putRescheduleAsync(log, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const AddCommen = (comment: AddComment): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            var v2 = '';
            await dispatch(requestServer((token, csrfToken) => putAddCommentAsync(comment, token, csrfToken)));
            dispatch(fetchLogs());

            //dispatch(requestServer((token=> )));
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const DeleteCommen = (comment: DeleteComment): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => putDeleteCommentAsync(comment, token, csrfToken)));
            dispatch(fetchLogs());
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const fetchLogsHist = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(logsDataLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getScrapperLogsHistAsync('', '', token, csrfToken)),
            );
            dispatch(logsDataHistoryLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(logsDataLoadError());
            dispatch(alertGenericError());
        }
    };
};
