import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { MapOf } from '../../../../utils/Types';

import { useTranslations } from '../../../../store/Translations/hooks';

import { PFormFullListTableContainer } from './styled';

import { ProcessingCatSettings } from '../../../../models/ProcessingCatSettings';
import PlatformFilters from '../../../../components/Filter';
import { findStringComparison } from '../../../../utils/utils';

export interface IPFormFullListMappingTable {
    values: MapOf<ProcessingCatSettings>;
}

const PFormFullListMappingTable: React.FC<IPFormFullListMappingTable> = ({ values }) => {
    const t = useTranslations();
    const [categoryList, setCategoryList] = React.useState<ProcessingCatSettings[]>([]);
    const [categoryListData, setCategoryListData] = React.useState<ProcessingCatSettings[]>([]);
    const [selectFilterType, setSelectFilterType] = React.useState('');
    const [textFilterVal, setTextFilterVal] = React.useState('');

    React.useEffect(() => {
        //const offset = currentPage * recordsPerPage;
        //const startingNumber = offset - recordsPerPage;
        const categoryList = Object.keys(values).map((key) => values[key]);
        setCategoryList(categoryList);
        setCategoryListData(categoryList);
    }, [values, setCategoryList, setCategoryListData]);

    const onSelectFilterChange = React.useCallback(
        (value) => {
            setSelectFilterType(value);
        },
        [selectFilterType, setSelectFilterType],
    );

    const onTextFilterChange = React.useCallback(
        (fieldName: string) => (filterVal: string) => {
            let categoryList: ProcessingCatSettings[] = [];

            if (fieldName === 'category') {
                categoryList = categoryListData.filter((item) =>
                    findStringComparison(item.category, filterVal, selectFilterType),
                );
            }

            if (fieldName === 'subCategory') {
                categoryList = categoryListData.filter((item) =>
                    findStringComparison(item.subCategory, filterVal, selectFilterType),
                );
            }

            if (fieldName === 'pharmaceuticalPhorm') {
                categoryList = categoryListData.filter((item) =>
                    findStringComparison(item.pharmaceuticalPhorm, filterVal, selectFilterType),
                );
            }

            if (fieldName === 'administrationRoute') {
                categoryList = categoryListData.filter((item) =>
                    findStringComparison(item.administrationRoute, filterVal, selectFilterType),
                );
            }

            setCategoryList(categoryList);
            setTextFilterVal(filterVal);
        },
        [selectFilterType, textFilterVal, setTextFilterVal, setCategoryList, categoryListData],
    );

    return (
        <PFormFullListTableContainer>
            {/*//<Typography variant="caption">* {t(TK.specifyTheKeywordsForTheCorrespondingValues)}.</Typography>*/}
            <div>Total: {categoryList.length}</div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Category
                            <PlatformFilters
                                elementId="category-popover"
                                onTextFilterChange={onTextFilterChange('category')}
                                onSelectFilterChange={onSelectFilterChange}
                            />
                        </TableCell>
                        <TableCell>
                            Sub-Category
                            <PlatformFilters
                                elementId="subCategory-popover"
                                onTextFilterChange={onTextFilterChange('subCategory')}
                                onSelectFilterChange={onSelectFilterChange}
                            />
                        </TableCell>
                        <TableCell>
                            Pharmaceutical Phorm
                            <PlatformFilters
                                elementId="pharmaceuticalPhorm-popover"
                                onTextFilterChange={onTextFilterChange('pharmaceuticalPhorm')}
                                onSelectFilterChange={onSelectFilterChange}
                            />
                        </TableCell>
                        <TableCell>
                            Administration Route
                            <PlatformFilters
                                elementId="administrationRoute-popover"
                                onTextFilterChange={onTextFilterChange('administrationRoute')}
                                onSelectFilterChange={onSelectFilterChange}
                            />
                        </TableCell>
                        <TableCell>Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categoryList.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.category}</TableCell>
                            <TableCell>{item.subCategory}</TableCell>
                            <TableCell>{item.pharmaceuticalPhorm}</TableCell>
                            <TableCell>{item.administrationRoute}</TableCell>
                            {/*    <TableCell width="100%">*/}
                            {/*        <MultipleSelectInput */}
                            {/*            limitTags={10}*/}
                            {/*            freeSolo*/}
                            {/*            values={values[key]}*/}
                            {/*            onChange={handleChange(key)}*/}
                            {/*            allowAdd*/}
                            {/*        />*/}
                            {/*    </TableCell>*/}
                            <TableCell>
                                Last updated by:
                                <br />
                                {item?.user}
                                {/* {item.updated != undefined
                                    ? new Date(Date.parse(item.updated?.toString() || '')).toLocaleString()
                                    : ''} */}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </PFormFullListTableContainer>
    );
};

export default PFormFullListMappingTable;
