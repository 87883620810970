import * as React from 'react';
import { TK } from '../../store/Translations/translationKeys';
import { ColumnDefinition, ColumnTypeTemplate } from '../../components/Table/types';
import CountryFlag from '../../components/CountryFlag';
import { parseNumber } from '../../utils/utils';
import { RFQQuote } from '../../models/RFQQuote';
import { RFQQuoteAvatar } from '../../components/RFQQuoteAvatar';
import moment from 'moment';
import { DateTimeFormat } from '../../components/Table/DataTypeFormatter';

export const columns: ColumnDefinition<any>[] = [
    {
        labelTK: TK.status,
        columnName: 'state',
        defaultWidth: 85,
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        renderTableCell: (row: RFQQuote) => <RFQQuoteAvatar size="small" state={row.state} />,
    },
    {
        labelTK: TK.country,
        columnName: 'country',
        defaultWidth: 100,
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        getCellValue: (item) => item.country.name,
        renderTableCell: (item) => <CountryFlag country={item.country.name} countryCode={item.country.code} />,
    },
    {
        labelTK: TK.supplier,
        columnName: 'supplier',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
    },
    {
        labelTK: TK.contacts,
        columnName: 'contacts',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
    },
    {
        labelTK: TK.rfqNr,
        columnName: 'rfqNr',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
    },
    {
        labelTK: TK.maHolder,
        defaultWidth: 120,
        columnName: 'maHolder',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
        getCellValue: (item) => item.maHolder,
    },
    {
        labelTK: TK.activeSubstances,
        columnName: 'activeSubstances',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.name,
        columnName: 'name',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.productCode,
        columnName: 'productCode',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.packSize,
        columnName: 'packSize',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
        getCellValue: (item) => item.packSize || item.package,
    },
    {
        labelTK: TK.unitQuant,
        defaultWidth: 120,
        columnName: 'unitQuant',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.exwNetPriceEuro,
        defaultWidth: 150,
        columnName: 'exwNetPriceEuro',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
        getCellValue: (item) => item.exwNetPriceEuro || item.exwNetPriceEuro,
    },
    {
        labelTK: TK.availabilityPacks,
        columnName: 'availabilityPacks',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.leadTimeToDeliver,
        columnName: 'leadTimeToDeliver',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.expDate,
        columnName: 'expDate',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.countryOfOrigin,
        columnName: 'countryOfOrigin',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
        getCellValue: (item) => item.country.name,
    },
    {
        labelTK: TK.comments,
        defaultWidth: 1000,
        columnName: 'comments',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
    },
    {
        labelTK: TK.id,
        columnName: 'id',
    },
    {
        labelTK: TK.packsTotal,
        columnName: 'packsTotal',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true, ///
        getCellValue: (item) =>
            ((parseNumber(item.unitQuant) || 0) / (parseNumber(item.packSize || item.package) || 1)).toString(),
    },
    {
        labelTK: TK.unitPrice,
        columnName: 'unitPrice',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: true,
        getCellValue: (item) =>
            `${(parseNumber(item.exwNetPriceEuro) || 0) / (parseNumber(item.packSize || item.package) || 1)} €`,
    },
    {
        labelTK: TK.totalPrice,
        columnName: 'totalPrice',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        canBeEdited: false,
        getCellValue: (item) => `${(parseNumber(item.exwNetPriceEuro) || 0) * (parseNumber(item.unitQuant) || 0)} €`,
    },
    {
        labelTK: TK.createdBy,
        columnName: 'createdBy',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        getCellValue: (p) => (p.createdBy?.startsWith('supplier') ? 'Supplier' : p.createdBy),
    },
    {
        labelTK: TK.creationDate,
        columnName: 'creationDate',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        templateType: ColumnTypeTemplate.DateTime,
        renderTableCell: (p) => (p.creationDate ? moment(p.creationDate).format(DateTimeFormat) : '-'),
    },
    {
        labelTK: TK.updatedBy,
        columnName: 'updatedBy',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        getCellValue: (p) => (p.updatedBy?.startsWith('supplier') ? 'Supplier' : p.updatedBy),
    },
    {
        labelTK: TK.lastUpdate,
        columnName: 'lastUpdateDate',
        canBeSorted: true,
        canBeFiltered: true,
        canBeGrouped: true,
        templateType: ColumnTypeTemplate.DateTime,
        renderTableCell: (p) => (p.lastUpdateDate ? moment(p.lastUpdateDate).format(DateTimeFormat) : '-'),
    },
];
