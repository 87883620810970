import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, Typography, TableHead, Button, Tooltip } from '@material-ui/core';
//import { MapOf } from "../../../../utils/Types";
//import { useTranslations } from "../../../../store/Translations/hooks";
//import { TK } from "../../../../store/Translations/translationKeys";
import { useTranslations } from '../../store/Translations/hooks';
import Page from '../../components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { getScrapperLogs, isLoadingLogsData, getReportLog, getScrapperLogsHistory, getSourceLog } from '../../store/Logs2/selectors';
import { fetchLogs, fetchLogsHist, fetchReportLogs, fetchSourcesLogs, RejectToProd } from '../../store/Logs2/actions';
import { LogExScrapper } from '../../models/LogExScrapper';

import { LogsScrapperResult } from '../../models/LogsScrapperResult';
import { LogDetailDialog } from '../../components/LogDialog';
import { SignalDetailsDialog } from '../../components/Signal';

import { AcceptToDevDialog } from '../../components/AcceptToDevDialog';
import { AcceptToDevConfirmDialog } from '../../components/AcceptToDevConfirmDialog';
import { AcceptToDevRejectDialog } from '../../components/AcceptToDevRejectDialog';
import { AcceptToProdDialog } from '../../components/AcceptToProdDialog';
import { AcceptToProdConfirmDialog } from '../../components/AcceptToProdConfirmDialog';
import { TK } from '../../store/Translations/translationKeys';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import Panel from '../../components/Panel';
import Loading from '../../components/Loading';
import { ReportScrapperDialog } from '../../components/ReportScrapperDialog';
import { ReportComment } from '../../models/ReportComment';
import { LogsSourcesHistoryDialog } from '../../components/LogsSourcesHistoryDialog';
import { Sources } from '../../models/Sources';
import moment from 'moment';

const Monitor: React.FC = () => {
    const t = useTranslations();
    const [open, setOpen] = React.useState(false);
    const [DevAcc, setDevAcc] = React.useState(false);
    const [DevRej, setDevRej] = React.useState(false);
    var x = {} as LogExScrapper;
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;

    const [logDialog, setlogDialog] = React.useState<LogsScrapperResult>();
    const [source, setSource] = React.useState<Sources>();
    const [logDialog2, setlogDialog2] = React.useState<LogsScrapperResult>();
    const [logDialog3, setlogDialog3] = React.useState<LogsScrapperResult>();
    const [logDialog4, setlogDialog4] = React.useState<LogsScrapperResult>();
    const [logDialog5, setlogDialog5] = React.useState<LogsScrapperResult>();
    const logs = useSelector(getScrapperLogs) || {};
    const hist = useSelector(getScrapperLogsHistory) || [];
    const sources = useSelector(getSourceLog) || {};
    const report = useSelector(getReportLog);
    const isLoading = useSelector(isLoadingLogsData);
    const dispatch = useDispatch();

    const getDuration = React.useCallback((log: LogExScrapper): string => {
        const endDate = log.endDate != null ? log.endDate.toString() : new Date().toString();
        const dateFuture = Date.parse(endDate || '');
        const dateNow = Date.parse(log.startDate.toString());
        const timeDiff = dateFuture - dateNow;
        let seconds = Math.floor(timeDiff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days == 0) {
            return (
                hours.toString().padStart(2, '0') +
                ':' +
                minutes.toString().padStart(2, '0') +
                ':' +
                seconds.toString().padStart(2, '0')
            );
        }
        return (
            days +
            '.' +
            hours.toString().padStart(2, '0') +
            ':' +
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
        );
    }, []);
    const getDurationLong = React.useCallback((log: LogExScrapper): string => {
        const endDate = log.endDate != null ? log.endDate.toString() : new Date().toString();
        const dateFuture = Date.parse(endDate || '');
        const dateNow = Date.parse(log.startDate.toString());
        const timeDiff = dateFuture - dateNow;
        let seconds = Math.floor(timeDiff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days == 0) {
            if (hours != 0) {
                return (hours.toString() + ' hr ' + (minutes == 0 ? '' : minutes + ' min'))
            } else if (minutes != 0) {
                return (minutes + ' min ' + (seconds == 0 ? '' : seconds + ' sec'))
            } else {
                return (seconds==0 ? 'now': seconds+ 'sec')
            }
        } if (days>=31) {
            return ((days/31)+' mo '+(days%31==0 ? '' :(days%31+' days')))
        }
        return (
            days +' days ' + (hours == 0 ? ' ': hours +'hr')
        );
    }, []);
    const getDurationLongEnded = React.useCallback((log?: LogExScrapper): string => {
        const endDate = log?.endDate != null ? log.endDate.toString() : new Date().toString();
        const dateFuture = Date.parse(endDate || '');
        const dateNow = Date.now();
        const timeDiff = dateNow - dateFuture;
        let seconds = Math.floor(timeDiff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        //var x2 = new Date(timeDiff);
        //var x3 = moment(timeDiff).toDate();
        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days == 0) {
            if (hours != 0) {
                return (hours.toString() + ' hr ' + (minutes == 0 ? '' : minutes + ' min'))
            } else if (minutes != 0) {
                return (minutes + ' min ' + (seconds == 0 ? '' : seconds + ' sec'))
            } else {
                return (seconds==0 ? 'now': seconds+ 'sec')
            }
        } if (days >= 31) {
            return (((days - (days % 31)) / 31) + ' mo ' + (days % 31 == 0 ? '' : (days % 31 + ' days')))
        }

        return (
            days +' days ' + (hours == 0 ? ' ': hours +'hr')
        );
    }, []);

    React.useEffect(() => {
        setHeaderName(t(TK.monitor));
    }, []);
    const getStatus = React.useCallback(
        (log: LogExScrapper): string => {
            if (log == null) {
                return '';
            }
            if (log.error == null && !log.endDate) {
                return 'Running (' + getDuration(log) + ')';
            }
            if (log.isSuccess && !log.isVerified) {
                return 'Pending Approval';
            }
            if (log.isSuccess && !log.isAprroved) {
                return 'Deployed on Dev';
            }
            if (log.isSuccess && log.isAprroved) {
                return 'Updated';
            }
            if (log.error != null) {
                return 'Failed';
            }

            return 'asda';
        },
        [getDuration],
    );

    const getStatusProp = React.useCallback(
        (log: LogExScrapper): any => {
            if (log == null) {
                return '';
            }
            if (log.error == null && !log.endDate) {
                return (<><span>{getDuration(log)}</span><img alt="Free Running Icon" width="40" src="https://cdn.iconscout.com/icon/free/png-256/free-running-3079964-2561166.png?f=webp&amp;w=256" srcSet="https://cdn.iconscout.com/icon/free/png-256/free-running-3079964-2561166.png?f=webp&amp;w=256 1x, https://cdn.iconscout.com/icon/free/png-256/free-running-3079964-2561166.png?f=webp&amp;w=512 2x" /></>);
            }
            if (log.isSuccess && !log.isVerified) {
                return (<><Tooltip title={<h6>Pending Approval</h6>}><svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40" height="40"><path d="M13.096 2.002v.002a2.496 2.496 0 0 0-1.602.5A2.5 2.5 0 0 0 10.56 5H8.5a.5.5 0 0 0-.5.5V6H3.5a.5.5 0 0 0-.5.5v23a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-23a.5.5 0 0 0-.5-.5H18v-.5a.5.5 0 0 0-.5-.5h-2.055a2.493 2.493 0 0 0-2.35-2.998zM13.059 3A1.495 1.495 0 0 1 14.3 5.244a.5.5 0 0 0 .17.674.5.5 0 0 0 .021.014.5.5 0 0 0 .02.01.5.5 0 0 0 .011.005.5.5 0 0 0 .022.01.5.5 0 0 0 .205.043H17v.418a.5.5 0 0 0 0 .164v1.836a.5.5 0 0 0 0 .164V9H9v-.422a.5.5 0 0 0 0-.16v-1.84a.5.5 0 0 0 0-.16V6h2.25a.5.5 0 0 0 .43-.766.5.5 0 0 0-.035-.09 1.496 1.496 0 0 1 .453-1.841 1.5 1.5 0 0 1 .96-.303zM26.5 6a.5.5 0 0 0-.5.5v1.502h-1.5L24.498 8a.5.5 0 0 0-.498.502v6.002c-.008.675 1.008.675 1 0V9.002h1V25.5a.5.5 0 0 0 .5.5h.129v1.502a.5.5 0 0 0 .5.5h.371V30h1v-1.998h.373a.5.5 0 0 0 .498-.504V26h.129a.5.5 0 0 0 .5-.5V8.262a.5.5 0 0 0 0-.033V6.5a.5.5 0 0 0-.5-.5h-3zM4 7h4v1H5.5a.5.5 0 0 0-.5.5v19a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-19a.5.5 0 0 0-.5-.5H18V7h4v22H4V7zm23 0h2v1.002h-2V7zM6 9h2v.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V9h2v18H6V9zm21 .002h2V14h-2V9.002zM7.5 11a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-4zm5.957 0a.5.5 0 0 0 .05 1h4.983a.5.5 0 1 0 0-1h-4.982a.5.5 0 0 0-.051 0zM8 12h3v3H8v-3zm5.457 1a.5.5 0 0 0 .05 1h4.983a.5.5 0 1 0 0-1h-4.982a.5.5 0 0 0-.051 0zm0 2a.5.5 0 0 0 .05 1h4.983a.5.5 0 1 0 0-1h-4.982a.5.5 0 0 0-.051 0zM27 15h2v10h-.094a.5.5 0 0 0-.035-.002h-1.744a.5.5 0 0 0-.025.002H27V15zM7.46 17a.5.5 0 0 0 .052 1h10.976a.5.5 0 1 0 0-1H7.512a.5.5 0 0 0-.051 0zm0 2a.5.5 0 0 0 .052 1h10.976a.5.5 0 1 0 0-1H7.512a.5.5 0 0 0-.051 0zm-.003 2a.5.5 0 0 0 .05 1h3.968a.5.5 0 1 0 0-1H7.508a.5.5 0 0 0-.051 0zm7.99 4a.5.5 0 0 0 .051 1h3.02a.5.5 0 1 0 0-1h-3.02a.5.5 0 0 0-.05 0zm12.18 1h.744v1h-.744v-1z"></path></svg></Tooltip></>);
            }
            if (log.isSuccess && !log.isAprroved) {
                return (<><Tooltip title={<h6>Deployed on Dev</h6>}><img alt="Free Checklist Icon" width="40" src="https://cdn.iconscout.com/icon/free/png-256/free-checklist-1661505-1410324.png?f=webp&amp;w=256" srcSet="https://cdn.iconscout.com/icon/free/png-256/free-checklist-1661505-1410324.png?f=webp&amp;w=256 1x, https://cdn.iconscout.com/icon/free/png-256/free-checklist-1661505-1410324.png?f=webp&amp;w=512 2x" /></Tooltip></>);
            }
            if (log.isSuccess && log.isAprroved) {
                return (<><Tooltip title={<h6>Updated</h6>} ><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100" id="Success"><path d="M50 5C25.19 5 5 25.19 5 50s20.19 45 45 45 45-20.19 45-45S74.81 5 50 5zm0 86C27.39 91 9 72.61 9 50S27.39 9 50 9s41 18.39 41 41-18.39 41-41 41zm22.41-56.41c.78.78.78 2.05 0 2.83l-28 28c-.39.38-.9.58-1.41.58s-1.02-.2-1.41-.59l-14-14c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0L43 61.17l26.59-26.59c.78-.78 2.04-.78 2.82.01z" fill="#00c4aa"></path></svg></Tooltip></>);
            }
            if (log.error != null) {
                return (<><Tooltip title={<h6>Failed</h6>}><img alt="Failed Icon" width="40" src="https://cdn.iconscout.com/icon/premium/png-256-thumb/failed-5612713-4731659.png?f=webp&amp;w=256" srcSet="https://cdn.iconscout.com/icon/premium/png-256-thumb/failed-5612713-4731659.png?f=webp&amp;w=256 1x, https://cdn.iconscout.com/icon/premium/png-256-thumb/failed-5612713-4731659.png?f=webp&amp;w=512 2x" /></Tooltip></>);
            }

            return 'asda';
        },
        [getDuration],
    );

    const handleOpenAcceptToDev = () => {
        setDevAcc(true);
    };

    const handleOpenRejectToDev = () => {
        setDevRej(true);
    };

    const openDialog = React.useCallback(
        (log?: LogsScrapperResult) => {
            setlogDialog(log);
            setOpen(true);
        },
        [setOpen, setlogDialog],
    );
    const handleReportLog = (log: LogsScrapperResult) => {

        dispatch(fetchReportLogs(log.lastSucessfull.executionId));
        setlogDialog5(log);
    };
    const handleDialogClose = React.useCallback(() => {
        setlogDialog(undefined);
        setlogDialog2(undefined);
        setlogDialog3(undefined);
        setlogDialog4(undefined);
        setlogDialog5(undefined);
        setSource(undefined);
        //setOpen(true);
    }, [setOpen, setlogDialog, setSource, setlogDialog2, setlogDialog3, setlogDialog4]);

    const handleDialogClose2 = React.useCallback(() => {
        setDevAcc(false);
        setDevRej(false);
        handleDialogClose();
        //setOpen(true);
    }, [setOpen, setDevAcc, setDevRej, handleDialogClose]);

    const handleRejectToProd = () => {
        const log = logDialog4?.last as LogExScrapper;
        dispatch(RejectToProd(log));
        handleDialogClose2();
    };


    const getColors = (log: LogExScrapper): string => {
        if (log.error == null && !log.endDate) {
            return '#f7f0b1';
        }
        if (log.isSuccess && !log.isVerified) {
            return '#ff8307bd';
        }
        if (log.isSuccess && log.isAprroved) {
            //return "#f0ff8a";
            return '#89C5A9';
        }
        if (log.error != null) {
            return '#fe8374';
        }

        return '';
    };

    const actions = (log: LogExScrapper, logs: LogsScrapperResult) => {
        let status = getStatus(log);
        if (status == 'Pending Approval') {
            return (
                <>
                    <Button onClick={() => setlogDialog3(logs)} color="primary" variant="contained">
                        Accept
                    </Button>
                    <Button onClick={() => setlogDialog2(logs)} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="Start" style={{ width: "40px", height: "40px" }}>
                            <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" fill="#00c4aa">
                            </path>
                        </svg>
                    </Button>
                </>
            );
        }
        if (status == 'Deployed on Dev') {
            return (
                <>
                    <Button onClick={() => setlogDialog4(logs)} variant="contained">
                        Send to Production
                    </Button>
                    <Button onClick={() => setlogDialog2(logs)} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="Start" style={{ width: "40px", height: "40px" }}>
                            <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" fill="#00c4aa">
                            </path>
                        </svg>
                    </Button>
                </>
            );
        }
        return (
            <Button onClick={() => setlogDialog2(logs)} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="Start" style={{ width: "40px", height:"40px" }}>
                    <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" fill="#00c4aa">
                    </path>
                </svg>

            </Button>
        );
    };

    React.useEffect(() => {
        dispatch(fetchLogs());
        dispatch(fetchLogsHist());
        dispatch(fetchSourcesLogs());
    }, []);

    return (
        <Page style={{ marginTop: '128px' }} title={t(TK.monitor)}>
            {report && <ReportScrapperDialog log={logDialog5} report={report as ReportComment} handleDialogClose={handleDialogClose} />}
            <LogDetailDialog log={logDialog} handleDialogClose={handleDialogClose}></LogDetailDialog>
            <SignalDetailsDialog log={logDialog2} handleDialogClose={handleDialogClose}></SignalDetailsDialog>
            <AcceptToDevDialog
                handleAccept={handleOpenAcceptToDev}
                handleReject={handleOpenRejectToDev}
                log={logDialog3}
                handleDialogClose={handleDialogClose}
            ></AcceptToDevDialog>
            <AcceptToProdDialog
                handleAccept={handleOpenAcceptToDev}
                handleReject={handleRejectToProd}
                log={logDialog4}
                handleDialogClose={handleDialogClose}
            ></AcceptToProdDialog>
            {<LogsSourcesHistoryDialog handleDialogClose={handleDialogClose2} hist={hist} source={source} log={logDialog4} />}
            {DevAcc && (
                <AcceptToDevConfirmDialog
                    handleDialogClose={handleDialogClose2}
                    log={logDialog3}
                ></AcceptToDevConfirmDialog>
            )}
            {DevRej && (
                <AcceptToDevRejectDialog
                    handleDialogClose={handleDialogClose2}
                    log={logDialog3}
                ></AcceptToDevRejectDialog>
            )}
            {DevAcc && (
                <AcceptToProdConfirmDialog
                    handleDialogClose={handleDialogClose2}
                    log={logDialog4}
                ></AcceptToProdConfirmDialog>
            )}

            {/*<Typography variant="caption">* {"Monitor"}.</Typography>*/}
            <Panel>
                <Loading isLoading={isLoading} style={{top:"0"}}/>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Last Sucessfull</TableCell>
                            <TableCell>Last Failed</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>Next Execution</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(logs).map((key) => (
                            <TableRow key={key}>
                                <TableCell>{key}<Button onClick={() => { setSource(sources[key]) }}><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 1778.69 1778.68" width="24" height="24"><path fill-rule="nonzero" d="M889.34 -0.01c245.58,0 467.91,99.55 628.85,260.5 160.95,160.94 260.5,383.28 260.5,628.85 0,245.55 -99.56,467.91 -260.5,628.85 -160.94,160.95 -383.27,260.5 -628.85,260.5 -245.54,0 -467.91,-99.56 -628.84,-260.5 -160.94,-160.94 -260.5,-383.3 -260.5,-628.85 0,-245.57 99.54,-467.91 260.5,-628.85 160.93,-160.93 383.3,-260.5 628.84,-260.5zm-74.22 518.64c0,-40.98 33.24,-74.22 74.22,-74.22 40.98,0 74.23,33.24 74.23,74.22l0 29.61c0,40.98 -33.25,74.22 -74.23,74.22 -40.98,0 -74.22,-33.24 -74.22,-74.22l0 -29.61zm147.88 259.75c0.37,3 0.57,6.04 0.57,9.14l0 398.25 28.22 0c40.98,0 74.22,33.25 74.22,74.23 0,40.98 -33.24,74.22 -74.22,74.22l-102.44 0 -102.43 0c-40.98,0 -74.22,-33.24 -74.22,-74.22 0,-40.98 33.24,-74.23 74.22,-74.23l28.22 0 0 -342.31 -28.22 0c-40.98,0 -74.22,-33.25 -74.22,-74.23 0,-40.98 33.24,-74.22 74.22,-74.22l102.43 0c40.98,0 74.23,33.24 74.23,74.22 0,3.1 -0.2,6.14 -0.57,9.14zm450.25 -412.96c-134.04,-134.06 -319.29,-216.99 -523.91,-216.99 -204.61,0 -389.85,82.93 -523.91,216.99 -134.06,134.05 -216.99,319.29 -216.99,523.91 0,204.61 82.93,389.85 216.99,523.91 134.06,134.07 319.3,216.99 523.91,216.99 204.62,0 389.87,-82.92 523.91,-216.99 134.07,-134.06 216.99,-319.3 216.99,-523.91 0,-204.62 -82.92,-389.86 -216.99,-523.91z" fill="#00c4aa" ></path></svg></Button></TableCell>
                                <TableCell>
                                    {/*<img src="https://cdn.iconscout.com/icon/free/png-512/attach-folder-6887266-5623000.png?f=avif&w=40" onClick={() => handleReportLog(logs[key])} />*/}
                                    {/*{new Date(*/}
                                    {/*    Date.parse(logs[key].lastSucessfull.startDate.toString()),*/}
                                    {/*).toLocaleDateString()}*/}
                                    {/*<br />*/}
                                    {getDurationLongEnded(logs[key].lastSucessfull)}
                                    <img src="https://cdn.iconscout.com/icon/free/png-512/attach-folder-6887266-5623000.png?f=avif&w=40" onClick={() => handleReportLog(logs[key])} />
                                </TableCell>
                                <TableCell>
                                    {/*{new Date(*/}
                                    {/*    Date.parse(logs[key].lastFinished.startDate.toString()),*/}
                                    {/*).toLocaleDateString()}*/}
                                    {/*<br />*/}
                                    {logs[key].lastFinished ? getDurationLongEnded(logs[key].lastFinished) : <>N/A</>}
                                </TableCell>
                                <TableCell>{getDurationLong(logs[key].lastSucessfull)}</TableCell>
                                <TableCell>{getStatusProp(logs[key].last)}</TableCell>
                                <TableCell>{actions(logs[key].last, logs[key])}</TableCell>
                                <TableCell>
                                    <Button onClick={() => openDialog(logs[key])} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="40" height="40">
                                            <path d="M74 43c0 1.1-.9 2-2 2H28c-1.1 0-2-.9-2-2s.9-2 2-2h44c1.1 0 2 .9 2 2zm-14.2 8H28c-1.1 0-2 .9-2 2s.9 2 2 2h31.8c1.1 0 2-.9 2-2s-.9-2-2-2zM72 31H28c-1.1 0-2 .9-2 2s.9 2 2 2h44c1.1 0 2-.9 2-2s-.9-2-2-2zm16-12v48c0 1.1-.9 2-2 2H47L33.4 82.4c-.5.6-3.4 1.4-3.4-1.4V69H14c-1.1 0-2-.9-2-2V19c0-1.1.9-2 2-2h72c1.1 0 2 .9 2 2zm-4 2H16v44h16c1.1 0 2 .9 2 2v9.2l10.8-10.6c.4-.4.9-.6 1.4-.6H84V21z" fill="#00c4aa" />
                                            <path fill="#00c4aa" d="M1224-510v1684H-560V-510h1784m8-8H-568v1700h1800V-518z" />
                                        </svg>
                                    </Button>
                                </TableCell>
                                <TableCell>{logs[key].nextExecution}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Panel>
        </Page>
    );
};

export default Monitor;
